import * as Application from "expo-application";
import { v5 as uuidv5 } from "uuid";

// used on web. you may need to use a polyfill for
// browsers that do not implement `crypto` interface (for example, IE < 11)
// or, you can use uuid@3.4.0 , which falls back to Math.random()
import { v4 as uuidv4 } from "uuid";
import { UUID_NAMESPACE } from "../config/constants";
import { isAndroid, isWeb } from "../styles/commonStyles";

export async function getInstallationId() {
  let installationId;

  if (isWeb) {
    // WEB. random (uuid v4)
    installationId = uuidv4();
  } else {
    if (isAndroid) {
      identifierForVendor = Application.androidId;
    } else {
      // ios
      identifierForVendor = await Application.getIosIdForVendorAsync();
    }

    const bundleIdentifier = Application.applicationId;

    if (identifierForVendor) {
      installationId = uuidv5(
        `${bundleIdentifier}-${identifierForVendor}`,
        UUID_NAMESPACE
      );
    } else {
      const installationTime = await Application.getInstallationTimeAsync();
      installationId = uuidv5(
        `${bundleIdentifier}-${installationTime.getTime()}`,
        UUID_NAMESPACE
      );
    }
  }

  return installationId;
}
