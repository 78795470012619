import {
  FETCH_GET_LIVE_EVENTS,
  SET_LIVE_EVENTS,
  FETCH_DELETE_LIVE_EVENT,
  SET_DELETE_LIVE_EVENT_RESULT,
  SET_UNINTERESTED_LIVE_EVENT_RESULT,
  FETCH_UNINTERESTED_LIVE_EVENT,
  SET_INTERESTED_LIVE_EVENT_RESULT,
  FETCH_INTERESTED_LIVE_EVENT,
  FETCH_FAILURE,
  FETCH_FLAG_INAPPROPRIATE_EVENT_CONTENT,
  SET_FLAG_INAPPROPRIATE_EVENT_CONTENT_RESULT,
  FETCH_PUBLISH_EVENT,
  SET_PUBLISH_EVENT_RESULT,
  SET_PUBLISH_EVENT_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";
import { fetchGetTemplates } from "./template";
import { APP_NAME } from "../config/constants";

const user = new schema.Entity("users", {}, { idAttribute: "_id" });
const interesteduser = new schema.Entity(
  "interestedusers",
  {},
  { idAttribute: "_id" }
);

export const event = new schema.Entity(
  "events",
  {
    userId: user,
    interestedUsersIds: [interesteduser]
  },
  { idAttribute: "_id" }
);

const infospage = new schema.Entity("infospages", {
  docs: [event]
});

let templatesUpdateTimeStamp;

export const setTemplatesUpdateTimeStamp = newValue => {
  templatesUpdateTimeStamp = newValue;
};

const getTemplatesUpdateTimeStamp = () => {
  return templatesUpdateTimeStamp;
};

export function fetchGetLiveEvents(page, filter) {
  return apiAction({
    url: "/events?page=" + page + "&filter=" + filter,
    method: "GET",
    schema: infospage,
    onSuccess: setLiveEvents,
    onFailure: setFetchFailure,
    label: FETCH_GET_LIVE_EVENTS
  });
}

function setLiveEvents(normalized) {
  if (!getTemplatesUpdateTimeStamp()) {
    setTemplatesUpdateTimeStamp(
      normalized.entities.infospages["1"].templatesUpdateTimeStamp
    );
  }
  if (
    getTemplatesUpdateTimeStamp() ===
    normalized.entities.infospages["1"].templatesUpdateTimeStamp
  ) {
    return {
      type: SET_LIVE_EVENTS,
      entities: normalized.entities
    };
  }
  // Templates have been updated, we retrieve new templates before setting new events
  setTemplatesUpdateTimeStamp(
    normalized.entities.infospages["1"].templatesUpdateTimeStamp
  );
  return [fetchGetTemplates(setLiveEvents(normalized))];
}

export function fetchDeleteLiveEvent(eventId) {
  return apiAction({
    url: "/events/" + eventId,
    method: "DELETE",
    onSuccess: setDeleteLiveEventResult,
    onFailure: setFetchFailure,
    label: FETCH_DELETE_LIVE_EVENT
  });
}

function setDeleteLiveEventResult(data) {
  return {
    type: SET_DELETE_LIVE_EVENT_RESULT,
    payload: data
  };
}

export function fetchInterested(eventId) {
  return apiAction({
    url: "/events/interested/" + eventId,
    method: "POST",
    data: {
      app: APP_NAME
    },
    onSuccess: setInterestedResult,
    onFailure: setFetchFailure,
    label: FETCH_INTERESTED_LIVE_EVENT
  });
}

function setInterestedResult(data) {
  return {
    type: SET_INTERESTED_LIVE_EVENT_RESULT,
    payload: data
  };
}

export function fetchUninterested(eventId) {
  return apiAction({
    url: "/events/uninterested/" + eventId,
    method: "POST",
    onSuccess: setUninterestedResult,
    onFailure: setFetchFailure,
    label: FETCH_UNINTERESTED_LIVE_EVENT
  });
}

function setUninterestedResult(data) {
  return {
    type: SET_UNINTERESTED_LIVE_EVENT_RESULT,
    payload: data
  };
}

export function fetchFlagInappropriateContent(eventId) {
  return apiAction({
    url: "/events/flag/" + eventId,
    method: "PUT",
    onSuccess: setFlagInappropriateContentResult,
    onFailure: setFetchFailure,
    label: FETCH_FLAG_INAPPROPRIATE_EVENT_CONTENT
  });
}

function setFlagInappropriateContentResult(data) {
  return {
    type: SET_FLAG_INAPPROPRIATE_EVENT_CONTENT_RESULT,
    payload: data
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}

export function fetchPublishEvent(data) {
  return apiAction({
    url: "/events/publish",
    method: "POST",
    data,
    onSuccess: setPublishEventResult,
    onFailure: setPublishEventFailure,
    label: FETCH_PUBLISH_EVENT
  });
}

function setPublishEventResult(data) {
  return {
    type: SET_PUBLISH_EVENT_RESULT,
    payload: data
  };
}

function setPublishEventFailure(error) {
  return {
    type: SET_PUBLISH_EVENT_FAILURE,
    payload: error
  };
}

export function fetchShareTemplatePost(files) {
  return apiAction({
    url: "/events/share",
    method: "POST",
    data: { files },
    onSuccess: setShareTemplatePostResult,
    onFailure: setShareTemplatePostFailure,
    label: FETCH_SHARE_TEMPLATE_POST
  });
}

function setShareTemplatePostResult(data) {
  return {
    type: SET_SHARE_TEMPLATE_POST_RESULT,
    payload: data
  };
}

function setShareTemplatePostFailure(error) {
  return {
    type: SET_SHARE_TEMPLATE_POST_FAILURE,
    payload: error
  };
}
