import { useRoute } from "@react-navigation/core";
import React, { memo, useEffect } from "react";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  View
} from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchGetEventInterestedUsers } from "../actions/chat";
import ChatUserItem from "../components/ChatUserItem";
import LiveEvent from "../components/LiveEvent";
import {
  GROUP_ID_ACTIVITY,
  GROUP_ID_GOODDEAL_ACTIVITY,
  GROUP_ID_PROPOSAL,
  GROUP_ID_TEMPLATE
} from "../config/constants";
import {
  getEventInterestedUsersReducer,
  getProfileReducer
} from "../selectors";
import { t } from "../services/i18n";
import commonStyles, { COLOR2 } from "../styles/commonStyles";

const getSelector = createSelector(
  [getProfileReducer, getEventInterestedUsersReducer],
  ({ user }, { isFetching, reload, event, users }) => ({
    isFetching,
    reload,
    event,
    users,
    user
  })
);

const EventInterestedUsers = () => {
  const dispatch = useDispatch();
  const route = useRoute();

  const { isFetching, reload, event, users, user } = useSelector(
    getSelector,
    shallowEqual
  );

  if (user && users) {
    // Delete current user
    delete users[user._id];
  }

  useEffect(() => {
    dispatch(fetchGetEventInterestedUsers(route.params.id));
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(fetchGetEventInterestedUsers(route.params.id));
    }
  }, [reload]);

  let groupId, userId;
  if (event) {
    ({ groupId, userId } = event);
  }

  const {
    font,
    flex1,
    justifyContentCenter,
    mb10,
    pv20,
    bgWhite,
    mt5,
    shadowGrey,
    bgColor1,
    color5,
    w100p,
    textCenter
  } = commonStyles;

  return (
    <View style={[flex1, bgColor1]}>
      {isFetching || !event ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <ScrollView nestedScrollEnabled={true}>
          <LiveEvent item={event} isDetails={true} />
          {Object.values(users).length > 0 &&
            user &&
            userId &&
            user._id === userId && (
              <View style={[flex1, pv20, bgWhite, mt5, shadowGrey]}>
                <Text
                  style={[font, color5, textCenter, mb10, { fontSize: 17 }]}
                >
                  {groupId === GROUP_ID_ACTIVITY ||
                  groupId === GROUP_ID_PROPOSAL ||
                  groupId === GROUP_ID_GOODDEAL_ACTIVITY
                    ? t("button:answers")
                    : groupId === GROUP_ID_TEMPLATE
                    ? t("button:interested")
                    : t("button:answerslike")}
                </Text>
                <FlatList
                  style={[flex1, w100p]}
                  data={Object.values(users)}
                  renderItem={({ item }) => (
                    // renderUser(item)}
                    <ChatUserItem item={item} />
                  )}
                  keyExtractor={obj => obj._id}
                />
              </View>
            )}
        </ScrollView>
      )}
    </View>
  );
};

const MemoizedEventInterestedUsers = memo(EventInterestedUsers);
export default MemoizedEventInterestedUsers;
