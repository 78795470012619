import React, { useEffect } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_SHARE, CLEAR_SHARE_POST } from "../actions/types";
import { PWA_DEEPLINK_APP_URL } from "../config/constants";
import { isElectron } from "../styles/commonStyles";
import { formatDate } from "../utils/DateUtils";
import { openURL } from "../utils/UrlUtil";
import { getTranslatedProperty, t } from "./i18n";

export default function DeepLinkManagement() {
  const dispatch = useDispatch();

  const { ad, share } = useSelector(state => state.adReducer);
  const { event, share: sharePost } = useSelector(
    state => state.eventInterestedUsersReducer
  );

  const postOnFacebook = (quote, uri) => {
    let facebookParameters = [];
    facebookParameters.push("u=" + encodeURI(uri ? uri : window.location.href));
    facebookParameters.push("quote=" + encodeURI(quote));

    const url =
      "https://www.facebook.com/sharer/sharer.php?" +
      facebookParameters.join("&");
    openURL(dispatch, url);
  };

  useEffect(() => {
    if (share) {
      postOnFacebook(
        `${getTranslatedProperty(
          ad,
          "title"
        ).toUpperCase()}\n${getTranslatedProperty(ad, "description")}`,
        isElectron &&
          `${PWA_DEEPLINK_APP_URL}/${ad.cityUri}/vie-locale/${ad.customUri}`
      );
      dispatch({
        type: CLEAR_SHARE
      });
    }
  }, [share]);

  useEffect(() => {
    if (sharePost) {
      let { date, title: title_fr, eventId } = event;

      let title = t("live:postof") + formatDate(date);
      if (title_fr) {
        title = getTranslatedProperty(event, "title");
      }
      postOnFacebook(
        `${title.toUpperCase()}\n${getTranslatedProperty(
          event,
          "description"
        )}`,
        `${PWA_DEEPLINK_APP_URL}/detail-publication?id=${eventId}`
      );

      dispatch({
        type: CLEAR_SHARE_POST
      });
    }
  }, [sharePost]);

  // Empty view
  return <View />;
}
