import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import * as Clipboard from "expo-clipboard";
import * as ImagePicker from "expo-image-picker";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Text,
  View
} from "react-native";
import { Avatar, Input, Tile } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { getYoutubeMeta } from "react-native-youtube-iframe";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublishEvent } from "../actions/live";
import {
  CLEAR_POST_PHOTO_DATA,
  CLEAR_POST_VALIDATE,
  CLEAR_READY_TO_COPY_URL,
  RESET_POST,
  SET_DROP_DOWN_ALERT_INFO,
  SET_DROP_DOWN_ALERT_WARN,
  SET_POST_DESCRIPTION_EN,
  SET_POST_DESCRIPTION_FR,
  SET_POST_ENGLISH,
  SET_POST_PHOTO_DATA,
  SET_POST_TITLE_EN,
  SET_POST_TITLE_FR,
  SET_POST_VIDEO_ID,
  SET_READY_TO_COPY_URL,
  VALIDATE_POST_INPUTS
} from "../actions/types";
import { getImageUri } from "../assets/Images";
import ActionButton from "../components/actionButton/ActionButton";
import { ROLE_GUEST } from "../config/constants";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR5,
  isDesktop,
  isiOS,
  isWeb,
  SCREEN_HEIGHT,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import { openBrowser } from "../utils/UrlUtil";

const Post = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const descriptionInputRef = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const [videoMeta, setVideoMeta] = useState(null);

  const {
    isFetching,
    files,
    photoUri,
    title,
    description,
    descriptionErrorMsg,
    lang,
    clearPostValidate,
    isValid,
    isEnglish,
    readyToCopyUrl,
    youtubeVideoId,
    publishResult
  } = useSelector(state => state.postReducer);
  const { user } = useSelector(state => state.profileReducer);

  useEffect(() => {
    return () => dispatch({ type: RESET_POST });
  }, []);

  useEffect(() => {
    if (clearPostValidate) {
      // Validate inputs
      dispatch({
        type: VALIDATE_POST_INPUTS
      });
    }
  }, [clearPostValidate]);

  useEffect(() => {
    if (descriptionErrorMsg) {
      descriptionInputRef.current?.shake();
      descriptionInputRef.current?.focus();
    }
  }, [descriptionErrorMsg]);

  useEffect(() => {
    if (isValid) {
      dispatch(
        fetchPublishEvent({
          files,
          title,
          description,
          photoUri,
          youtubeVideoId,
          lang
        })
      );
    }
  }, [isValid]);

  useEffect(() => {
    if (publishResult) {
      navigation.goBack();
    }
  }, [publishResult]);

  const {
    bgLightgrey,
    bgColor1,
    bgWhite,
    row,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    mt10,
    m10,
    p10,
    font,
    fontBold,
    rounded5,
    w100p,
    shadowGrey,
    positionAbsolute,
    ml10,
    fs14,
    fs16,
    rounded10,
    mb10,
    overflowHidden
  } = commonStyles;

  const _pickImage = async () => {
    if (!isWeb) {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      if (isWeb) {
        const files = [];
        const name = "" + Date.now();
        files.push({
          name,
          base64: result.uri
        });
        dispatch({
          type: SET_POST_PHOTO_DATA,
          value: result.uri,
          files
        });
      } else {
        const files = [];
        const filename = result.uri.split("/").pop();
        const name = filename
          ? filename.substring(0, filename.lastIndexOf("."))
          : "" + Date.now();
        files.push({
          name,
          base64: "data:image/jpeg;base64," + result.base64
        });
        dispatch({
          type: SET_POST_PHOTO_DATA,
          value: result.uri,
          files
        });
      }
    }
  };

  const renderActionsButtons = () => {
    return (
      <ActionButton
        buttonColor={menuOpen ? COLOR5 : COLOR2}
        degrees={90}
        renderIcon={() => (
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={40}
            color="white"
          />
        )}
        onPressIn={() => setMenuOpen(true)}
        onReset={() => setMenuOpen(false)}
        disabled={isFetching}
      >
        <ActionButton.Item
          buttonColor={"red"}
          title={t("button:cancel")}
          onPress={() => {
            navigation.goBack();
          }}
          textStyle={[fontBold, fs16]}
        >
          <MaterialCommunityIcons name="cancel" size={36} color="white" />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor={COLOR2}
          title={t("button:post")}
          onPress={() =>
            user.role === ROLE_GUEST
              ? dispatch({
                  type: SET_DROP_DOWN_ALERT_WARN,
                  warn: "guestforbidden"
                })
              : dispatch({
                  type: CLEAR_POST_VALIDATE
                })
          }
          textStyle={[fontBold, fs16]}
        >
          <FontAwesome name="send" size={32} color="white" />
        </ActionButton.Item>
      </ActionButton>
    );
  };

  const onFocus = async () => {
    if (readyToCopyUrl) {
      try {
        if (isDesktop) {
          const text = await Clipboard.getStringAsync();
          let match;
          const re =
            /^((http:\/\/|https:\/\/|www.)\S+?\.(?:jpe?g|gif|png|tiff|svg))$/;
          if (re.test(text)) {
            dispatch({
              type: SET_POST_PHOTO_DATA,
              value: text
            });
          } else if (
            (match = text.match(
              /^(https:\/\/www.|https:\/\/)youtube.com\/watch\?v=([-_a-zA-Z0-9]+)$/
            )) ||
            (match = text.match(
              /^(https:\/\/www.|https:\/\/)youtu.be\/([-_a-zA-Z0-9]+)$/
            ))
          ) {
            dispatch({
              type: SET_POST_VIDEO_ID,
              value: match[2]
            });
            const videoMeta = await getYoutubeMeta(match[2]);
            if (!title) {
              dispatch({ type: SET_POST_TITLE_FR, value: videoMeta.title });
            }
            if (!description) {
              dispatch({
                type: SET_POST_DESCRIPTION_FR,
                value: `Vidéo de ${videoMeta.author_name}`
              });
            }
            setVideoMeta(videoMeta);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: CLEAR_READY_TO_COPY_URL });
      }
    }
  };

  const handleOnChangeText = async content => {
    const regEnd = /^[-_a-zA-Z0-9][\n.!, ]$/;
    let oldContent = "";
    if (isEnglish) {
      if (lang && lang.en && lang.en.description) {
        oldContent = lang.en.description;
      }
    } else if (description) {
      oldContent = description;
    }
    if (
      content === "" ||
      photoUri ||
      youtubeVideoId ||
      oldContent.length > content.length ||
      content.length < 8 ||
      !regEnd.test(content.substring(content.length - 2))
    ) {
      return;
    }
    let match;
    if (
      (match = content.match(
        /^.*((http:\/\/|https:\/\/|www.)\S+?\.(?:jpe?g|gif|png|tiff|svg))[\n.!, ]$/
      ))
    ) {
      dispatch({
        type: SET_POST_PHOTO_DATA,
        value: match[1]
      });
    }
    if (
      (match = content.match(
        /^.*(https:\/\/www.|https:\/\/)youtube.com\/watch\?v=([-_a-zA-Z0-9]+)[\n.!, ]$/
      )) ||
      (match = content.match(
        /^.*(https:\/\/www.|https:\/\/)youtu.be\/([-_a-zA-Z0-9]+)[\n.!, ]$/
      ))
    ) {
      dispatch({
        type: SET_POST_VIDEO_ID,
        value: match[2]
      });
      const videoMeta = await getYoutubeMeta(match[2]);
      if (!title) {
        dispatch({ type: SET_POST_TITLE_FR, value: videoMeta.title });
      }
      setVideoMeta(videoMeta);
    }
  };

  return (
    <KeyboardAvoidingView
      {...(isiOS && { behavior: "padding" })}
      style={[flex1, bgColor1]}
    >
      <ScrollView contentContainerStyle={[flex1, { paddingBottom: 90 }]}>
        <View
          style={[
            w100p,
            p10,
            { paddingTop: 54 },
            bgWhite,
            mt10,
            shadowGrey,
            alignItemsCenter
          ]}
        >
          {isEnglish ? (
            <Input
              value={lang && lang.en && lang.en.title ? lang.en.title : ""}
              onChangeText={value =>
                dispatch({ type: SET_POST_TITLE_EN, value })
              }
              onFocus={async () => {
                await onFocus();
              }}
              placeholder={t("live:title")}
            />
          ) : (
            <Input
              value={title || ""}
              onChangeText={value =>
                dispatch({ type: SET_POST_TITLE_FR, value })
              }
              onFocus={async () => {
                await onFocus();
              }}
              placeholder={t("live:title")}
            />
          )}
          {isEnglish ? (
            <Input
              multiline
              value={
                lang && lang.en && lang.en.description
                  ? lang.en.description
                  : ""
              }
              onChangeText={value => {
                handleOnChangeText(value);
                dispatch({ type: SET_POST_DESCRIPTION_EN, value });
              }}
              onFocus={async () => {
                await onFocus();
              }}
              placeholder={t("live:postenglish")}
            />
          ) : (
            <Input
              ref={descriptionInputRef}
              multiline
              value={description || ""}
              onChangeText={value => {
                handleOnChangeText(value);
                dispatch({ type: SET_POST_DESCRIPTION_FR, value });
              }}
              onFocus={async () => {
                await onFocus();
              }}
              errorMessage={descriptionErrorMsg}
              placeholder={t("live:postfrench")}
            />
          )}
          {videoMeta ? (
            <Tile
              activeOpacity={0.5}
              containerStyle={[
                {
                  height: ((SCREEN_WIDTH - 40) * 9) / 16,
                  width: SCREEN_WIDTH - 40
                },
                rounded10,
                mb10,
                overflowHidden
              ]}
              imageSrc={videoMeta.thumbnail_url}
              imageContainerStyle={[rounded10]}
              icon={{
                name: "play-circle",
                type: "font-awesome",
                size: 50,
                color: COLOR2
              }}
              onPress={() => {
                dispatch({ type: CLEAR_POST_PHOTO_DATA });
                setVideoMeta(null);
              }}
            />
          ) : (
            <Avatar
              containerStyle={[w100p, m10, { height: SCREEN_HEIGHT / 3 }]}
              size={SCREEN_HEIGHT / 3}
              placeholderStyle={[rounded5]}
              renderPlaceholderContent={
                <Image
                  style={[{ height: SCREEN_HEIGHT / 3 }, w100p, rounded5]}
                  source={getImageUri("placeholder")}
                />
              }
              source={
                Boolean(photoUri)
                  ? {
                      uri: photoUri
                    }
                  : undefined
              }
              overlayContainerStyle={[bgLightgrey, rounded5]}
              onPress={() => {
                Keyboard.dismiss();
                navigation.navigate("CameraScreen", {
                  from: "post"
                });
              }}
            />
          )}
          <ActionButton
            buttonColor={COLOR5}
            renderIcon={() => (
              <MaterialCommunityIcons
                name="dots-horizontal"
                size={34}
                color="white"
              />
            )}
            size={44}
            verticalOrientation="down"
            offsetX={10}
            offsetY={20}
            degrees={90}
            spacing={10}
          >
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:camera")}
              onPress={() => {
                Keyboard.dismiss();
                navigation.navigate("CameraScreen", {
                  from: "post"
                });
              }}
              textStyle={font}
              textHeight={24}
            >
              <MaterialIcons name="camera-alt" size={34} color="white" />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:albums")}
              onPress={_pickImage}
              textStyle={font}
              textHeight={24}
            >
              <FontAwesome name="image" size={28} color="white" />
            </ActionButton.Item>
            {isDesktop && (
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:copyyoutube")}
                onPress={() => {
                  dispatch({ type: SET_READY_TO_COPY_URL });
                  openBrowser(dispatch, "https://youtube.com");
                }}
                textStyle={font}
                textHeight={24}
              >
                <FontAwesome name="play" size={28} color="white" />
              </ActionButton.Item>
            )}
            {isDesktop && (
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:copygiphy")}
                onPress={() => {
                  dispatch({ type: SET_READY_TO_COPY_URL });
                  openBrowser(
                    dispatch,
                    "https://giphy.com/search/neighborhood"
                  );
                }}
                textStyle={font}
                textHeight={24}
              >
                <FontAwesome name="link" size={28} color="white" />
              </ActionButton.Item>
            )}
            <ActionButton.Item
              buttonColor={"white"}
              title={
                isEnglish ? t("button:postfrench") : t("button:postenglish")
              }
              onPress={() =>
                dispatch({ type: SET_POST_ENGLISH, payload: !isEnglish })
              }
              textStyle={font}
              textHeight={24}
            >
              <View style={[alignItemsCenter, justifyContentCenter]}>
                {isEnglish ? (
                  <Image
                    style={{ width: 24, height: 24 }}
                    source={getImageUri("FR")}
                  />
                ) : (
                  <Image
                    style={{ width: 24, height: 24 }}
                    source={getImageUri("GB")}
                  />
                )}
              </View>
            </ActionButton.Item>
            {Boolean(files.length > 0 || youtubeVideoId) && (
              <ActionButton.Item
                buttonColor={"red"}
                title={t("button:delete")}
                onPress={() => dispatch({ type: CLEAR_POST_PHOTO_DATA })}
                textStyle={font}
                textHeight={24}
              >
                <MaterialCommunityIcons
                  name={"delete-forever"}
                  size={34}
                  color="white"
                />
              </ActionButton.Item>
            )}
          </ActionButton>
          <View style={[positionAbsolute, { left: 20, top: 20 }]}>
            {isEnglish ? (
              <View style={[row, alignItemsCenter]}>
                <Image
                  style={{ width: 24, height: 24 }}
                  source={getImageUri("GB")}
                />
                <Text style={[ml10, font, fs14]}>
                  {t("button:postenglish")}
                </Text>
              </View>
            ) : (
              <View style={[row, alignItemsCenter]}>
                <Image
                  style={{ width: 24, height: 24 }}
                  source={getImageUri("FR")}
                />
                <Text style={[ml10, font, fs14]}>{t("button:postfrench")}</Text>
              </View>
            )}
          </View>
        </View>
        {isiOS && renderActionsButtons()}
      </ScrollView>
      {!isiOS && renderActionsButtons()}
    </KeyboardAvoidingView>
  );
};

const MemoizedPost = memo(Post);
export default MemoizedPost;
