import {
  FETCH_GET_EVENT_DETAILS,
  SET_GET_EVENT_DETAILS_RESULT,
  FETCH_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const user = new schema.Entity("users", { idAttribute: "_id" });

const event = new schema.Entity(
  "events",
  {
    userId: user
  },
  {
    idAttribute: "_id"
  }
);

export function fetchGetEventDetails(eventId) {
  return apiAction({
    url: "/events/details/" + eventId,
    method: "GET",
    schema: event,
    onSuccess: setGetEventDetailsResult,
    onFailure: setFetchFailure,
    label: FETCH_GET_EVENT_DETAILS
  });
}

function setGetEventDetailsResult(normalized) {
  return {
    type: SET_GET_EVENT_DETAILS_RESULT,
    entities: normalized.entities
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}
