import * as Notifications from "expo-notifications";
import merge from "lodash/merge";
import {
  API_END,
  API_START,
  CHAT,
  CLEAR_CHAT_MAP_DATA,
  CLEAR_CHAT_PHOTO_DATA,
  CLEAR_CHAT_VIDEO_ID,
  CLEAR_READY_TO_COPY_URL,
  FETCH_GET_CHAT_NEIGHBORS,
  FETCH_GET_CHAT_EMPLOYEES,
  FETCH_GET_CHAT_EVENTS,
  FETCH_GET_CHAT_MESSAGES,
  FETCH_GET_MY_CHAT_USERS,
  NAVIGATION,
  OFFLINE,
  ONLINE,
  RELOAD_CHAT_USERS,
  RESET_DATA,
  RESET_NOT_READ,
  SET_CHAT_NEIGHBORS,
  SET_CHAT_CURRENT_LOCATION,
  SET_CHAT_EMPLOYEES,
  SET_CHAT_EVENTS_RESULT,
  SET_CHAT_EXIT_RESULT,
  SET_CHAT_MESSAGES,
  SET_CHAT_PHOTO_DATA,
  SET_CHAT_VIDEO_ID,
  SET_IN_CHAT_WITH,
  SET_MY_CHAT_USERS,
  SET_PROFILE_DETAILS,
  SET_READY_TO_COPY_URL,
  UPDATE_GLOBAL_NOT_READ,
  WS_CONNECTED,
  WS_EVENT
} from "../actions/types";
import { ROLE_NEIGHBOR } from "../config/constants";
import { isNative } from "../styles/commonStyles";

const initialState = {
  isFetching: false,
  isFetchingDataEvents: false,
  isFetchingDataEmployees: false,
  isFetchingDataNeighbors: false,
  chatevents: {},
  liveevents: {},
  livetemplates: {},
  data: null,
  dataEmployees: null,
  dataNeighbors: null,
  reload: 0,
  inChatWith: null,
  totalNotRead: 0,
  messages: {},
  to: null,
  userId: null,
  role: null,
  eventId: null,
  page: 1,
  hasNextPage: false,
  nextPage: 2,
  totalPages: 0,
  totalDocs: 0,
  image: null,
  files: null,
  location: null,
  readyToCopyUrl: false,
  title: null,
  templateId: null,
  youtubeVideoId: null
};

async function updateBadgeNumber(badgeNumber) {
  if (Number.isInteger(badgeNumber)) {
    await Notifications.setBadgeCountAsync(badgeNumber);
  }
}

export default function chatReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      switch (action.payload) {
        case FETCH_GET_MY_CHAT_USERS:
          return {
            ...state,
            isFetching: true,
            data: null
          };
        case FETCH_GET_CHAT_EMPLOYEES:
          return {
            ...state,
            isFetchingDataEmployees: true,
            dataEmployees: null
          };
        case FETCH_GET_CHAT_NEIGHBORS:
          return {
            ...state,
            isFetchingDataNeighbors: true,
            dataNeighbors: null
          };
        case FETCH_GET_CHAT_EVENTS:
          return {
            ...state,
            isFetchingDataEvents: true,
            chatevents: {},
            liveevents: {},
            livetemplates: {}
          };
        case FETCH_GET_CHAT_MESSAGES:
          return {
            ...state,
            isFetching: true,
            title: null,
            templateId: null
          };
        default:
          break;
      }
      break;

    case RESET_DATA:
      return {
        ...initialState
      };

    case RELOAD_CHAT_USERS:
    case WS_CONNECTED:
      if (state.data) {
        return {
          ...state,
          reload: ++state.reload
        };
      }
      break;

    case SET_READY_TO_COPY_URL:
      return {
        ...state,
        readyToCopyUrl: true
      };

    case CLEAR_READY_TO_COPY_URL:
      return {
        ...state,
        readyToCopyUrl: false
      };

    case SET_CHAT_EXIT_RESULT:
      return {
        ...state,
        messages: {},
        page: 1,
        hasNextPage: false,
        nextPage: 2,
        totalPages: 0,
        totalDocs: 0,
        image: null,
        files: null,
        location: null,
        to: null,
        inChatWith: null,
        youtubeVideoId: null
      };

    case SET_IN_CHAT_WITH: {
      // Reset notRead because we enter chatMessage view
      let foundIndex = -1;
      if (state.data) {
        foundIndex = state.data.findIndex(user => user._id === action.value);
      }
      if (foundIndex >= 0) {
        const newData = [...state.data];
        newData[foundIndex] = {
          ...newData[foundIndex],
          notRead: 0
        };

        return {
          ...state,
          data: newData,
          inChatWith: action.value,
          to: action.value
        };
      }
      return {
        ...state,
        inChatWith: action.value
      };
    }

    case NAVIGATION:
      if (action.name === "ChatGrpScreen" && action.params.eventId) {
        const chatevents = {};
        Object.assign(chatevents, {
          ...state.chatevents
        });
        if (chatevents[action.params.eventId]) {
          chatevents[action.params.eventId].notRead = 0;
        }
        return {
          ...state,
          inChatWith: action.params.eventId,
          eventId: action.params.eventId,
          chatevents
        };
      } else if (action.name === "ChatUsersScreen" && state.data) {
        return {
          ...state,
          reload: ++state.reload
        };
      }
      break;

    case SET_MY_CHAT_USERS:
      if (isNative) {
        updateBadgeNumber(action.payload.totalNotRead);
      }
      return {
        ...state,
        data: action.payload.users,
        totalNotRead: action.payload.totalNotRead
      };

    case SET_CHAT_EMPLOYEES:
      return {
        ...state,
        dataEmployees: action.payload.users
      };

    case SET_CHAT_NEIGHBORS:
      return {
        ...state,
        dataNeighbors: action.payload.users
      };

    case SET_CHAT_EVENTS_RESULT:
      return merge({}, state, action.entities);

    case WS_EVENT:
      switch (action.payload.type) {
        case CHAT: {
          if (state.data) {
            if (action.payload.from._id === state.userId) {
              // confirmation that message we sent is correctly sent
              const { tmpId, notRead, to, ...others } = action.payload;
              const messages = { ...state.messages };

              messages[tmpId] = {
                ...messages[tmpId],
                ...others,
                sent: true,
                pending: false,
                received: Boolean(notRead === 0 && !action.payload.eventId),
                user: state.messages[tmpId].user,
                notRead,
                to
              };

              let reload = state.reload;
              if (to && state.data.findIndex(user => user._id === to) < 0) {
                reload++;
              }

              return {
                ...state,
                messages,
                reload
              };
            } else if (
              action.payload.from._id === state.inChatWith ||
              action.payload.eventId === state.inChatWith
            ) {
              const { _id, eventId, from, notRead, ...others } = action.payload;
              const { pseudo, firstname, photoUri, ...userOthers } = from;
              const messages = {
                [_id]: {
                  _id,
                  eventId,
                  user: {
                    name:
                      pseudo.indexOf("@") < 0 && state.role === ROLE_NEIGHBOR
                        ? pseudo
                        : firstname,
                    avatar: photoUri,
                    ...userOthers
                  },
                  notRead,
                  ...others
                }
              };
              Object.assign(messages, {
                ...state.messages
              });

              return {
                ...state,
                messages
              };
            }
            // We receive a new group message => update notRead only if we are not in the ChatScreen
            else if (
              action.payload.eventId &&
              (!state.inChatWith || state.inChatWith !== action.payload.eventId)
            ) {
              const { eventId, notRead } = action.payload;
              const chatevents = {};
              Object.assign(chatevents, {
                ...state.chatevents
              });
              if (eventId && chatevents[eventId]) {
                chatevents[eventId].notRead = notRead;
              }
              return {
                ...state,
                chatevents
              };
            }
            // We receive a new message => update notRead only if we are not in the ChatScreen
            else if (
              action.payload.to &&
              (!state.inChatWith ||
                state.inChatWith !== action.payload.from._id)
            ) {
              let foundIndex = state.data.findIndex(
                user => user._id === action.payload.from._id
              );
              let foundIndexEmployees = state.dataEmployees
                ? state.dataEmployees.findIndex(
                    user => user._id === action.payload.from._id
                  )
                : -1;
              let foundIndexNeighbors = state.dataNeighbors
                ? state.dataNeighbors.findIndex(
                    user => user._id === action.payload.from._id
                  )
                : -1;
              if (foundIndex >= 0) {
                const newData = [...state.data];
                newData[foundIndex] = {
                  ...newData[foundIndex],
                  notRead: ++newData[foundIndex].notRead
                };

                let newDataEmployees = state.dataEmployees
                  ? [...state.dataEmployees]
                  : null;
                if (foundIndexEmployees >= 0) {
                  newDataEmployees[foundIndexEmployees] = {
                    ...newDataEmployees[foundIndexEmployees],
                    notRead: ++newDataEmployees[foundIndexEmployees].notRead
                  };
                }

                let newDataNeighbors = state.dataNeighbors
                  ? [...state.dataNeighbors]
                  : null;
                if (foundIndexNeighbors >= 0) {
                  newDataNeighbors[foundIndexNeighbors] = {
                    ...newDataNeighbors[foundIndexNeighbors],
                    notRead: ++newDataNeighbors[foundIndexNeighbors].notRead
                  };
                }

                return {
                  ...state,
                  data: newData,
                  dataEmployees: newDataEmployees,
                  dataNeighbors: newDataNeighbors
                };
              } else {
                // new user: we refresh
                return {
                  ...state,
                  reload: ++state.reload
                };
              }
            }
          }
          break;
        }

        case OFFLINE:
        case ONLINE:
          {
            if (state.data) {
              let foundIndex = state.data.findIndex(
                user => user._id === action.payload.userId
              );
              let foundIndexEmployees = state.dataEmployees
                ? state.dataEmployees.findIndex(
                    user => user._id === action.payload.userId
                  )
                : -1;
              let foundIndexNeighbors = state.dataNeighbors
                ? state.dataNeighbors.findIndex(
                    user => user._id === action.payload.userId
                  )
                : -1;
              const data = state.data ? [...state.data] : null;
              const dataEmployees = state.dataEmployees
                ? [...state.dataEmployees]
                : null;
              const dataNeighbors = state.dataNeighbors
                ? [...state.dataNeighbors]
                : null;
              if (foundIndex >= 0) {
                data[foundIndex] = {
                  ...data[foundIndex],
                  online: action.payload.type === ONLINE ? true : false
                };
              }
              if (foundIndexEmployees >= 0) {
                dataEmployees[foundIndexEmployees] = {
                  ...dataEmployees[foundIndexEmployees],
                  online: action.payload.type === ONLINE ? true : false
                };
              }
              if (foundIndexNeighbors >= 0) {
                dataNeighbors[foundIndexNeighbors] = {
                  ...dataNeighbors[foundIndexNeighbors],
                  online: action.payload.type === ONLINE ? true : false
                };
              }
              return {
                ...state,
                data,
                dataEmployees,
                dataNeighbors
              };
            }
          }
          break;

        case UPDATE_GLOBAL_NOT_READ:
          if (isNative) {
            updateBadgeNumber(action.payload.notRead);
          }
          return {
            ...state,
            totalNotRead: action.payload.notRead
          };

        // user we chat with has enter chat => all messages should be received
        case RESET_NOT_READ:
          if (action.payload.to === state.inChatWith) {
            const messages = {};
            Object.assign(messages, {
              ...state.messages
            });

            const msgs = Object.values(state.messages);
            if (msgs && msgs.length) {
              for (let notRead = msgs[0].notRead; notRead > 0; notRead--) {
                msgs[notRead - 1].received = true;
              }
              return {
                ...state,
                messages
              };
            }
          }
          break;

        default:
          break;
      }
      break;

    case SET_CHAT_MESSAGES:
      {
        const { chatmessages, users, infospages } = action.entities;
        const {
          page,
          hasNextPage,
          totalPages,
          totalDocs,
          to,
          title,
          templateId
        } = infospages["1"];

        if (chatmessages) {
          const messages = Object.values(chatmessages);
          if (messages && messages.length) {
            let notRead = messages[0].notRead;
            for (const message of messages) {
              if (users[message.from]) {
                const {
                  _id,
                  photoUri: avatar,
                  pseudo,
                  firstname,
                  gender
                } = users[message.from];
                const name =
                  pseudo.indexOf("@") < 0 && state.role === ROLE_NEIGHBOR
                    ? pseudo
                    : firstname;
                message.user = {
                  _id,
                  name,
                  avatar,
                  gender
                };
              } else {
                message.user = {
                  _id: message.from
                };
              }
              message.sent = true;
              message.received = notRead <= 0 && !state.eventId;
              delete message.from;
              notRead--;
            }
            if (page === 1) {
              return {
                ...state,
                messages: chatmessages,
                page,
                nextPage: 1 + page,
                hasNextPage,
                totalPages,
                totalDocs,
                to: to ? to : state.to,
                title,
                templateId
              };
            } else {
              return merge({}, state, {
                messages: chatmessages,
                page,
                nextPage: 1 + page,
                hasNextPage,
                totalPages,
                totalDocs
              });
            }
          }
        }
        return {
          ...state,
          to,
          title,
          templateId
        };
      }
      break;

    case SET_PROFILE_DETAILS:
      return {
        ...state,
        userId: action.payload._id,
        role: action.payload.role
      };

    case CHAT:
      const { _id, type, meta, ...others } = action;
      const messages = {
        [_id]: {
          _id,
          user: meta.user,
          pending: true,
          createdAt: Date.now(),
          ...others
        }
      };
      Object.assign(messages, {
        ...state.messages
      });
      return {
        ...state,
        messages,
        image: null,
        files: null,
        location: null,
        youtubeVideoId: null
      };

    case SET_CHAT_PHOTO_DATA:
      return {
        ...state,
        image: action.value,
        files: action.files,
        location: null,
        youtubeVideoId: null
      };

    case SET_CHAT_VIDEO_ID:
      return {
        ...state,
        youtubeVideoId: action.value,
        image: null,
        files: null,
        location: null
      };

    case CLEAR_CHAT_VIDEO_ID:
      return {
        ...state,
        youtubeVideoId: null
      };

    case CLEAR_CHAT_PHOTO_DATA:
      return {
        ...state,
        image: null,
        files: null
      };

    case CLEAR_CHAT_MAP_DATA:
      return {
        ...state,
        location: null
      };

    case SET_CHAT_CURRENT_LOCATION:
      return {
        ...state,
        location: action.location,
        image: null,
        files: null,
        youtubeVideoId: null
      };

    case API_END:
      switch (action.payload) {
        case FETCH_GET_CHAT_MESSAGES:
        case FETCH_GET_MY_CHAT_USERS:
          return {
            ...state,
            isFetching: false
          };
        case FETCH_GET_CHAT_EMPLOYEES:
          return {
            ...state,
            isFetchingDataEmployees: false
          };
        case FETCH_GET_CHAT_NEIGHBORS:
          return {
            ...state,
            isFetchingDataNeighbors: false
          };
        case FETCH_GET_CHAT_EVENTS:
          return {
            ...state,
            isFetchingDataEvents: false
          };
        default:
          break;
      }
      break;

    default:
      return state;
  }
  return state;
}
