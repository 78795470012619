import React from "react";
import { useSelector } from "react-redux";
import DocReader from "./DocReader";

function PdfWelcomeScreen() {
  const { currentCommunity } = useSelector(state => state.communityReducer);

  const { pdfUri } = currentCommunity || {};

  return <DocReader uri={pdfUri} sharing={true} />;
}

export default PdfWelcomeScreen;
