import {
  API_START,
  API_END,
  FETCH_GET_LIVE_EVENTS,
  SET_LIVE_EVENTS,
  WS_EVENT,
  WS_CONNECTED,
  LIVE,
  LIVE_UPDATE,
  LIVE_DELETE,
  RESET_DATA,
  LIVE_ON_TOP,
  SET_INTERESTED_LIVE_EVENT_RESULT,
  SET_UNINTERESTED_LIVE_EVENT_RESULT,
  SET_FILTER,
  DISPLAY_ALL,
  DISPLAY_ONLY_MY_POSTS,
  DISPLAY_ONLY_COMMUNITY_POSTS,
  SET_PROFILE_DETAILS,
  LIVE_INTERESTED,
  LIVE_UNINTERESTED,
  NAVIGATION,
  SET_DISPLAY_FILTER_OPTION,
  LIVE_REFRESH
} from "../actions/types";
import { GROUP_ID_ACTIVITY, GROUP_ID_PROPOSAL } from "../config/constants";
import merge from "lodash/merge";
import { event } from "../actions/live";
import { normalize } from "normalizr";

const initialState = {
  isFetching: true,
  inLiveScreen: false,
  liveOnTop: true,
  cptNewEvents: 0,
  reload: 0,
  refresh: 0,
  page: 1,
  hasNextPage: false,
  nextPage: 2,
  totalPages: 0,
  totalDocs: 0,
  events: {},
  users: {},
  interestedusers: {},
  displayFilterOptions: false,
  filter: DISPLAY_ALL,
  user: null
};

export default function liveReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_LIVE_EVENTS) {
        return {
          ...state,
          isFetching: true
        };
      }
      break;

    case RESET_DATA:
      return {
        ...initialState
      };

    case SET_FILTER:
      // We reinitialize a part of the state
      return {
        ...state,
        reload: 0,
        page: 1,
        hasNextPage: false,
        nextPage: 2,
        totalPages: 0,
        totalDocs: 0,
        events: {},
        users: {},
        interestedusers: {},
        filter: action.payload
      };

    case SET_DISPLAY_FILTER_OPTION:
      return {
        ...state,
        displayFilterOptions: action.payload
      };

    case SET_PROFILE_DETAILS: {
      const { _id, photoUri, firstname } = action.payload;
      const interestedusers = { ...state.interestedusers };
      interestedusers[_id] = { _id, photoUri, firstname };
      return {
        ...state,
        userId: _id,
        interestedusers
      };
    }

    case WS_CONNECTED:
      return {
        ...state,
        reload: ++state.reload
      };

    case LIVE_REFRESH:
      return {
        ...state,
        refresh: ++state.refresh
      };

    case SET_LIVE_EVENTS: {
      const { events, users, interestedusers, infospages } = action.entities;
      const { page, hasNextPage, totalPages, totalDocs } = infospages["1"];

      if (page === 1) {
        return {
          ...state,
          events: events ? events : {},
          users: users ? users : {},
          interestedusers: interestedusers ? interestedusers : {},
          page,
          nextPage: 1 + page,
          hasNextPage,
          totalPages,
          totalDocs
        };
      } else {
        return merge({}, state, {
          events,
          users,
          interestedusers,
          page,
          nextPage: 1 + page,
          hasNextPage,
          totalPages,
          totalDocs
        });
      }
    }

    case LIVE_ON_TOP:
      return {
        ...state,
        liveOnTop: action.value,
        cptNewEvents: action.value ? 0 : state.cptNewEvents
      };

    case NAVIGATION:
      if (
        action.name === "TabScreen" ||
        action.name === "LiveScreen" ||
        action.name === "LiveScreens"
      ) {
        return {
          ...state,
          inLiveScreen: true,
          cptNewEvents: state.liveOnTop ? 0 : state.cptNewEvents
        };
      } else {
        return {
          ...state,
          inLiveScreen: false
        };
      }

    case WS_EVENT: {
      if (action.payload.type === LIVE) {
        // manage filter
        if (
          (state.filter === DISPLAY_ONLY_MY_POSTS &&
            action.payload.userId._id !== state.userId) ||
          (state.filter === DISPLAY_ONLY_COMMUNITY_POSTS &&
            (action.payload.groupId === GROUP_ID_ACTIVITY ||
              action.payload.groupId === GROUP_ID_PROPOSAL))
        ) {
          // ignore event
          break;
        }
        const normalized = normalize(action.payload, event);
        let { users, interestedusers, events } = normalized.entities;
        if (action.payload.groupId === "weather") {
          const { [Object.keys(events)[0]]: id, ...others } = state.events;
          // delete others[Object.keys(events)[0]];
          return {
            ...state,
            events: {
              ...events,
              ...others
            }
          };
          // return { ...state, events };
        } else {
          return merge(
            {},
            {
              users,
              interestedusers,
              events,
              cptNewEvents:
                state.inLiveScreen && state.liveOnTop ? 0 : ++state.cptNewEvents
            },
            state
          );
        }
      } else if (action.payload.type === LIVE_DELETE) {
        const { [action.payload.id]: value, ...withoutSecond } = state.events;
        // When a record is removed, we stay in the same page
        return {
          ...state,
          nextPage: state.page,
          events: { ...withoutSecond }
        };
      } else if (action.payload.type === LIVE_INTERESTED) {
        const { eventId, interestedUsersIds, user } = action.payload;
        if (state.events[eventId]) {
          const events = { ...state.events };
          events[eventId].interestedUsersIds = interestedUsersIds;
          const interestedusers = { ...state.interestedusers };
          interestedusers[user._id] = user;
          return {
            ...state,
            events,
            interestedusers
          };
        }
      } else if (action.payload.type === LIVE_UNINTERESTED) {
        const { eventId, interestedUsersIds } = action.payload;
        if (state.events[eventId]) {
          const events = { ...state.events };
          events[eventId].interestedUsersIds = interestedUsersIds;
          const interestedusers = {};
          Object.assign(interestedusers, state.interestedusers);
          return {
            ...state,
            events,
            interestedusers
          };
        }
      }
      break;
    }

    case SET_INTERESTED_LIVE_EVENT_RESULT:
    case SET_UNINTERESTED_LIVE_EVENT_RESULT: {
      if (state.events[action.payload._id]) {
        const events = { ...state.events };
        events[action.payload._id].interestedUsersIds =
          action.payload.interestedUsersIds;
        return {
          ...state,
          events
        };
      }
      break;
    }

    case API_END:
      if (action.payload === FETCH_GET_LIVE_EVENTS) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
