import { createSelector } from "reselect";
import _ from "lodash";
import { TYPE_ACTIVITY, TYPE_TEMPLATE } from "../actions/types";
import { TYPE_PROPOSAL } from "../config/constants";
import { formatDateIntl } from "../utils/DateUtils";

export const getLiveReducer = state => state.liveReducer;
export const getEntitiesReducer = state => state.entitiesReducer;
export const getGlobalInformationsReducer = state =>
  state.globalInformationsReducer;
export const getProfileReducer = state => state.profileReducer;
export const getTemplateReducer = state => state.templateReducer;
export const getErrorReducer = state => state.errorReducer;
export const getLoginReducer = state => state.loginReducer;
export const getCommunityReducer = state => state.communityReducer;
export const getUrlscanReducer = state => state.urlscanReducer;
export const getChatReducer = state => state.chatReducer;
export const getEventInterestedUsersReducer = state =>
  state.eventInterestedUsersReducer;
export const getAdReducer = state => state.adReducer;
export const getDeeplinkReducer = state => state.deeplinkReducer;

const getCategories = state => state.entitiesReducer.categories;
const getTemplates = state => state.entitiesReducer.templates;
const getUserId = state =>
  state.profileReducer.user ? state.profileReducer.user._id : null;

export const getActivities = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.type === TYPE_ACTIVITY)
);

export const getProposals = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.type === TYPE_PROPOSAL)
);

export const getTypeTemplates = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.type === TYPE_TEMPLATE)
);

export const getUserTemplates = createSelector(
  [getTemplates, getUserId],
  (templates, userId) =>
    _.pickBy(
      templates,
      template => template.type === TYPE_TEMPLATE && template.userId === userId
    )
);

export const getNotifTemplates = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.acceptNotif)
);

export const getCategoriesForActivities = createSelector(
  [getCategories, getActivities],
  getFilteredCategories()
);

export const getCategoriesForProposals = createSelector(
  [getCategories, getProposals],
  getFilteredCategories()
);

export const getCategoriesForTemplates = createSelector(
  [getCategories, getTypeTemplates],
  getFilteredCategories()
);

export const getCategoriesForUser = createSelector(
  [getCategories, getUserTemplates],
  getFilteredCategories()
);

export const getCategoriesForNotif = createSelector(
  [getCategories, getNotifTemplates],
  getFilteredCategories()
);

export const getLoadingAppSelector = createSelector(
  [
    getProfileReducer,
    getGlobalInformationsReducer,
    getErrorReducer,
    getLoginReducer,
    getCommunityReducer
  ],
  (
    { user },
    { initialUrl, clientVersion, serverVersion, accessDenied },
    { dropDownAlertError },
    { fromLogin },
    { currentCommunity, isNotFound }
  ) => {
    return {
      user,
      initialUrl,
      clientVersion,
      serverVersion,
      accessDenied,
      dropDownAlertError,
      fromLogin,
      currentCommunity,
      isNotFound
    };
  }
);

export const getLandingSelector = createSelector(
  [
    getGlobalInformationsReducer,
    getErrorReducer,
    getUrlscanReducer,
    getCommunityReducer,
    getDeeplinkReducer,
    getLoginReducer
  ],
  (
    { accessDenied, dropDownAlertInfoGI, dropDownAlertErrorGI, initialLink },
    { dropDownAlertError, dropDownAlertInfo },
    { link },
    {
      cities,
      currentCommunity,
      computedCommunities,
      needRegisterKey,
      currentCity
    },
    { deeplink },
    { showLoginModal, routeName }
  ) => {
    return {
      accessDenied,
      dropDownAlertInfoGI,
      dropDownAlertErrorGI,
      initialLink,
      dropDownAlertError,
      dropDownAlertInfo,
      link,
      cities,
      currentCommunity,
      computedCommunities,
      needRegisterKey,
      currentCity,
      deeplink,
      showLoginModal,
      isLandingScreen: Boolean(routeName === "LandingScreen")
    };
  }
);

export const getLoginSelector = createSelector(
  [
    getLoginReducer,
    getGlobalInformationsReducer,
    getErrorReducer,
    getCommunityReducer,
    getProfileReducer
  ],
  (
    {
      isFetching,
      emailOrPseudo,
      emailOrPseudoErrorMsg,
      password,
      passwordErrorMsg,
      valid,
      clearValidate,
      registerkey,
      registerkeyErrorMsg,
      clearValidateApple,
      validApple,
      clearForgetPwdValidate,
      validForgetPwd,
      showLoginModal,
      selectedCategory,
      token,
      tokenErrorMsg,
      hideRegisterKey,
      isTooltip3Visible,
      isTooltip4Visible,
      isTooltip6Visible,
      clearValidateSignupGuest,
      validSignupGuest,
      fromLogin
    },
    { lang, installationId },
    { dropDownAlertInfo, dropDownAlertError },
    { currentCommunity },
    { user, strategy }
  ) => {
    return {
      isFetching,
      emailOrPseudo,
      emailOrPseudoErrorMsg,
      password,
      passwordErrorMsg,
      valid,
      clearValidate,
      registerkey,
      registerkeyErrorMsg,
      clearValidateApple,
      validApple,
      clearForgetPwdValidate,
      validForgetPwd,
      showLoginModal,
      selectedCategory,
      token,
      tokenErrorMsg,
      hideRegisterKey,
      isTooltip3Visible,
      isTooltip4Visible,
      isTooltip6Visible,
      clearValidateSignupGuest,
      validSignupGuest,
      fromLogin,
      lang,
      installationId,
      dropDownAlertInfo,
      dropDownAlertError,
      currentCommunity,
      user,
      strategy
    };
  }
);

export const getAppNavigationSelector = createSelector(
  [
    getProfileReducer,
    getGlobalInformationsReducer,
    getTemplateReducer,
    getCommunityReducer
  ],
  (
    { user },
    { accessDenied = false, isFetchingError, lang },
    { displayPreview },
    { currentCommunity }
  ) => {
    return {
      user,
      accessDenied,
      isFetchingError,
      lang,
      displayPreview,
      communityDetails: currentCommunity
    };
  }
);

export const getProfileUpdateSelector = createSelector(
  [getProfileReducer, getLoginReducer],
  (
    {
      files,
      firstname,
      lastname,
      gender,
      birthdate,
      address,
      zipCode,
      city,
      phone,
      description,
      photoUri,
      hobbiesIds,
      firstnameErrorMsg,
      lastnameErrorMsg,
      birthdayErrorMsg,
      confirmAddressErrorMsg,
      confirmAddress,
      user,
      isValid,
      isFetching,
      firstLogin,
      clearProfileValidate,
      phoneErrorMsg,
      floor,
      apartmentNumber,
      job,
      building,
      buildingAccess,
      residence,
      district,
      needRegisterForPush,
      updateHobbies,
      strategy,
      newpassword,
      newpasswordconfirmation,
      passwordErrorMsg,
      passwordConfirmationErrorMsg
    },
    { deleteUserResult }
  ) => {
    return {
      files,
      firstname,
      lastname,
      gender,
      birthdate,
      address,
      zipCode,
      city,
      phone,
      description,
      photoUri,
      hobbiesIds,
      firstnameErrorMsg,
      lastnameErrorMsg,
      birthdayErrorMsg,
      confirmAddressErrorMsg,
      confirmAddress,
      user,
      isValid,
      isFetching,
      firstLogin,
      clearProfileValidate,
      phoneErrorMsg,
      floor,
      apartmentNumber,
      job,
      building,
      buildingAccess,
      residence,
      district,
      needRegisterForPush,
      updateHobbies,
      strategy,
      newpassword,
      newpasswordconfirmation,
      passwordErrorMsg,
      passwordConfirmationErrorMsg,
      deleteUserResult
    };
  }
);

export const getProfileSelector = createSelector(
  [getProfileReducer],
  ({
    isFetching,
    photoUri,
    hobbiesIds,
    pseudo,
    firstname,
    lastname,
    age,
    gender,
    floor,
    apartmentNumber,
    job,
    buildingAccess,
    description,
    _id,
    role,
    user,
    phone,
    company
  }) => {
    return {
      isFetching,
      photoUri,
      hobbiesIds,
      pseudo,
      firstname,
      lastname,
      age,
      description,
      gender,
      floor,
      apartmentNumber,
      job,
      buildingAccess,
      to: _id,
      role,
      myRole: user ? user.role : null,
      blockedUsersIds: user ? new Set(user.blockedUsersIds) : new Set(),
      phone,
      company
    };
  }
);

export const getChatSelector = createSelector(
  [
    getProfileReducer,
    getGlobalInformationsReducer,
    getEntitiesReducer,
    getChatReducer
  ],
  (
    { user },
    { lang },
    { templates },
    {
      messages,
      isFetching,
      hasNextPage,
      nextPage,
      page,
      image,
      files,
      location,
      readyToCopyUrl,
      title,
      to: chatTo,
      templateId,
      youtubeVideoId,
      reload
    }
  ) => {
    return {
      user,
      lang,
      templates,
      messages,
      isFetching,
      hasNextPage,
      nextPage,
      page,
      image,
      files,
      location,
      readyToCopyUrl,
      title,
      chatTo,
      templateId,
      youtubeVideoId,
      reload
    };
  }
);

export const getLiveSelector = createSelector(
  [
    getLiveReducer,
    getGlobalInformationsReducer,
    getProfileReducer,
    getCommunityReducer,
    getDeeplinkReducer
  ],
  (
    {
      events,
      isFetching,
      liveOnTop,
      reload,
      nextPage,
      hasNextPage,
      filter,
      inLiveScreen
    },
    { initialLink },
    { user },
    { currentCommunity },
    { deeplink }
  ) => {
    return {
      events,
      isFetching,
      liveOnTop,
      reload,
      nextPage,
      hasNextPage,
      filter,
      inLiveScreen,
      initialLink,
      user,
      community: currentCommunity,
      deeplink
    };
  }
);

export const getLiveEventSelector = createSelector(
  [
    getLiveReducer,
    getEntitiesReducer,
    getTemplateReducer,
    getProfileReducer,
    getAdReducer
  ],
  (
    { interestedusers, inLiveScreen },
    { templates, locations },
    { equipments, templateDates, templateTimes },
    { user },
    { isFetching, ad }
  ) => {
    return {
      interestedusers,
      inLiveScreen,
      templates,
      locations,
      equipments,
      templateDates,
      templateTimes,
      user,
      inLiveScreen,
      isFetching,
      ad
    };
  }
);

export const getLiveHeaderSelector = date =>
  createSelector(
    [getProfileReducer, getLiveReducer, getGlobalInformationsReducer],
    ({ user }, { users }, { lang, datetimeDiff }) => {
      const dateStr = formatDateIntl(lang, date, datetimeDiff);
      return {
        user,
        users,
        dateStr
      };
    }
  );

export const getErrorManagementSelector = createSelector(
  [getErrorReducer, getLoginReducer, getGlobalInformationsReducer],
  (
    {
      isMaintenance,
      dropDownAlertInfo,
      dropDownAlertError,
      dropDownAlertMessage,
      dropDownAutoClearAlertError,
      dropDownAutoClearAlertWarn,
      dropDownAutoClearAlertInfo,
      splashScreenHide,
      logout: errorlogout
    },
    { logout, logoutResult },
    {
      dropDownAlertInfo: dropDownAlertInfoGI,
      dropDownAlertWarn,
      dropDownAlertError: dropDownAlertErrorGI
    }
  ) => {
    return {
      isMaintenance,
      dropDownAlertInfo,
      dropDownAlertError,
      dropDownAlertMessage,
      dropDownAutoClearAlertError,
      dropDownAutoClearAlertWarn,
      dropDownAutoClearAlertInfo,
      splashScreenHide,
      errorlogout,
      logout,
      logoutResult,
      dropDownAlertInfoGI,
      dropDownAlertWarn,
      dropDownAlertErrorGI
    };
  }
);

function getFilteredCategories() {
  return (categories, templates) =>
    _.pickBy(
      categories,
      category =>
        _.filter(category.templateIds, templateId => templates[templateId])
          .length > 0
    );
}
