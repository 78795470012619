import {
  API_START,
  API_END,
  FETCH_GET_CURRENT_METEO,
  SET_CURRENT_METEO,
  FETCH_GET_FORECAST_METEO,
  SET_FORECAST_METEO,
  NAVIGATION
} from "../actions/types";

const initialState = {
  isFetchingNow: false,
  isFetching: false,
  meteoNow: null,
  meteo: null,
  reload: 0
};

export default function meteoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_CURRENT_METEO) {
        return {
          ...state,
          isFetchingNow: true,
          meteoNow: null
        };
      }
      if (action.payload === FETCH_GET_FORECAST_METEO) {
        return {
          ...state,
          isFetching: true,
          meteo: null
        };
      }
      break;

    case NAVIGATION:
      if (action.name === "MeteoScreen") {
        return {
          ...state,
          reload: ++state.reload
        };
      }
      break;

    case SET_CURRENT_METEO:
      return {
        ...state,
        meteoNow: action.payload
      };

    case SET_FORECAST_METEO:
      return {
        ...state,
        meteo: action.payload
      };

    case API_END:
      if (action.payload === FETCH_GET_CURRENT_METEO) {
        return {
          ...state,
          isFetchingNow: false
        };
      }
      if (action.payload === FETCH_GET_FORECAST_METEO) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
