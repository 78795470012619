import { SET_DEEP_LINK } from "../actions/types";

const initialState = {
  deeplink: null
};

export default function deeplinkReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DEEP_LINK:
      return {
        ...state,
        deeplink: action.payload
      };

    default:
      return state;
  }
}
