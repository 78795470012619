import {
  FETCH_PUSH_TOKEN,
  FETCH_LOGIN,
  FETCH_SIGNUP,
  SET_TOKEN_RESULT,
  LOGIN_FAILURE,
  SIGNUP_RESULT,
  FETCH_FORGET_PASSWORD,
  FORGET_PASSWORD_RESULT,
  FORGET_PASSWORD_FAILURE,
  FETCH_DELETE_USER,
  DELETE_USER_RESULT,
  FETCH_LOGIN_WITH_FB,
  FETCH_LOGIN_AS_GUEST,
  FETCH_SIGNUP_WITH_FB,
  DELETE_USER_FAILURE,
  FETCH_LOGIN_USING_TOKEN,
  FETCH_LOGIN_WITH_APPLE,
  FETCH_SIGNUP_WITH_APPLE,
  SET_STRATEGY,
  FETCH_SIGNUP_AS_GUEST
} from "./types";
import { apiAction } from "./api";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchCheckVersionAuthenticated } from ".";
import { APP_NAME, APP_BUNDLE_ID } from "../config/constants";
import { isElectron } from "../styles/commonStyles";

export function fetchLogin(email, password, communityId) {
  return apiAction({
    url: "/api/auth",
    data: { email, password, communityId },
    method: "POST",
    onSuccess: data => [
      fetchCheckVersionAuthenticated(),
      setStrategy("local"),
      setEmail(email)
    ],
    onFailure: loginFailure,
    label: FETCH_LOGIN
  });
}

function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    payload: error
  };
}

export function fetchLoginAsGuest(data) {
  return apiAction({
    url: "/api/auth/guest",
    data,
    method: "POST",
    onSuccess: result => [
      fetchCheckVersionAuthenticated(),
      setStrategy("guest")
    ],
    onFailure: loginFailure,
    label: FETCH_LOGIN_AS_GUEST
  });
}

export function fetchLoginWithApple(communityId, data) {
  return apiAction({
    url: "/api/auth/apple",
    data: { communityId, bundleId: APP_BUNDLE_ID, ...data },
    method: "POST",
    onSuccess: data => [fetchCheckVersionAuthenticated(), setStrategy("apple")],
    onFailure: loginFailure,
    label: FETCH_LOGIN_WITH_APPLE
  });
}

export function fetchLoginWithFB(communityId, token) {
  return apiAction({
    url: "/api/auth/fb",
    data: { token, communityId },
    method: "POST",
    onSuccess: data => [
      fetchCheckVersionAuthenticated(),
      setStrategy("facebook")
    ],
    onFailure: loginFailure,
    label: FETCH_LOGIN_WITH_FB
  });
}

function setStrategy(strategy) {
  AsyncStorage.setItem("strategy", strategy);
  AsyncStorage.setItem("hideTooltips", "true");
  return {
    type: SET_STRATEGY,
    payload: strategy
  };
}

function setEmail(email) {
  if (email) {
    AsyncStorage.setItem("emailOrPseudo", email);
  }
}

export function fetchLoginUsingToken(token) {
  return apiAction({
    url: "/api/auth/jwt",
    data: { token },
    method: "POST",
    onSuccess: data => [fetchCheckVersionAuthenticated(), setStrategy("jwt")],
    onFailure: loginFailure,
    label: FETCH_LOGIN_USING_TOKEN
  });
}

export function fetchDeleteUser() {
  return apiAction({
    url: "/api/user",
    method: "DELETE",
    onSuccess: deleteUserResult,
    onFailure: deleteUserFailure,
    label: FETCH_DELETE_USER
  });
}

function deleteUserResult(data) {
  return {
    type: DELETE_USER_RESULT,
    payload: data
  };
}

function deleteUserFailure(error) {
  return {
    type: DELETE_USER_FAILURE,
    payload: error
  };
}

export function fetchForgetPassword(communityId, email, lang) {
  return apiAction({
    url: "/api/clear",
    method: "POST",
    data: {
      communityId,
      email,
      lang,
      appname: APP_NAME,
      os: isElectron ? "electron" : Platform.OS
    },
    onSuccess: forgetPasswordResult,
    onFailure: forgetPasswordFailure,
    label: FETCH_FORGET_PASSWORD
  });
}

function forgetPasswordResult(data) {
  return {
    type: FORGET_PASSWORD_RESULT,
    payload: data
  };
}

function forgetPasswordFailure(error) {
  return {
    type: FORGET_PASSWORD_FAILURE,
    payload: error
  };
}

export function fetchSignUp(email, registerkey, communityId, lang) {
  return apiAction({
    url: "/api/users",
    data: {
      email,
      registerkey,
      communityId,
      lang,
      os: isElectron ? "electron" : Platform.OS
    },
    method: "POST",
    onSuccess: data => [setSignupResult(data), setEmail(email)],
    onFailure: loginFailure,
    label: FETCH_SIGNUP
  });
}

export function fetchSignupAsGuest(data) {
  return apiAction({
    url: "/api/auth/signup/guest",
    data,
    method: "POST",
    onSuccess: result => [
      fetchCheckVersionAuthenticated(),
      setStrategy("guest")
    ],
    onFailure: loginFailure,
    label: FETCH_SIGNUP_AS_GUEST
  });
}

export function fetchSignUpWithFB(
  communityId,
  token,
  registerkey,
  email,
  lang
) {
  return apiAction({
    url: "/api/auth/signup/fb",
    data: { communityId, token, registerkey, email, lang },
    method: "POST",
    onSuccess: data => [
      fetchCheckVersionAuthenticated(),
      setStrategy("facebook"),
      setEmail(email)
    ],
    onFailure: loginFailure,
    label: FETCH_SIGNUP_WITH_FB
  });
}

export function fetchSignUpWithApple(communityId, registerkey, lang, data) {
  return apiAction({
    url: "/api/auth/signup/apple",
    data: { communityId, registerkey, lang, bundleId: APP_BUNDLE_ID, ...data },
    method: "POST",
    onSuccess: data => [fetchCheckVersionAuthenticated(), setStrategy("apple")],
    onFailure: loginFailure,
    label: FETCH_SIGNUP_WITH_APPLE
  });
}

export function fetchPushToken(token, lang) {
  return apiAction({
    url: "/api/push-token",
    data: {
      token,
      os: isElectron ? "electron" : Platform.OS,
      lang
    },
    method: "POST",
    onSuccess: setTokenResult,
    onFailure: err => console.log(err),
    label: FETCH_PUSH_TOKEN
  });
}

function setSignupResult(data) {
  return {
    type: SIGNUP_RESULT,
    payload: data
  };
}

function setTokenResult(data) {
  return {
    type: SET_TOKEN_RESULT,
    payload: data
  };
}
