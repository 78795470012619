import React, { memo, useEffect } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  View
} from "react-native";
import { CheckBox } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDeleteNotifUserTemplate,
  fetchGetNotifStatus,
  fetchSetNotifAll,
  fetchSetNotifSelection,
  fetchSetNotifUserTemplates
} from "../actions/template";
import {
  STATUS_ALL,
  STATUS_SELECTION,
  STATUS_UNDEFINED
} from "../actions/types";
import LabelSelect from "../components/LabelSelect";
import { getNotifTemplates } from "../selectors";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, { COLOR2 } from "../styles/commonStyles";

const Settings = () => {
  const dispatch = useDispatch();

  const { isFetching, status, data } = useSelector(
    state => state.settingsReducer
  );
  const { data: tokenResult } = useSelector(state => state.tokenReducer);
  const { user } = useSelector(state => state.profileReducer);
  const templates = useSelector(getNotifTemplates);

  let selected = new Set(data);
  const gender = user?.gender;

  useEffect(() => {
    dispatch(fetchGetNotifStatus());
  }, []);

  useEffect(() => {
    dispatch(fetchGetNotifStatus());
  }, [tokenResult]);

  const selectConfirm = templatesIds => {
    selected = new Set([...selected, ...templatesIds]);

    dispatch(fetchSetNotifUserTemplates(Array.from(selected.values())));
  };

  const deleteItem = templateId => {
    dispatch(fetchDeleteNotifUserTemplate(templateId));
  };

  const renderLabelSelectLabels = () => {
    const components = [];

    for (const templateId of selected) {
      if (templateId) {
        components.push(
          <LabelSelect.Label
            key={templateId}
            data={templates[templateId]}
            onCancel={() => {
              deleteItem(templateId);
            }}
          >
            {getTranslatedProperty(templates[templateId], "name")}
          </LabelSelect.Label>
        );
      }
    }

    return components;
  };

  const setNotifAll = () => {
    dispatch(fetchSetNotifAll());
  };

  const setNotifSelection = () => {
    dispatch(fetchSetNotifSelection());
  };

  const {
    bgColor1,
    font,
    flex1,
    justifyContentCenter,
    darkgrey,
    p10,
    mb10
  } = commonStyles;

  return (
    <View style={[flex1, bgColor1]}>
      {isFetching ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <ScrollView style={[flex1, p10]}>
          <Text style={[font, darkgrey, mb10, { fontSize: 18, marginTop: 15 }]}>
            {gender === "M" ? t("settings:notif") : t("settings:notif_F")}
          </Text>
          <CheckBox
            title={t("settings:allposts")}
            textStyle={[darkgrey, font]}
            containerStyle={[flex1]}
            checkedColor={COLOR2}
            uncheckedColor={COLOR2}
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            checked={status === STATUS_ALL}
            onPress={setNotifAll}
          />
          <CheckBox
            title={t("settings:selectedposts")}
            textStyle={[darkgrey, font]}
            containerStyle={[flex1]}
            checkedColor={COLOR2}
            uncheckedColor={COLOR2}
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            checked={status === STATUS_SELECTION}
            onPress={setNotifSelection}
          />
          {status !== STATUS_UNDEFINED &&
            (status === STATUS_SELECTION || selected.size > 0) && (
              <LabelSelect
                title={t("settings:alerts")}
                style={styles.labelSelect}
                readOnly={status !== STATUS_SELECTION}
                hobbiesIds={selected}
                onConfirm={selectConfirm}
              >
                {renderLabelSelectLabels()}
                {Object.values(templates)
                  .filter(item => !selected.has(item._id))
                  .map(template => (
                    <LabelSelect.ModalItem
                      key={template._id}
                      data={template}
                      hobbiesIds={templates}
                    >
                      {getTranslatedProperty(template, "name")}
                    </LabelSelect.ModalItem>
                  ))}
              </LabelSelect>
            )}
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  labelSelect: {
    width: "100%",
    marginTop: 5,
    marginBottom: 20,
    padding: 5,
    borderWidth: 1,
    borderRadius: 6,
    borderStyle: "dashed",
    borderColor: COLOR2,
    backgroundColor: "#fafafa"
  }
});

const MemoizedSettings = memo(Settings);
export default MemoizedSettings;
