import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import * as ImagePicker from "expo-image-picker";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  Text,
  View
} from "react-native";
import { Avatar, CheckBox, Input } from "react-native-elements";
import { TextInputMask } from "react-native-masked-text";
import SafeAreaView from "react-native-safe-area-view";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchDeleteUser } from "../actions/login";
import { fetchUpdateProfile } from "../actions/profile";
import {
  CLEAR_PHOTO,
  CLEAR_PROFILE_VALIDATE,
  EDIT_PROFILE,
  LOGOUT,
  NOT_UPDATE_AUTOMATICALLY_HOBBIES,
  REGISTER_FOR_PUSH_NOTIF,
  RESET_DATA,
  SET_APARTMENT_NUMBER,
  SET_BIRTHDATE,
  SET_BUILDING_ACCESS,
  SET_DESCRIPTION,
  SET_DROP_DOWN_ALERT_INFO,
  SET_FIRST_NAME,
  SET_FLOOR,
  SET_GENDER,
  SET_JOB,
  SET_LAST_NAME,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_CONFIRMATION,
  SET_PHONE,
  SET_PHOTO_URI,
  SET_USER_HOBBIES,
  SET_VALIDATE_FALSE,
  TOGGLE_CONFIRM_ADDRESS,
  UPDATE_AUTOMATICALLY_HOBBIES,
  VALIDATE_PROFILE_INPUTS
} from "../actions/types";
import { getImageUri } from "../assets/Images";
import ActionButton from "../components/actionButton/ActionButton";
import ConfirmationModal from "../components/ConfirmationModal";
import Hobbies from "../components/Hobbies";
import { ROLE_NEIGHBOR } from "../config/constants";
import { getProfileUpdateSelector } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, {
  BLUE_COLOR,
  COLOR2,
  COLOR5,
  isiOS,
  isWeb,
  PINK_COLOR,
  SCREEN_HEIGHT
} from "../styles/commonStyles";
const IMAGE_SIZE = 200;

const ProfileUpdate = () => {
  const headerHeight = useHeaderHeight();
  const firstnameInputRef = useRef();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    files,
    pseudo,
    firstname,
    lastname,
    gender,
    birthdate,
    address,
    zipCode,
    city,
    phone,
    description,
    photoUri,
    hobbiesIds,
    firstnameErrorMsg,
    lastnameErrorMsg,
    birthdayErrorMsg,
    confirmAddressErrorMsg,
    confirmAddress,
    user,
    isValid,
    isFetching,
    firstLogin,
    clearProfileValidate,
    phoneErrorMsg,
    floor,
    apartmentNumber,
    job,
    building,
    buildingAccess,
    residence,
    district,
    needRegisterForPush,
    updateHobbies,
    strategy,
    newpassword,
    newpasswordconfirmation,
    passwordErrorMsg,
    passwordConfirmationErrorMsg,
    deleteUserResult
  } = useSelector(getProfileUpdateSelector, shallowEqual);

  const role = user?.role;

  useEffect(() => {
    dispatch({
      type: EDIT_PROFILE
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (deleteUserResult) {
      (async () => {
        await AsyncStorage.removeItem("emailOrPseudo");
        await AsyncStorage.removeItem("hideTooltips");
        await AsyncStorage.removeItem("strategy");
        dispatch({
          type: LOGOUT
        });
        dispatch({
          type: RESET_DATA
        });
      })();
    }
  }, [deleteUserResult]);

  useEffect(() => {
    if (files) {
      firstnameInputRef.currrent?.focus();
    }
  }, [files]);

  useEffect(() => {
    if (clearProfileValidate) {
      dispatch({
        type: VALIDATE_PROFILE_INPUTS
      });
    }
  }, [clearProfileValidate]);

  useEffect(() => {
    if (isValid) {
      dispatch(
        fetchUpdateProfile({
          photoUri: photoUri && !files.length ? photoUri : null,
          firstname,
          lastname,
          gender,
          address,
          zipCode,
          files,
          city,
          phone,
          description,
          birthdate,
          floor,
          apartmentNumber,
          job,
          updateHobbies,
          hobbiesIds: [...hobbiesIds],
          buildingAccess,
          newpassword
        })
      );
    }
  }, [isValid]);

  useEffect(() => {
    if (isValid && user) {
      navigation.navigate("TabScreen");
      if (needRegisterForPush) {
        dispatch({
          type: REGISTER_FOR_PUSH_NOTIF
        });
      }
      dispatch({
        type: SET_VALIDATE_FALSE
      });
    }
  }, [user]);

  const deleteUser = () => {
    dispatch(fetchDeleteUser());
  };

  const _pickImage = async () => {
    if (!isWeb) {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      // allowsEditing: true,
      aspect: [1, 1],
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      if (isWeb) {
        const files = [];
        const name = "" + Date.now();
        files.push({
          name,
          base64: result.uri
        });
        dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      } else {
        const files = [];
        const filename = result.uri.split("/").pop();
        const name = filename
          ? filename.substring(0, filename.lastIndexOf("."))
          : null;
        files.push({
          name,
          base64: "data:image/jpeg;base64," + result.base64
        });
        dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      }
    }
  };

  const cancel = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      dispatch({
        type: LOGOUT
      });
    }
  };

  const {
    red,
    bgColor1,
    darkgrey,
    bgWhite,
    color2,
    color5,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    fs12,
    fs16,
    fs18,
    fs20,
    row,
    p10,
    p20,
    ml10,
    mt10,
    mb10,
    mb20,
    mv10,
    mv20,
    font,
    fontBold,
    shadowGrey,
    w100p,
    ml5,
    mt5
  } = commonStyles;

  return (
    <KeyboardAvoidingView
      {...(isiOS && { behavior: "padding" })}
      style={[[w100p, { height: SCREEN_HEIGHT - headerHeight }, bgColor1]]}
    >
      <ConfirmationModal
        description={t("settings:confirmdelete")}
        onClosed={() => setShowConfirmationModal(false)}
        title={t("menu:confirmation")}
        showConfirmationModal={showConfirmationModal}
        onConfirmation={deleteUser}
      />
      {isFetching || isLoading ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <SafeAreaView style={[flex1]}>
          <ScrollView
            style={[flex1]}
            contentContainerStyle={{ paddingBottom: 90 }}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={[
                w100p,
                p10,
                bgWhite,
                mt10,
                mb20,
                shadowGrey,
                alignItemsCenter
              ]}
            >
              <Text style={[fontBold, color5, fs20, mv20]}>
                {t("profile:profile")}
              </Text>

              <View style={[row, mb20, justifyContentCenter, w100p]}>
                <Avatar
                  rounded
                  size={IMAGE_SIZE}
                  title={pseudo ? pseudo.toUpperCase().substr(0, 2) : "?"}
                  source={
                    Boolean(photoUri)
                      ? {
                          uri: photoUri
                        }
                      : gender === "M"
                      ? getImageUri("avatar")
                      : getImageUri("avatarF")
                  }
                  onPress={() => {
                    Keyboard.dismiss();
                    navigation.navigate("CameraScreen", {
                      from: "profile"
                    });
                  }}
                />
                <ActionButton
                  buttonColor={COLOR5}
                  renderIcon={() => (
                    <MaterialCommunityIcons
                      name="dots-horizontal"
                      size={34}
                      color="white"
                    />
                  )}
                  verticalOrientation="down"
                  size={44}
                  offsetX={10}
                  offsetY={0}
                  degrees={90}
                  spacing={10}
                >
                  {Boolean(photoUri) && (
                    <ActionButton.Item
                      buttonColor={"red"}
                      title={t("button:delete")}
                      onPress={() => dispatch({ type: CLEAR_PHOTO })}
                      textStyle={font}
                      textHeight={24}
                    >
                      <MaterialCommunityIcons
                        name={"delete-forever"}
                        size={34}
                        color="white"
                      />
                    </ActionButton.Item>
                  )}
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:albums")}
                    onPress={_pickImage}
                    textStyle={font}
                    textHeight={24}
                  >
                    <FontAwesome name="image" size={28} color="white" />
                  </ActionButton.Item>
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:camera")}
                    onPress={() => {
                      Keyboard.dismiss();
                      navigation.navigate("CameraScreen", {
                        from: "profile"
                      });
                    }}
                    textStyle={font}
                    textHeight={24}
                  >
                    <MaterialIcons name="camera-alt" size={34} color="white" />
                  </ActionButton.Item>
                </ActionButton>
              </View>
              <View style={[mv10, row, alignItemsCenter]}>
                <CheckBox
                  title={t("profile:male")}
                  textStyle={[{ color: BLUE_COLOR }, fontBold]}
                  checkedColor={BLUE_COLOR}
                  uncheckedColor={BLUE_COLOR}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={gender === "M"}
                  onPress={() => dispatch({ type: SET_GENDER, value: "M" })}
                />
                <CheckBox
                  title={t("profile:female")}
                  textStyle={[{ color: PINK_COLOR }, fontBold]}
                  checkedColor={PINK_COLOR}
                  uncheckedColor={PINK_COLOR}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={gender === "F"}
                  onPress={() => dispatch({ type: SET_GENDER, value: "F" })}
                />
              </View>
              <Input
                ref={firstnameInputRef}
                inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                onChangeText={value =>
                  dispatch({ type: SET_FIRST_NAME, value })
                }
                placeholder={t("profile:firstname")}
                keyboardType="default"
                autoCorrect={false}
                autoCapitalize="words"
                keyboardAppearance="light"
                returnKeyType="next"
                value={firstname}
                errorMessage={firstnameErrorMsg}
              />
              <Input
                inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                onChangeText={value => dispatch({ type: SET_LAST_NAME, value })}
                containerStyle={mb20}
                placeholder={t("profile:lastname")}
                autoCorrect={false}
                autoCapitalize="words"
                value={lastname}
                keyboardType="default"
                returnKeyType="next"
                errorMessage={lastnameErrorMsg}
              />
              {role === ROLE_NEIGHBOR && (
                <View style={[{ paddingHorizontal: 10 }, w100p]}>
                  <TextInputMask
                    type={isWeb ? "custom" : "datetime"}
                    options={
                      isWeb
                        ? {
                            mask: "99/99/9999"
                          }
                        : {
                            format: "DD/MM/YYYY"
                          }
                    }
                    placeholder={t("profile:birthdate")}
                    value={birthdate}
                    style={[
                      font,
                      {
                        paddingLeft: isiOS ? 10 : 0,
                        paddingBottom: 10,
                        fontSize: 18,
                        height: 40,
                        width: "100%",
                        borderColor: "gray",
                        borderBottomWidth: 1
                      },
                      !birthdayErrorMsg && mb20
                    ]}
                    onChangeText={value =>
                      dispatch({ type: SET_BIRTHDATE, value })
                    }
                  />
                  {birthdayErrorMsg && (
                    <Text style={[ml5, mt5, red, fs12, mb20]}>
                      {birthdayErrorMsg}
                    </Text>
                  )}
                </View>
              )}
            </View>
            {role === ROLE_NEIGHBOR && (
              <View
                style={[
                  w100p,
                  p10,
                  bgWhite,
                  mt10,
                  mb20,
                  shadowGrey,
                  alignItemsCenter
                ]}
              >
                <Text style={[fontBold, color2, fs20, mv20]}>
                  {t("profile:address")}
                </Text>
                <Text style={[font, darkgrey, fs18, mb10]}>{address}</Text>
                <Text
                  style={[font, darkgrey, fs18, mb10]}
                >{`${zipCode} ${city}`}</Text>
                {Boolean(building) && (
                  <Text
                    style={[
                      font,
                      darkgrey,
                      fs18,
                      Boolean(building || district) ? mb10 : mb20
                    ]}
                  >{`${t("infos:building")} ${building}`}</Text>
                )}
                {Boolean(residence) && (
                  <Text
                    style={[
                      font,
                      darkgrey,
                      fs18,
                      Boolean(district) ? mb10 : mb20
                    ]}
                  >{`${t("infos:residence")} ${residence}`}</Text>
                )}
                {Boolean(district) && (
                  <Text style={[font, darkgrey, fs18, mb20]}>{`${t(
                    "infos:district"
                  )} ${district}`}</Text>
                )}
                <CheckBox
                  title={t("profile:confirmaddress")}
                  textStyle={[darkgrey, fs18, fontBold]}
                  checkedColor={COLOR2}
                  containerStyle={!confirmAddressErrorMsg && mb20}
                  checked={confirmAddress}
                  onPress={() => dispatch({ type: TOGGLE_CONFIRM_ADDRESS })}
                />
                {confirmAddressErrorMsg && (
                  <Text style={[ml5, mt5, red, fs12, mb20]}>
                    {confirmAddressErrorMsg}
                  </Text>
                )}
              </View>
            )}

            {role === ROLE_NEIGHBOR && (
              <View
                style={[
                  w100p,
                  p10,
                  bgWhite,
                  mt10,
                  mb20,
                  shadowGrey,
                  alignItemsCenter
                ]}
              >
                <Text style={[fontBold, color2, fs20, mv20]}>
                  {t("profile:moreinfos")}
                </Text>
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb10}
                  onChangeText={value =>
                    dispatch({ type: SET_BUILDING_ACCESS, value })
                  }
                  placeholder={t("profile:buildingaccess")}
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={buildingAccess}
                />
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb10}
                  onChangeText={value =>
                    dispatch({ type: SET_APARTMENT_NUMBER, value })
                  }
                  placeholder={t("profile:apartmentnumber")}
                  keyboardType="decimal-pad"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={apartmentNumber}
                />
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb20}
                  onChangeText={value => dispatch({ type: SET_FLOOR, value })}
                  placeholder={t("profile:floor")}
                  keyboardType="decimal-pad"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={floor}
                />
                <View style={[{ paddingHorizontal: 10 }, w100p, mb10]}>
                  <TextInputMask
                    type={"custom"}
                    options={{
                      mask: "+33 (0)9 99 99 99 99"
                    }}
                    keyboardType="phone-pad"
                    placeholder={t("profile:phone")}
                    value={phone}
                    style={[
                      font,
                      darkgrey,
                      {
                        paddingLeft: isiOS ? 10 : 0,
                        paddingBottom: 10,
                        fontSize: 18,
                        height: 40,
                        width: "100%",
                        borderColor: "gray",
                        borderBottomWidth: 1
                      },
                      !birthdayErrorMsg && mb20
                    ]}
                    onChangeText={value => dispatch({ type: SET_PHONE, value })}
                  />
                  {phoneErrorMsg && (
                    <Text style={[ml5, mt5, red, fs12]}>{phoneErrorMsg}</Text>
                  )}
                </View>
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb10}
                  onChangeText={value => dispatch({ type: SET_JOB, value })}
                  placeholder={t("profile:job")}
                  keyboardType="default"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={job}
                />
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb20}
                  onChangeText={value =>
                    dispatch({ type: SET_DESCRIPTION, value })
                  }
                  placeholder={t("profile:description")}
                  keyboardType="default"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={description}
                />
              </View>
            )}
            {role === ROLE_NEIGHBOR && (
              <View
                style={[
                  w100p,
                  p20,
                  bgWhite,
                  mt10,
                  mb20,
                  shadowGrey,
                  alignItemsCenter
                ]}
              >
                <Text style={[fontBold, color5, fs20, mv20]}>
                  {t("profile:hobbies")}
                </Text>
                <Hobbies
                  hobbiesIds={new Set(hobbiesIds)}
                  onHobbiesChange={value =>
                    dispatch({ type: SET_USER_HOBBIES, value })
                  }
                />
                <CheckBox
                  title={t("profile:updatehobbies")}
                  textStyle={[darkgrey, fontBold]}
                  containerStyle={[w100p]}
                  checkedColor={COLOR2}
                  uncheckedColor={COLOR2}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={updateHobbies}
                  onPress={() =>
                    dispatch({ type: UPDATE_AUTOMATICALLY_HOBBIES })
                  }
                />
                <CheckBox
                  title={t("profile:noupdatehobbies")}
                  textStyle={[darkgrey, fontBold]}
                  containerStyle={[w100p]}
                  checkedColor={COLOR2}
                  uncheckedColor={COLOR2}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={!updateHobbies}
                  onPress={() =>
                    dispatch({ type: NOT_UPDATE_AUTOMATICALLY_HOBBIES })
                  }
                />
              </View>
            )}
            {strategy === "jwt" && (
              <View
                style={[
                  w100p,
                  p20,
                  bgWhite,
                  mt10,
                  mb20,
                  shadowGrey,
                  alignItemsCenter
                ]}
              >
                <Text style={[fontBold, color2, fs20, mv20]}>
                  {t("profile:password")}
                </Text>
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb10}
                  onChangeText={value =>
                    dispatch({ type: SET_NEW_PASSWORD, value })
                  }
                  placeholder={t("profile:newpassword")}
                  keyboardType="default"
                  keyboardAppearance="light"
                  autoCapitalize="none"
                  autoCorrect={false}
                  secureTextEntry={true}
                  returnKeyType="next"
                  value={newpassword}
                  errorMessage={passwordErrorMsg}
                />
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb10}
                  onChangeText={value =>
                    dispatch({ type: SET_NEW_PASSWORD_CONFIRMATION, value })
                  }
                  placeholder={t("profile:newpasswordconfirmation")}
                  keyboardType="default"
                  keyboardAppearance="light"
                  autoCapitalize="none"
                  autoCorrect={false}
                  secureTextEntry={true}
                  returnKeyType="done"
                  value={newpasswordconfirmation}
                  errorMessage={passwordConfirmationErrorMsg}
                />
              </View>
            )}
          </ScrollView>
          <ActionButton
            buttonColor={menuOpen ? COLOR5 : COLOR2}
            degrees={90}
            renderIcon={() => (
              <MaterialCommunityIcons
                name="dots-horizontal"
                size={40}
                color="white"
              />
            )}
            onPressIn={() => setMenuOpen(true)}
            onReset={() => setMenuOpen(false)}
            disabled={isFetching}
          >
            <ActionButton.Item
              buttonColor={"red"}
              title={t("button:deleteaccount")}
              onPress={() => setShowConfirmationModal(true)}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons
                name="account-remove"
                size={36}
                color="white"
              />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor={"red"}
              title={t("button:cancel")}
              onPress={() => cancel()}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons name="cancel" size={36} color="white" />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor={COLOR2}
              title={firstLogin ? t("button:signup") : t("button:update")}
              onPress={() =>
                dispatch({
                  type: CLEAR_PROFILE_VALIDATE
                })
              }
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons
                name="account-check"
                size={36}
                color="white"
              />
            </ActionButton.Item>
          </ActionButton>
        </SafeAreaView>
      )}
    </KeyboardAvoidingView>
  );
};

const MemoizedProfileUpdate = memo(ProfileUpdate);
export default MemoizedProfileUpdate;
