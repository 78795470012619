import { useRoute } from "@react-navigation/core";
import React from "react";
import { View } from "react-native";
import WebView from "react-native-web-webview";
import commonStyles from "../styles/commonStyles";

const DocReader = props => {
  const route = useRoute();
  const uri = props?.uri || route.params?.uri;

  const { bgColor1, flex1 } = commonStyles;

  return (
    <View style={[flex1, bgColor1]}>
      <WebView bounces={false} scrollEnabled={false} source={{ uri }} />
    </View>
  );
};

export default DocReader;
