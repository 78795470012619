import {
  API_START,
  API_END,
  FETCH_UPDATE_AD_MAP_PHOTO_URI,
  SET_MAP_PHOTO_URI,
  SET_UPDATE_AD_MAP_PHOTO_URI_RESULT,
  SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT
} from "../actions/types";

const initialState = {
  isFetching: false,
  files: [],
  mapPhotoUri: null
};

export default function captureReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_UPDATE_AD_MAP_PHOTO_URI) {
        return {
          ...state,
          isFetching: true
        };
      }
      break;

    case SET_MAP_PHOTO_URI:
      return { ...state, mapPhotoUri: action.uri, files: action.files };

    case SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT:
    case SET_UPDATE_AD_MAP_PHOTO_URI_RESULT:
      return { ...state, mapPhotoUri: null, files: [] };

    case API_END:
      if (action.payload === FETCH_UPDATE_AD_MAP_PHOTO_URI) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }

  return state;
}
