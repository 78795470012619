import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { Fragment, memo, useEffect, useRef } from "react";
import { LayoutAnimation, StyleSheet, Text, View } from "react-native";
import { CheckBox } from "react-native-elements/dist/checkbox/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetAds } from "../actions/ad";
import {
  CATEGORIES_FILTER,
  SET_GOODDEAL_DISPLAY_FILTER_OPTION
} from "../actions/types";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, { COLOR2 } from "../styles/commonStyles";
import LabelSelect from "./LabelSelect";

const AdsFilters = () => {
  const dispatch = useDispatch();
  const refModal = useRef();
  let { filter, displayFilterOptions } = useSelector(state => state.adReducer);
  filter = new Set(filter);
  const { adsCategories: categories } = useSelector(
    state => state.entitiesReducer
  );

  const {
    fontBold,
    color5,
    darkgrey,
    bgWhite,
    mb10,
    ml10,
    ph10,
    pv10,
    w100p,
    shadowGrey
  } = commonStyles;

  const getAds = (page, filter) => {
    dispatch({
      type: CATEGORIES_FILTER,
      payload: filter
    });
    dispatch(fetchGetAds(page, filter));
  };

  const hideFilter = () => {
    LayoutAnimation.easeInEaseOut();
    dispatch({
      type: SET_GOODDEAL_DISPLAY_FILTER_OPTION,
      payload: false
    });
  };

  const selectConfirm = categoriesIds => {
    if (categoriesIds.size) {
      filter = [...filter, ...categoriesIds];
      AsyncStorage.setItem("gooddealfilter", JSON.stringify(filter));
      getAds(1, filter);

      setTimeout(hideFilter, 1000);
    }
  };

  const deleteItem = async categoryId => {
    filter.delete(categoryId);

    if (filter.size) {
      await AsyncStorage.setItem(
        "gooddealfilter",
        JSON.stringify(Array.from(filter))
      );
    } else {
      await AsyncStorage.removeItem("gooddealfilter");
    }
    getAds(1, filter.size ? Array.from(filter) : []);
  };

  const renderLabelSelectLabels = () => {
    const components = [];

    for (const categoryId of filter) {
      components.push(
        <LabelSelect.Label
          key={categoryId}
          data={categories[categoryId]}
          onCancel={() => {
            deleteItem(categoryId);
          }}
        >
          {getTranslatedProperty(categories[categoryId], "name")}
        </LabelSelect.Label>
      );
    }

    return components;
  };

  const clearFilter = () => {
    AsyncStorage.removeItem("gooddealfilter");
    getAds(1, []);
    setTimeout(hideFilter, 1000);
  };

  useEffect(() => {
    (async () => {
      const filter = await AsyncStorage.getItem("gooddealfilter");
      getAds(1, filter ? JSON.parse(filter) : []);
      if (filter) {
        dispatch({
          type: SET_GOODDEAL_DISPLAY_FILTER_OPTION,
          payload: true
        });
        setTimeout(hideFilter, 1000);
      }
    })();
  }, []);

  return displayFilterOptions ? (
    <View style={[bgWhite, w100p, pv10, shadowGrey, ph10]}>
      <Text style={[ml10, mb10, fontBold, darkgrey]}>
        {t("filter:display")}
      </Text>
      <CheckBox
        title={t("filter:all")}
        textStyle={[color5, fontBold]}
        checkedIcon="dot-circle-o"
        uncheckedIcon="circle-o"
        checkedColor={COLOR2}
        checked={filter.size === 0}
        onPress={clearFilter}
      />
      <CheckBox
        title={t("filter:selection")}
        textStyle={[color5, fontBold]}
        checkedIcon="dot-circle-o"
        uncheckedIcon="circle-o"
        checkedColor={COLOR2}
        checked={filter.size > 0}
        onPress={() => refModal.current?.openModal()}
      />
      <LabelSelect
        title={t("filter:display")}
        ref={refModal}
        style={styles.labelSelect}
        hobbiesIds={filter}
        onConfirm={selectConfirm}
      >
        {renderLabelSelectLabels()}
        {Object.values(categories)
          .filter(item => !filter.has(item._id))
          .map(category => (
            <LabelSelect.ModalItem
              key={category._id}
              data={category}
              hobbiesIds={categories}
            >
              {getTranslatedProperty(category, "name")}
            </LabelSelect.ModalItem>
          ))}
      </LabelSelect>
    </View>
  ) : (
    <Fragment />
  );
};

const MemoizedAdsFilters = memo(AdsFilters);
export default MemoizedAdsFilters;

const styles = StyleSheet.create({
  labelSelect: {
    width: "100%",
    marginTop: 5,
    marginBottom: 20,
    padding: 5,
    borderWidth: 1,
    borderRadius: 6,
    borderStyle: "dashed",
    borderColor: COLOR2,
    backgroundColor: "#fafafa"
  }
});
