import { MaterialCommunityIcons } from "@expo/vector-icons";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { LayoutAnimation, TouchableOpacity, View } from "react-native";
import {
  CLEAR_PUBLISH,
  LOGOUT,
  RESET_POST,
  TOGGLE_DISPLAY_PREVIEW
} from "../actions/types";
import LoginModal from "../components/LoginModal";
import { BUSINESS_NAME } from "../config/constants";
import AdDetails from "../containers/AdDetails";
import AppInfos from "../containers/AppInfos";
import CameraScreen from "../containers/CameraScreen";
import CGUReader from "../containers/CGUReader";
import Chat from "../containers/Chat";
import CitiesModal from "../containers/CitiesModal";
import CommunitiesMapModal from "../containers/CommunitiesMapModal";
import CommunitiesModal from "../containers/CommunitiesModal";
import CommunityDetails from "../containers/CommunityDetails";
import PdfWelcomeScreen from "../containers/PdfWelcomeScreen";
import EventDetails from "../containers/EventDetails";
import EventInterestedUsers from "../containers/EventInterestedUsers";
import ImageZoom from "../containers/ImgZoom";
import LandingScreen from "../containers/LandingScreen";
import LoadingScreen from "../containers/LoadingScreen";
import MaintenanceScreen from "../containers/Maintenance";
import Post from "../containers/Post";
import Profile from "../containers/Profile";
import ProfileUpdate from "../containers/ProfileUpdate";
import SelectTemplate from "../containers/SelectTemplate";
import SelectTemplateDate from "../containers/SelectTemplateDate";
import Youtube from "../containers/Youtube";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR5,
  WHITE_COLOR
} from "../styles/commonStyles";
import BottomTabNavigator from "./BottomTabsNavigator";
import {
  DEFAULT_HEADER_STYLE,
  getCommunityUri,
  getHeaderLeftBack
} from "./CommonNavigation";

const Stack = createStackNavigator();

function getTemplateHeaderStyle(displayPreview) {
  if (displayPreview) {
    return {
      backgroundColor: COLOR5
    };
  }
  return DEFAULT_HEADER_STYLE;
}

function getTemplateHeaderRight(dispatch, displayPreview) {
  const { flex1, bgWhite, bgColor2, justifyContentCenter, alignItemsCenter } =
    commonStyles;

  return () => (
    <TouchableOpacity
      style={flex1}
      onPress={() => {
        LayoutAnimation.easeInEaseOut();
        dispatch({
          type: TOGGLE_DISPLAY_PREVIEW
        });
      }}
    >
      <View
        style={[
          displayPreview ? bgWhite : bgColor2,
          alignItemsCenter,
          justifyContentCenter,
          {
            height: "100%",
            width: 56,
            paddingBottom: 3
          }
        ]}
      >
        <MaterialCommunityIcons
          name="eye-check"
          color={displayPreview ? COLOR2 : WHITE_COLOR}
          size={28}
        />
      </View>
    </TouchableOpacity>
  );
}

const config = {
  animation: "timing",
  config: {
    duration: 500
  }
};

export const createRootAppStackNavigator = ({
  dispatch,
  user,
  accessDenied,
  navigation,
  displayPreview,
  communityDetails
}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        title: BUSINESS_NAME,
        headerTitleAlign: "left",
        headerStyle: DEFAULT_HEADER_STYLE,
        headerTintColor: WHITE_COLOR,
        headerTitleStyle: {
          fontFamily: "fontBold",
          textTransform: "uppercase"
        }
      }}
    >
      {Boolean(!user) ? (
        !accessDenied ? (
          <>
            <Stack.Group>
              <Stack.Screen
                name="LoadingScreen"
                component={LoadingScreen}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="MaintenanceScreen"
                component={MaintenanceScreen}
                options={{ headerShown: false }}
              />
            </Stack.Group>
          </>
        ) : (
          <>
            <Stack.Group>
              <Stack.Screen
                name="LandingScreen"
                component={LandingScreen}
                initialParams={{
                  communityUri: getCommunityUri(communityDetails)
                }}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AppInfosScreen"
                component={AppInfos}
                options={{
                  title: t("menu:about"),
                  headerLeft: getHeaderLeftBack(navigation)
                }}
              />
              <Stack.Screen
                name="CGUScreen"
                component={CGUReader}
                options={{
                  title: t("menu:cgu"),
                  headerLeft: getHeaderLeftBack(navigation)
                }}
              />
              <Stack.Screen
                name="AdDetailsScreen"
                component={AdDetails}
                options={() => {
                  return {
                    title: t("menu:localLife"),
                    headerLeft: getHeaderLeftBack(navigation)
                  };
                }}
              />
              <Stack.Screen
                name="AdScreen"
                component={AdDetails}
                options={() => {
                  return {
                    title: t("menu:localLife"),
                    headerLeft: getHeaderLeftBack(navigation)
                  };
                }}
              />
              <Stack.Screen
                name="SelectTemplateDateScreen"
                component={SelectTemplateDate}
                options={({ route }) => {
                  return {
                    headerTitleStyle: {
                      textTransform: "none"
                    },
                    headerLeft: getHeaderLeftBack(navigation, {
                      callback: () => dispatch({ type: CLEAR_PUBLISH })
                    }),
                    headerRight: getTemplateHeaderRight(
                      dispatch,
                      displayPreview
                    ),
                    headerTitleStyle: {
                      textTransform: "none"
                    },
                    headerStyle: getTemplateHeaderStyle(displayPreview)
                  };
                }}
              />
              <Stack.Screen
                name="EventDetailsScreen"
                component={EventDetails}
                options={{
                  title: t("menu:post"),
                  headerLeft: getHeaderLeftBack(navigation)
                }}
              />
              <Stack.Screen
                name="ImageZoomScreen"
                component={ImageZoom}
                options={{
                  title: t("menu:zoom"),
                  headerLeft: getHeaderLeftBack(navigation)
                }}
              />
              <Stack.Screen
                name="MaintenanceScreen"
                component={MaintenanceScreen}
                options={{ headerShown: false }}
              />
            </Stack.Group>
            <Stack.Group
              screenOptions={{
                presentation: "modal",
                headerShown: false,
                animationEnabled: true,
                cardStyle: {
                  backgroundColor: "gray"
                  // opacity: 0.9
                },
                transitionConfig: () => ({
                  containerStyle: {
                    backgroundColor: "transparent"
                  }
                }),
                transitionSpec: {
                  open: config,
                  close: config
                }
              }}
            >
              <Stack.Screen
                name="CommunitiesModal"
                component={CommunitiesModal}
              />
              <Stack.Screen
                name="CommunitiesMapModal"
                component={CommunitiesMapModal}
              />
              <Stack.Screen name="CitiesModal" component={CitiesModal} />
              <Stack.Screen
                name="LoginModal"
                component={LoginModal}
                initialParams={{
                  communityUri: getCommunityUri(communityDetails)
                }}
              />
            </Stack.Group>
          </>
        )
      ) : user.needUpdate ? (
        <>
          <Stack.Screen
            name="ProfileUpdateScreen"
            component={ProfileUpdate}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={{
              title: t("menu:profile"),
              headerLeft: getHeaderLeftBack(navigation, {
                callback: () =>
                  dispatch({
                    type: LOGOUT
                  })
              })
            }}
          />
          <Stack.Screen
            name="CameraScreen"
            component={CameraScreen}
            options={{ headerShown: false }}
          />
        </>
      ) : (
        <>
          <Stack.Screen name="TabScreen" component={BottomTabNavigator} />
          <Stack.Screen
            name="ProfileUpdateScreen"
            component={ProfileUpdate}
            options={{
              title: t("menu:profile"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="CameraScreen"
            component={CameraScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="SelectActivityScreen"
            component={SelectTemplate.SelectActivity}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={{
              title: t("menu:instantsharing"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="SelectProposalScreen"
            component={SelectTemplate.SelectProposal}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={{
              title: t("menu:needservice"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="SelectTemplateScreen"
            component={SelectTemplate.SelectTemplate}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={{
              title: t("menu:templates"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="SelectTemplateDateScreen"
            component={SelectTemplateDate}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={({ route }) => {
              return {
                headerTitleStyle: {
                  textTransform: "none"
                },
                headerLeft: getHeaderLeftBack(navigation, {
                  callback: () => dispatch({ type: CLEAR_PUBLISH })
                }),
                headerRight: getTemplateHeaderRight(dispatch, displayPreview),
                headerStyle: getTemplateHeaderStyle(displayPreview)
              };
            }}
          />
          <Stack.Screen
            name="PostScreen"
            component={Post}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={{
              title: t("menu:snapshot"),
              headerLeft: getHeaderLeftBack(navigation, {
                callback: () => dispatch({ type: RESET_POST })
              })
            }}
          />
          <Stack.Screen
            name="EventDetailsScreen"
            component={EventDetails}
            options={{
              title: t("menu:post"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="EventInterestedUsersScreen"
            component={EventInterestedUsers}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={{
              title: t("menu:post"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="ProfileScreen"
            component={Profile}
            options={{
              title: t("menu:profile"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="ProfileTeamScreen"
            component={Profile}
            options={{
              title: t("menu:profile"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="AdScreen"
            component={AdDetails}
            options={() => {
              return {
                title: t("menu:localLife"),
                headerLeft: getHeaderLeftBack(navigation)
              };
            }}
          />
          <Stack.Screen
            name="CommunityDetailsScreen"
            component={CommunityDetails}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={{
              title: t("menu:neighborhood"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="ChatScreen"
            component={Chat}
            options={() => {
              return {
                headerTitleStyle: {
                  textTransform: "none"
                },
                headerLeft: getHeaderLeftBack(navigation, {
                  // origin: "ChatTab"
                })
              };
            }}
          />
          <Stack.Screen
            name="ChatTeamScreen"
            component={Chat}
            options={() => {
              return {
                headerTitleStyle: {
                  textTransform: "none"
                },
                headerLeft: getHeaderLeftBack(navigation, {
                  // origin: "ChatTab"
                })
              };
            }}
          />
          <Stack.Screen
            name="ChatGrpScreen"
            component={Chat}
            initialParams={{
              communityUri: getCommunityUri(communityDetails)
            }}
            options={() => {
              return {
                headerLeft: getHeaderLeftBack(navigation),
                headerTitleStyle: {
                  textTransform: "none"
                }
              };
            }}
          />
          <Stack.Screen
            name="ImageZoomScreen"
            component={ImageZoom}
            options={{
              title: t("menu:zoom"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="YoutubeScreen"
            component={Youtube}
            options={{
              title: t("menu:youtube"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="AppInfosScreen"
            component={AppInfos}
            options={{
              title: t("menu:about"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="CGUScreen"
            component={CGUReader}
            options={{
              title: t("menu:CGU"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="PdfWelcomeScreen"
            initialParams={{ communityUri: communityDetails?.customUri }}
            component={PdfWelcomeScreen}
            options={{
              title: t("menu:welcomepdf"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="MaintenanceScreen"
            component={MaintenanceScreen}
            options={{ headerShown: false }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};
