import merge from "lodash/merge";

import {
  SET_LIVE_EVENTS,
  RESET_DATA,
  SET_ADS_RESULT,
  SET_TEMPLATES,
  SET_EVENT_INTERESTED_USERS_RESULT,
  SET_GET_EVENT_DETAILS_RESULT,
  SET_TEMPLATE_DATES,
  SET_TEMPLATE_TIMES,
  SET_COMMUNITY_DETAILS,
  SET_CITIES,
  SET_CHAT_EVENTS_RESULT,
  SET_CHAT_MESSAGES,
  SET_SEARCH_COMMUNITIES_RESULT,
  SET_NEAR_COMMUNITIES
} from "../actions/types";

const initialState = {
  locations: {},
  templates: {},
  categories: {},
  adsCategories: {},
  hobbies: {}
};

export default function entities(state = initialState, action) {
  switch (action.type) {
    case SET_CITIES:
    case SET_LIVE_EVENTS:
    case SET_ADS_RESULT:
    case SET_EVENT_INTERESTED_USERS_RESULT:
    case SET_GET_EVENT_DETAILS_RESULT:
    case SET_TEMPLATE_DATES:
    case SET_TEMPLATE_TIMES:
    case SET_COMMUNITY_DETAILS:
    case SET_CHAT_EVENTS_RESULT:
    case SET_CHAT_MESSAGES:
    case SET_NEAR_COMMUNITIES:
    case SET_SEARCH_COMMUNITIES_RESULT:
      break;

    case RESET_DATA:
      return {
        ...initialState
      };

    case SET_TEMPLATES:
      return {
        ...state,
        categories: { ...action.entities.categories },
        templates: { ...action.entities.templates }
      };

    default: {
      if (action.entities) {
        return merge({}, state, action.entities);
      }
      break;
    }
  }
  return state;
}
