import { useHeaderHeight } from "@react-navigation/elements";
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import React from "react";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import pjson from "../../package.json";
import { getImage } from "../assets/Images";
import {
  BUSINESS_NAME,
  CONTACT_MAIL,
  SLOGAN,
  WEB_URL
} from "../config/constants.js";
import { t } from "../services/i18n";
import commonStyles, { isNative, SCREEN_HEIGHT } from "../styles/commonStyles";
import { openBrowser, openURL } from "../utils/UrlUtil";

export default function AppInfos() {
  const {
    color2,
    darkgrey,
    bgWhite,
    font,
    bgColor1,
    alignItemsCenter,
    row,
    mt5,
    mt10,
    mt20,
    p20,
    mb10,
    fs14,
    shadowGrey,
    textCenter,
    underline,
    w100p
  } = commonStyles;
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const headerHeight = useHeaderHeight();
  const serverVersion = useSelector(
    state => state.globalInformationsReducer.serverVersion
  );

  return (
    <View style={[w100p, { height: SCREEN_HEIGHT - headerHeight }, bgColor1]}>
      <ScrollView style={[bgColor1]} showsVerticalScrollIndicator={false}>
        <View style={[p20, bgWhite, mt5, shadowGrey, mb10]}>
          <View style={[alignItemsCenter]}>
            <Text style={[font, color2, { fontSize: 28 }]}>
              {BUSINESS_NAME}
            </Text>
            {Boolean(SLOGAN) && (
              <Text style={[font, color2, { fontSize: 17 }]}>{SLOGAN}</Text>
            )}
            <Text
              style={[mt10, font, darkgrey, underline, fs14]}
              onPress={() => navigation.navigate("CGUScreen")}
            >
              {t("login:cgu")}
            </Text>
          </View>
          <View style={[row, mt20, { justifyContent: "space-between" }]}>
            <View>
              <Text style={[font, mt10, darkgrey, { fontSize: 12 }]}>
                {t("infos:clientversion")}{" "}
                <Text style={[font, mt10, color2, { fontSize: 15 }]}>
                  {isNative ? Constants.manifest.version : pjson.version}
                </Text>
              </Text>

              <Text style={[font, mt10, darkgrey, { fontSize: 12 }]}>
                {t("infos:serverversion")}{" "}
                <Text style={[font, mt10, color2, { fontSize: 15 }]}>
                  {serverVersion}
                </Text>
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => openBrowser(dispatch, `https://${WEB_URL}`)}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: 100
                }}
              >
                <Image
                  style={{ width: 100, height: 100 }}
                  source={getImage("logo")}
                />
              </View>
            </TouchableOpacity>
          </View>
          <Text style={[font, darkgrey, { fontSize: 12, lineHeight: 20 }]}>
            {t("infos:author")}
            <Text style={[font, mt20, color2, { fontSize: 12 }]}>
              {t("infos:salah")}
            </Text>
            {t("infos:author2")}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://www.digihapi.com")}
            >
              {"DigiHapi"}
            </Text>
            {t("infos:author3")}
          </Text>
          <Text style={[font, mt20, darkgrey, { fontSize: 12 }]}>
            {t("infos:contact")}{" "}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openURL(dispatch, `mailto:${CONTACT_MAIL}`)}
            >
              {CONTACT_MAIL}
            </Text>
          </Text>
          <Text style={[font, mt20, darkgrey, { fontSize: 12 }]}>
            {t("infos:website")}{" "}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, `https://${WEB_URL}`)}
            >
              {WEB_URL}
            </Text>
          </Text>
        </View>
        <View style={[p20, bgWhite, shadowGrey, mb10]}>
          <Text style={[font, textCenter, { fontSize: 17 }]}>
            {t("infos:thanks")}
          </Text>
          <Text
            style={[font, mt10, darkgrey, { fontSize: 12, lineHeight: 20 }]}
          >
            {t("infos:thanksopensource")}
          </Text>
          <Text
            style={[font, mt10, darkgrey, { fontSize: 12, lineHeight: 20 }]}
          >
            {t("infos:thanksexpo")}
            <Text style={[font, color2, { fontSize: 12 }]}>{"Expo"}</Text>
            {t("infos:from")}
            <Text
              style={[font, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://www.expo.io")}
            >
              www.expo.io
            </Text>
            {t("infos:thanksexpo2")}
          </Text>
        </View>
        <View style={[p20, bgWhite, shadowGrey, mb10]}>
          <Text style={[font, textCenter, { fontSize: 17 }]}>
            {t("infos:resources")}
          </Text>
          <Text
            style={[font, mt10, darkgrey, { fontSize: 12, lineHeight: 20 }]}
          >
            {t("infos:pictos")}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://www.freepik.com")}
            >
              Freepik
            </Text>
            {t("infos:from")}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://www.flaticon.com")}
            >
              www.flaticon.com
            </Text>
            .
          </Text>
          <Text
            style={[font, mt10, darkgrey, { fontSize: 12, lineHeight: 20 }]}
          >
            {t("infos:weather")}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() =>
                openBrowser(dispatch, "https://openweathermap.org")
              }
            >
              OpenWeatherMap
            </Text>
            .
          </Text>
        </View>
      </ScrollView>
    </View>
  );
}
