import { useNavigation } from "@react-navigation/core";
import React, { memo, useEffect, useRef, useState } from "react";
import { ActivityIndicator, FlatList, StyleSheet, View } from "react-native";
import { Button, ListItem, SearchBar } from "react-native-elements";
import { GiftedAvatar } from "react-native-gifted-chat";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchGetCities } from "../actions";
import { SET_REGION_COORDINATES } from "../actions/types";
import { getCommunityReducer } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, { COLOR2, isWeb } from "../styles/commonStyles";

const getSelector = createSelector(
  [getCommunityReducer],
  ({ cities, hasNextPage, nextPage }) => ({
    cities,
    hasNextPage,
    nextPage
  })
);

const CitiesModal = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  let { cities, hasNextPage, nextPage, isFetchingList } = useSelector(
    getSelector,
    shallowEqual
  );

  cities = Object.values(cities);
  const citiesLength = cities.length;

  const [search, setSearch] = useState("");
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum
  ] = useState(false);

  useEffect(() => {
    dispatch(
      fetchGetCities({
        page: 1
      })
    );
  }, []);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevCitiesLength = usePrevious(citiesLength);

  useEffect(() => {
    if (!prevCitiesLength || citiesLength > prevCitiesLength) {
      setOnEndReachedCalledDuringMomentum(false);
    }
  }, [prevCitiesLength, citiesLength]);

  const {
    font,
    textCenter,
    m20,
    flex1,
    w100p,
    bgWhite,
    red,
    mt10,
    overflowHidden,
    justifyContentCenter
  } = commonStyles;

  const renderItem = ({ item }) => (
    <ListItem
      bottomDivider
      onPress={() => {
        dispatch({
          type: SET_REGION_COORDINATES,
          payload: [item?.longitude, item?.latitude]
        });
        navigation.goBack();
      }}
    >
      <GiftedAvatar
        user={{
          name: item?.name,
          avatar: item.photoUri
        }}
      />
      <ListItem.Content>
        <ListItem.Title style={font}>{item?.name}</ListItem.Title>
        <ListItem.Subtitle>{item?.department}</ListItem.Subtitle>
      </ListItem.Content>
      <ListItem.Chevron />
    </ListItem>
  );

  const _displayLoading = () => {
    if (isFetchingList) {
      return (
        <View style={styles.loading_container}>
          <ActivityIndicator size="large" color={COLOR2} />
        </View>
      );
    }
  };

  return (
    <View style={[flex1, m20, justifyContentCenter]}>
      <View style={[flex1, { maxHeight: 600 }]}>
        <View style={[flex1, { borderRadius: 3 }, bgWhite, overflowHidden]}>
          <SearchBar
            inputStyle={isWeb && { paddingLeft: 10 }}
            placeholder={t("button:search")}
            onChangeText={search => {
              setSearch(search);
              dispatch(
                fetchGetCities({
                  page: 1,
                  search
                })
              );
            }}
            value={search}
          />
          <ListItem bottomDivider>
            <ListItem.Content>
              <ListItem.Title style={[font, textCenter]} right>
                {t("login:selectcity")}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
          {cities && (
            <FlatList
              showsVerticalScrollIndicator={false}
              style={[flex1, w100p]}
              keyExtractor={item => item._id}
              data={cities}
              renderItem={renderItem}
              onEndReachedThreshold={0.5}
              onEndReached={() => {
                if (hasNextPage && !onEndReachedCalledDuringMomentum) {
                  setOnEndReachedCalledDuringMomentum(true);
                  dispatch(fetchGetCities({ page: nextPage, search }));
                }
              }}
            />
          )}
        </View>
        {_displayLoading()}
        <Button
          buttonStyle={[w100p, bgWhite]}
          titleStyle={[font, red]}
          containerStyle={[mt10]}
          onPress={() => navigation.goBack()}
          title={t("button:cancel")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  loading_container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 100,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center"
  }
});

const MemoizedCitiesModal = memo(CitiesModal);
export default MemoizedCitiesModal;
