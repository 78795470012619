import {
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useFocusEffect } from "@react-navigation/core";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
  useDrawerStatus
} from "@react-navigation/drawer";
import {
  DrawerActions,
  getFocusedRouteNameFromRoute
} from "@react-navigation/native";
import React from "react";
import { isMobile } from "react-device-detect";
import { LayoutAnimation, Text, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import {
  DISPLAY_ALL,
  LOGOUT,
  SET_DISPLAY_FILTER_OPTION,
  SET_GOODDEAL_DISPLAY_FILTER_OPTION,
  SHOW_QR_CODE_SCANNER_MODAL
} from "../actions/types";
import { getImageUri } from "../assets/Images";
import { ROLE_GUEST } from "../config/constants";
import Ads from "../containers/Ads";
import AdsMap from "../containers/AdsMap";
import AppInfos from "../containers/AppInfos";
import ChatNeighbors from "../containers/ChatNeighbors";
import ChatEmployees from "../containers/ChatEmployees";
import ChatGroup from "../containers/ChatGroup";
import ChatUsers from "../containers/ChatUsers";
import CommunityDetails from "../containers/CommunityDetails";
import Live from "../containers/Live";
import Meteo from "../containers/Meteo";
import Settings from "../containers/Settings";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR5,
  isiOS,
  isNative,
  WHITE_COLOR
} from "../styles/commonStyles";
import { DEFAULT_HEADER_STYLE, getCommunityUri } from "./CommonNavigation";

const BottomTabs = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function CustomDrawerContent(props) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.profileReducer);
  const isDrawerOpen = useDrawerStatus() === "open";

  const {
    flex1,
    mv20,
    mb20,
    mh20,
    fontBold,
    row,
    alignItemsCenter,
    justifyContentCenter,
    bgWhite,
    bgColor2,
    bgColor5
  } = commonStyles;
  const {
    navigation,
    displayFilter = false,
    displayDisconnect = false
  } = props;

  useFocusEffect(() => {
    navigation
      ?.getParent()
      ?.getParent()
      ?.setOptions({
        // headerTitle: getHeaderTitle(route),
        headerLeft: () => (
          <TouchableOpacity
            style={flex1}
            onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
          >
            <View
              style={[
                isDrawerOpen ? bgWhite : bgColor2,
                alignItemsCenter,
                justifyContentCenter,
                {
                  height: "100%",
                  width: 56,
                  paddingBottom: 3
                }
              ]}
            >
              <MaterialCommunityIcons
                name="menu"
                color={isDrawerOpen ? COLOR2 : WHITE_COLOR}
                size={28}
              />
            </View>
          </TouchableOpacity>
        ),
        headerStyle:
          isDrawerOpen || displayFilter ? bgColor5 : DEFAULT_HEADER_STYLE
      });
  });

  return (
    <DrawerContentScrollView {...props}>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("ProfileUpdateScreen");
          navigation.dispatch(DrawerActions.closeDrawer());
        }}
        disabled={!user || user?.role === ROLE_GUEST}
        style={[row, alignItemsCenter, isiOS ? mb20 : mv20]}
      >
        {user?.role === ROLE_GUEST ? (
          <Avatar
            rounded
            size={40}
            icon={{ name: "guest", type: "zocial" }}
            activeOpacity={0.7}
            containerStyle={[mh20, bgColor5]}
          />
        ) : (
          <Avatar
            rounded
            size={40}
            source={
              user?.photoUri
                ? { uri: user?.photoUri }
                : user?.gender === "F"
                ? getImageUri("avatarF")
                : getImageUri("avatar")
            }
            title={user?.firstname?.toUpperCase().substr(0, 2)}
            activeOpacity={0.7}
            containerStyle={[mh20]}
          />
        )}
        <Text style={[fontBold]}>
          {!user || user.role === ROLE_GUEST
            ? t("profile:guest")
            : `${user?.firstname} ${user?.lastname}`}
        </Text>
      </TouchableOpacity>
      <DrawerItemList {...props} />
      {displayDisconnect && (
        <DrawerItem
          label={t("button:disconnect")}
          icon={({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="exit-run"
                size={size}
                color={color}
              />
            </View>
          )}
          onPress={() => {
            AsyncStorage.removeItem("REDIRECT_LINK");
            dispatch({
              type: LOGOUT
            });
          }}
        />
      )}
    </DrawerContentScrollView>
  );
}

function LiveDrawer(props) {
  const { navigation, route } = props;
  const { bgWhite, flex1, bgColor2, justifyContentCenter, alignItemsCenter } =
    commonStyles;

  const dispatch = useDispatch();
  const { filter, displayFilterOptions } = useSelector(
    state => state.liveReducer
  );
  const { currentCommunity } = useSelector(state => state.communityReducer);

  const isDefault = filter === DISPLAY_ALL && !displayFilterOptions;
  const routeName = getFocusedRouteNameFromRoute(route) ?? route.name;

  useFocusEffect(() => {
    navigation.getParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () =>
        routeName.startsWith("Live") && (
          <TouchableOpacity
            style={flex1}
            onPress={() => {
              LayoutAnimation.easeInEaseOut();
              dispatch({
                type: SET_DISPLAY_FILTER_OPTION,
                payload: !displayFilterOptions
              });
            }}
          >
            <View
              style={[
                isDefault ? bgColor2 : bgWhite,
                alignItemsCenter,
                justifyContentCenter,
                {
                  height: "100%",
                  width: 56,
                  paddingBottom: 3
                }
              ]}
            >
              <FontAwesome
                name="filter"
                color={isDefault ? WHITE_COLOR : COLOR2}
                size={isDefault ? 24 : 32}
                style={{
                  backgroundColor: "transparent"
                }}
              />
            </View>
          </TouchableOpacity>
        )
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => (
        <CustomDrawerContent
          {...props}
          displayFilter={displayFilterOptions}
          displayDisconnect={true}
        />
      )}
      screenOptions={{
        headerShown: false,
        drawerActiveTintColor: WHITE_COLOR,
        drawerActiveBackgroundColor: COLOR2,
        drawerType: "front",
        drawerContentContainerStyle: [bgWhite]
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="LiveScreen"
        component={Live}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("menu:live"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="animation-outline"
                size={size}
                color={color}
              />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="SettingsScreen"
        component={Settings}
        initialParams={{
          communityUri: getCommunityUri(currentCommunity)
        }}
        options={{
          title: t("menu:parameters"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <Ionicons name="settings" size={size} color={color} />
            </View>
          ),
          headerShown: false
        }}
      />
    </Drawer.Navigator>
  );
}

function ChatDrawer(props) {
  const { navigation, route } = props;
  const { justifyContentCenter, alignItemsCenter } = commonStyles;
  const { currentCommunity } = useSelector(state => state.communityReducer);

  useFocusEffect(() => {
    navigation.getParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () => undefined
    });
  });
  return (
    <Drawer.Navigator
      drawerContent={props => <CustomDrawerContent {...props} />}
      screenOptions={{
        drawerActiveTintColor: WHITE_COLOR,
        drawerActiveBackgroundColor: COLOR2,
        drawerType: "front",
        headerShown: false
      }}
      drawerStyle={{ width: 300 }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="ChatOwnScreen"
        component={ChatUsers}
        options={{
          title: t("chat:myconversations"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons name="chat" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="ChatGroupScreen"
        component={ChatGroup}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("chat:groupconversations"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <Ionicons name="ios-chatbubbles" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="ChatEmployeesScreen"
        component={ChatEmployees}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("chat:atmyservice"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome name="handshake-o" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="ChatNeighborsScreen"
        component={ChatNeighbors}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("chat:coneighbors"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome name="users" size={size} color={color} />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function AdsDrawer(props) {
  const dispatch = useDispatch();
  const { navigation, route } = props;
  const {
    bgWhite,
    flex1,
    bgColor2,
    justifyContentCenter,
    alignItemsCenter,
    row,
    h100p
  } = commonStyles;
  const { showQrCodeScannerModal } = useSelector(state => state.urlscanReducer);
  const { filter, displayFilterOptions } = useSelector(
    state => state.adReducer
  );
  const { currentCommunity } = useSelector(state => state.communityReducer);

  const isDefault = !filter.length && !displayFilterOptions;
  const routeName = getFocusedRouteNameFromRoute(route) ?? route.name;
  useFocusEffect(() => {
    navigation.getParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () =>
        (routeName === "AdsScreen" || routeName === "AdsTab") &&
        !showQrCodeScannerModal && (
          <View style={[flex1, row]}>
            {(isNative || isMobile) && (
              <TouchableOpacity
                onPress={() =>
                  dispatch({
                    type: SHOW_QR_CODE_SCANNER_MODAL
                  })
                }
              >
                <View
                  style={[
                    alignItemsCenter,
                    justifyContentCenter,
                    h100p,
                    {
                      width: 56
                    }
                  ]}
                >
                  <MaterialCommunityIcons
                    name="qrcode-scan"
                    color={WHITE_COLOR}
                    size={28}
                  />
                </View>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              onPress={() => {
                LayoutAnimation.easeInEaseOut();
                dispatch({
                  type: SET_GOODDEAL_DISPLAY_FILTER_OPTION,
                  payload: !displayFilterOptions
                });
              }}
            >
              <View
                style={[
                  isDefault ? bgColor2 : bgWhite,
                  alignItemsCenter,
                  justifyContentCenter,
                  {
                    height: "100%",
                    width: 56,
                    paddingBottom: 3
                  }
                ]}
              >
                <FontAwesome
                  name="filter"
                  color={isDefault ? WHITE_COLOR : COLOR2}
                  size={isDefault ? 24 : 32}
                  style={{
                    backgroundColor: "transparent"
                  }}
                />
              </View>
            </TouchableOpacity>
          </View>
        )
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => (
        <CustomDrawerContent {...props} displayFilter={displayFilterOptions} />
      )}
      screenOptions={{
        drawerActiveTintColor: WHITE_COLOR,
        drawerActiveBackgroundColor: COLOR2,
        drawerType: "front",
        headerShown: false
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="AdsScreen"
        component={Ads}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("menu:localLife"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="map-marker-radius"
                size={size}
                color={color}
              />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="AdsMapScreen"
        component={AdsMap}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("menu:near"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="map-search-outline"
                size={size}
                color={color}
              />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function MeteoDrawer(props) {
  const { navigation, route } = props;
  const { justifyContentCenter, alignItemsCenter } = commonStyles;
  const { currentCommunity } = useSelector(state => state.communityReducer);

  useFocusEffect(() => {
    navigation.getParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () => undefined
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => <CustomDrawerContent {...props} />}
      screenOptions={{
        drawerActiveTintColor: WHITE_COLOR,
        drawerActiveBackgroundColor: COLOR2,
        drawerType: "front",
        headerShown: false
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="MeteoScreen"
        component={Meteo}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("menu:weather"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="weather-partly-cloudy"
                size={size}
                color={color}
              />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function CommunityDetailsDrawer(props) {
  const { navigation, route } = props;
  const { justifyContentCenter, alignItemsCenter } = commonStyles;
  const { currentCommunity } = useSelector(state => state.communityReducer);

  useFocusEffect(() => {
    navigation.getParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () => undefined
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => <CustomDrawerContent {...props} />}
      screenOptions={{
        drawerActiveTintColor: WHITE_COLOR,
        drawerActiveBackgroundColor: COLOR2,
        drawerType: "front",
        headerShown: false
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="CommunityDetailsScreen"
        component={CommunityDetails}
        initialParams={{ communityUri: getCommunityUri(currentCommunity) }}
        options={{
          title: t("menu:neighborhood"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialIcons name="apartment" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="AppInfosScreen"
        component={AppInfos}
        options={{
          title: t("menu:about"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <Ionicons name="information-circle" size={size} color={color} />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = getFocusedRouteNameFromRoute(route) ?? route.name;
  switch (routeName) {
    case "TabScreen":
    case "LiveTab":
    case "LiveScreen":
      return t("menu:live");
    case "ChatTab":
      return t("menu:chat");
    case "AdsTab":
    case "AdsScreen":
      return t("menu:localLife");
    case "AdsMapScreen":
      return t("menu:near");
    case "MeteoTab":
    case "MeteoScreen":
      return t("menu:weather");
    case "CommunityDetailsTab":
    case "CommunityDetailsScreen":
      return t("menu:neighborhood");
    case "SettingsScreen":
      return t("menu:parameters");
    case "ChatOwnScreen":
      return t("chat:myconversations");
    case "ChatEmployeesScreen":
      return t("chat:atmyservice");
    case "ChatNeighborsScreen":
      return t("chat:coneighbors");
    case "ChatGroupScreen":
      return t("chat:groupconversations");
    case "AppInfosScreen":
      return t("menu:about");
    default:
      return routeName;
  }
}

export default function BottomTabsNavigator() {
  const { cptNewAds } = useSelector(state => state.adReducer);
  const { totalNotRead } = useSelector(state => state.chatReducer);
  const { cptNewEvents } = useSelector(state => state.liveReducer);
  const { currentCommunity } = useSelector(state => state.communityReducer);

  return (
    <BottomTabs.Navigator
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: "white",
        tabBarActiveBackgroundColor: COLOR2,
        tabBarInactiveBackgroundColor: COLOR5,
        tabBarItemStyle: {
          paddingBottom: 5
        },
        tabBarAdaptive: false,
        tabBarLabelPosition: "below-icon"
      }}
    >
      <BottomTabs.Screen
        name="LiveTab"
        component={LiveDrawer}
        options={{
          tabBarLabel: t("menu:live"),
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="animation-outline"
              size={size}
              color={color}
            />
          ),
          tabBarBadge: cptNewEvents ? cptNewEvents : undefined
        }}
      />
      <BottomTabs.Screen
        name="ChatTab"
        component={ChatDrawer}
        options={{
          tabBarLabel: t("menu:chat"),
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="ios-chatbubbles" size={size} color={color} />
          ),
          tabBarBadge: totalNotRead ? totalNotRead : undefined
        }}
      />
      <BottomTabs.Screen
        name="AdsTab"
        component={AdsDrawer}
        options={{
          tabBarLabel: t("menu:localLife"),
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="map-marker-radius"
              size={size}
              color={color}
            />
          ),
          tabBarBadge: cptNewAds ? cptNewAds : undefined
        }}
      />
      {currentCommunity?.weather && (
        <BottomTabs.Screen
          name="MeteoTab"
          component={MeteoDrawer}
          options={{
            tabBarLabel: t("menu:weather"),
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="weather-partly-cloudy"
                size={size}
                color={color}
              />
            )
          }}
        />
      )}
      <BottomTabs.Screen
        name="CommunityDetailsTab"
        component={CommunityDetailsDrawer}
        options={{
          tabBarLabel: t("menu:infos"),
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="information-circle" size={size} color={color} />
          )
        }}
      />
    </BottomTabs.Navigator>
  );
}
