import {
  API_START,
  API_END,
  LOGOUT_RESULT,
  SET_PROFILE_DETAILS,
  SET_PHOTO_URI,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_BIRTHDATE,
  SET_ADDRESS,
  SET_ZIP_CODE,
  SET_CITY,
  SET_PHONE,
  SET_DESCRIPTION,
  SET_USER_HOBBIES,
  FETCH_UPDATE_PROFILE,
  UPDATE_PROFILE_FAILURE,
  CLEAR_PROFILE_VALIDATE,
  VALIDATE_PROFILE_INPUTS,
  CLEAR_PHOTO,
  DELETE_USER_RESULT,
  SET_COUNTRY_CODE,
  FETCH_GET_USER,
  SET_PSEUDO,
  SET_LANG,
  SET_GENDER,
  CHECK_PSEUDO_FAILURE,
  SET_BLOCK_USER_RESULT,
  SET_UNBLOCK_USER_RESULT,
  SET_USER_PROFILE,
  FETCH_GET_USER_PROFILE,
  SET_VALIDATE_FALSE,
  EDIT_PROFILE,
  SET_DATE_INVALID,
  CLEAR_VALIDATE_PSEUDO,
  VALIDATE_PSEUDO,
  VALIDATE_FIRST_NAME,
  VALIDATE_LAST_NAME,
  CHECK_PSEUDO_RESULT,
  CLEAR_VALIDATE_FIRST_NAME,
  CLEAR_VALIDATE_LAST_NAME,
  CLEAR_VALIDATE_BIRTHDATE,
  VALIDATE_BIRTHDATE,
  CLEAR_VALIDATE_PHONE,
  VALIDATE_PHONE,
  SET_FLOOR,
  SET_BUILDING,
  SET_RESIDENCE,
  SET_DISTRICT,
  SET_APARTMENT_NUMBER,
  TOGGLE_CONFIRM_ADDRESS,
  SET_JOB,
  UPDATE_AUTOMATICALLY_HOBBIES,
  NOT_UPDATE_AUTOMATICALLY_HOBBIES,
  SET_BUILDING_ACCESS,
  SET_STRATEGY,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_CONFIRMATION,
  VALIDATE_NEW_PASSWORD,
  VALIDATE_NEW_PASSWORD_CONFIRMATION,
  CLEAR_VALIDATE_NEW_PASSWORD,
  CLEAR_VALIDATE_NEW_PASSWORD_CONFIRMATION,
  LOGOUT
} from "../actions/types";
import { ROLE_NEIGHBOR } from "../config/constants";
import moment from "moment";
import { t } from "../services/i18n";
import { getTranslatedProperty } from "../services/i18n";

const initialState = {
  isFetching: false,
  files: [],
  _id: null,
  photoUri: null,
  pseudo: null,
  firstname: null,
  lastname: null,
  gender: null,
  birthdate: null,
  address: null,
  zipCode: null,
  city: null,
  country: null,
  phone: null,
  description: null,
  hobbiesIds: null,
  pseudoErrorMsg: null,
  firstnameErrorMsg: null,
  lastnameErrorMsg: null,
  birthdayErrorMsg: null,
  clearProfileValidate: false,
  isValid: false,
  firstLogin: false,
  role: null,
  user: null,
  defaultCountry: "FR",
  needPseudoCheck: false,
  age: null,
  communityId: null,
  company: null,
  isPseudoClearValidate: false,
  isFirstnameClearValidate: false,
  isLastnameClearValidate: false,
  isBirthdateClearValidate: false,
  isPhoneClearValidate: false,
  phoneErrorMsg: null,
  floor: null,
  apartmentNumber: null,
  building: null,
  residence: null,
  district: null,
  confirmAddress: false,
  confirmAddressErrorMsg: null,
  job: null,
  needUpdate: false,
  needRegisterForPush: false,
  updateHobbies: true,
  buildingAccess: null,
  strategy: null,
  passwordErrorMsg: null,
  passwordConfirmationErrorMsg: null,
  newpassword: null,
  newpasswordconfirmation: null,
  isPasswordConfirmationClearValidate: false,
  isPasswordClearValidate: false
};

const validatePseudo = () => {
  let pseudoErrorMsg = null;
  // if (role === ROLE_NEIGHBOR) {
  //   const regex = RegExp("^[a-zA-Z]{3}[a-zA-Z0-9_.-]*$");
  //   if (!pseudo || pseudo.trim().length === 0) {
  //     pseudoErrorMsg = t("profile:pseudomandatory");
  //   } else if (!regex.test(pseudo)) {
  //     pseudoErrorMsg = t("profile:invalidpseudo");
  //   }
  // }
  return pseudoErrorMsg;
};

const validatePassword = state => {
  const { strategy, newpassword } = state;
  if (strategy === "jwt") {
    if (!newpassword) {
      return t("profile:newpasswordmandatory");
    } else if (newpassword.length < 8) {
      return t("profile:newpasswordbadformat");
    }
  }
  return null;
};

const validatePasswordConfirmation = state => {
  const { strategy, newpassword, newpasswordconfirmation } = state;
  if (strategy === "jwt") {
    if (!newpasswordconfirmation) {
      return t("profile:newpasswordconfirmationmandatory");
    } else if (newpasswordconfirmation.length < 8) {
      return t("profile:newpasswordbadformat");
    } else if (newpasswordconfirmation !== newpassword) {
      return t("profile:differentpasswords");
    }
  }
  return null;
};

const validateBirthday = (birthdate, role) => {
  let birthdayErrorMsg = null;
  const regex = RegExp("^[0-9]{2}/[0-9]{2}/[0-9]{4}$");
  let birthday;

  if (role === ROLE_NEIGHBOR) {
    if (!birthdate) {
      birthdayErrorMsg = t("profile:birthdatemandatory");
    } else if (!regex.test(birthdate)) {
      birthdayErrorMsg = t("profile:invaliddateformat");
    } else if ((birthday = moment(birthdate, "DD/MM/YYYY", true))) {
      let age = Math.floor(
        (new Date().getTime() - birthday.valueOf()) / 31536000000
      );
      if (age < 12) {
        birthdayErrorMsg = t("profile:tooyoungerror");
      }
    } else {
      birthdayErrorMsg = t("profile:invaliddateformat");
    }
  }
  return birthdayErrorMsg;
};

export default function profileReducer(state = initialState, action = {}) {
  const isCharDigit = n => n < 10;

  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_USER) {
        return {
          ...state,
          isFetching: true
        };
      } else if (
        action.payload === FETCH_GET_USER_PROFILE ||
        action.payload === FETCH_UPDATE_PROFILE
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      break;

    case LOGOUT:
      return {
        ...initialState,
        defaultCountry: state.defaultCountry
      };

    case SET_STRATEGY:
      return {
        ...state,
        strategy: action.payload
      };

    case SET_FLOOR:
      if (
        (!action.value || isCharDigit(action.value[action.value.length - 1])) &&
        action.value.length <= 2
      ) {
        return {
          ...state,
          floor: action.value
        };
      } else if (!state.floor) {
        return {
          ...state,
          floor: ""
        };
      }
      break;

    case TOGGLE_CONFIRM_ADDRESS:
      return {
        ...state,
        confirmAddress: !state.confirmAddress,
        confirmAddressErrorMsg: null
      };

    case SET_BUILDING_ACCESS:
      return {
        ...state,
        buildingAccess: action.value
      };

    case SET_APARTMENT_NUMBER:
      if (!action.value || isCharDigit(action.value[action.value.length - 1])) {
        return {
          ...state,
          apartmentNumber: action.value
        };
      } else if (!state.apartmentNumber) {
        return {
          ...state,
          apartmentNumber: ""
        };
      }
      break;

    case SET_JOB:
      return {
        ...state,
        job: action.value
      };

    case SET_BUILDING:
      return {
        ...state,
        building: action.value
      };

    case SET_RESIDENCE:
      return {
        ...state,
        residence: action.value
      };

    case SET_DISTRICT:
      return {
        ...state,
        district: action.value
      };

    case SET_DATE_INVALID:
      return {
        ...state,
        birthdayErrorMsg: t("profile:invaliddate")
      };

    case SET_LANG:
      return {
        ...state,
        defaultCountry: action.country
      };

    case CLEAR_PROFILE_VALIDATE:
      return {
        ...state,
        clearProfileValidate: true,
        firstnameErrorMsg: null,
        lastnameErrorMsg: null,
        birthdayErrorMsg: null,
        confirmAddressErrorMsg: null,
        isValid: false
      };

    case SET_VALIDATE_FALSE:
      return {
        ...state,
        isValid: false,
        needRegisterForPush: false
      };

    case VALIDATE_PROFILE_INPUTS:
      const pseudoErrorMsg = validatePseudo(state.pseudo, state.role);
      const birthdayErrorMsg = validateBirthday(state.birthdate, state.role);
      const passwordErrorMsg = validatePassword(state);
      const passwordConfirmationErrorMsg = validatePasswordConfirmation(state);
      const phoneErrorMsg =
        state.phone && state.phone.length < 20
          ? t("profile:invalidphone")
          : null;
      const confirmAddressErrorMsg = state.confirmAddress
        ? null
        : t("profile:confirmaddresserror");
      const isValid =
        !pseudoErrorMsg &&
        !birthdayErrorMsg &&
        !phoneErrorMsg &&
        !passwordErrorMsg &&
        !passwordConfirmationErrorMsg &&
        !confirmAddressErrorMsg &&
        ((state.firstname &&
          state.firstname.trim().length > 0 &&
          state.lastname &&
          state.lastname.trim().length > 0 &&
          true) ||
          state.role !== ROLE_NEIGHBOR);
      return {
        ...state,
        clearProfileValidate: false,
        firstnameErrorMsg:
          state.firstname && state.firstname.trim().length > 0
            ? null
            : t("profile:firstnamemandatory"),
        lastnameErrorMsg:
          state.lastname && state.lastname.trim().length > 0
            ? null
            : t("profile:lastnamemandatory"),
        birthdayErrorMsg,
        pseudoErrorMsg,
        passwordErrorMsg,
        passwordConfirmationErrorMsg,
        phoneErrorMsg,
        confirmAddressErrorMsg,
        isValid,
        strategy: isValid ? null : state.strategy
      };

    case UPDATE_PROFILE_FAILURE:
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message.endsWith("409")
      ) {
        return {
          ...state,
          pseudoErrorMsg: t("profile:pseudoduplicate")
        };
      }
      break;

    case CLEAR_PHOTO:
      return {
        ...state,
        photoUri: undefined,
        files: []
      };

    case DELETE_USER_RESULT:
    case LOGOUT_RESULT:
      return {
        ...state,
        user: null
      };

    case SET_USER_PROFILE: {
      const {
        userId,
        photoUri,
        pseudo,
        firstname,
        lastname,
        gender,
        birthdate,
        city,
        zipCode,
        buildingAccess,
        building,
        residence,
        district,
        floor,
        apartmentNumber,
        job,
        hobbiesIds,
        role,
        phone,
        company
      } = action.payload;

      let age = null;
      if (birthdate) {
        const birthday = moment(birthdate, "DD-MM-YYYY").toDate();
        age = Math.floor(
          (new Date().getTime() - birthday.getTime()) / 31536000000
        );
      }
      return {
        ...state,
        _id: userId,
        photoUri,
        hobbiesIds,
        pseudo,
        firstname,
        lastname,
        gender,
        description: getTranslatedProperty(action.payload, "description"),
        city,
        zipCode,
        building,
        buildingAccess,
        residence,
        district,
        floor,
        apartmentNumber,
        job,
        role,
        age,
        phone,
        company
      };
    }

    case SET_PROFILE_DETAILS: {
      const {
        _id,
        photoUri,
        pseudo,
        firstname,
        lastname,
        gender,
        birthdate,
        address,
        zipCode,
        city,
        buildingAccess,
        building,
        residence,
        district,
        floor,
        apartmentNumber,
        job,
        phone,
        description,
        hobbiesIds,
        role,
        updateHobbies,
        firstLogin,
        needUpdate
      } = action.payload;

      return {
        ...state,
        _id,
        photoUri,
        pseudo:
          pseudo && pseudo.indexOf("@") >= 0 && state.role === ROLE_NEIGHBOR
            ? null
            : pseudo,
        firstname,
        lastname,
        gender,
        birthdate,
        address,
        zipCode,
        city,
        building,
        buildingAccess,
        residence,
        district,
        floor,
        apartmentNumber,
        job,
        phone,
        description,
        hobbiesIds,
        role,
        confirmAddress: !needUpdate,
        needUpdate,
        needRegisterForPush: needUpdate,
        user: action.payload,
        firstLogin,
        updateHobbies
      };
    }

    case UPDATE_AUTOMATICALLY_HOBBIES:
      return {
        ...state,
        updateHobbies: true
      };

    case NOT_UPDATE_AUTOMATICALLY_HOBBIES:
      return {
        ...state,
        updateHobbies: false
      };

    case EDIT_PROFILE:
      const {
        _id,
        photoUri,
        // pseudo,
        firstname,
        lastname,
        gender,
        birthdate,
        address,
        zipCode,
        city,
        building,
        residence,
        district,
        floor,
        apartmentNumber,
        job,
        phone,
        description,
        hobbiesIds,
        firstLogin
      } = state.user;

      return {
        ...state,
        _id,
        photoUri,
        // pseudo:
        //   pseudo && pseudo.indexOf("@") >= 0 && state.role === ROLE_NEIGHBOR
        //     ? null
        //     : pseudo,
        firstname,
        lastname,
        gender,
        birthdate,
        address,
        zipCode,
        city,
        building,
        residence,
        district,
        floor,
        apartmentNumber,
        job,
        phone,
        description,
        hobbiesIds,
        firstLogin
      };

    case SET_PSEUDO:
      return {
        ...state,
        pseudo: action.value,
        pseudoErrorMsg: null,
        firstnameErrorMsg: null,
        lastnameErrorMsg: null,
        birthdayErrorMsg: null,
        phoneErrorMsg: null,
        needPseudoCheck: true
      };

    case CLEAR_VALIDATE_PSEUDO:
      return {
        ...state,
        isPseudoClearValidate:
          !state.firstnameErrorMsg &&
          !state.lastnameErrorMsg &&
          !state.birthdayErrorMsg &&
          !state.phoneErrorMsg
      };

    case VALIDATE_PSEUDO:
      return {
        ...state,
        pseudoErrorMsg: validatePseudo(state.pseudo, state.role),
        isPseudoClearValidate: false
      };

    case CHECK_PSEUDO_RESULT:
      return {
        ...state,
        needPseudoCheck: false
      };

    case CHECK_PSEUDO_FAILURE: {
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message.endsWith("409")
      ) {
        return {
          ...state,
          pseudoErrorMsg: t("profile:pseudoduplicate")
        };
      }
    }

    case CLEAR_VALIDATE_FIRST_NAME:
      return {
        ...state,
        isFirstnameClearValidate:
          !state.pseudoErrorMsg &&
          !state.lastnameErrorMsg &&
          !state.birthdayErrorMsg &&
          !state.phoneErrorMsg
      };

    case SET_FIRST_NAME:
      return {
        ...state,
        firstname: action.value,
        pseudoErrorMsg: null,
        firstnameErrorMsg: null,
        lastnameErrorMsg: null,
        birthdayErrorMsg: null,
        phoneErrorMsg: null
      };

    case VALIDATE_FIRST_NAME:
      return {
        ...state,
        firstnameErrorMsg:
          state.firstname && state.firstname.trim().length > 0
            ? null
            : t("profile:firstnamemandatory"),
        isFirstnameClearValidate: false
      };

    case CLEAR_VALIDATE_NEW_PASSWORD:
      return {
        ...state,
        isPasswordClearValidate: !state.passwordConfirmationErrorMsg
      };

    case SET_NEW_PASSWORD:
      return {
        ...state,
        newpassword: action.value,
        passwordErrorMsg: null,
        passwordConfirmationErrorMsg: null
      };

    case VALIDATE_NEW_PASSWORD:
      return {
        ...state,
        passwordErrorMsg: validatePassword(state),
        isPasswordClearValidate: false
      };

    case CLEAR_VALIDATE_NEW_PASSWORD_CONFIRMATION:
      return {
        ...state,
        isPasswordConfirmationClearValidate: !state.passwordErrorMsg
      };

    case SET_NEW_PASSWORD_CONFIRMATION:
      return {
        ...state,
        newpasswordconfirmation: action.value,
        passwordErrorMsg: null,
        passwordConfirmationErrorMsg: null
      };

    case VALIDATE_NEW_PASSWORD_CONFIRMATION:
      return {
        ...state,
        passwordConfirmationErrorMsg: validatePasswordConfirmation(state),
        isPasswordConfirmationClearValidate: false
      };

    case CLEAR_VALIDATE_LAST_NAME:
      return {
        ...state,
        isLastnameClearValidate:
          !state.pseudoErrorMsg &&
          !state.firstnameErrorMsg &&
          !state.birthdayErrorMsg &&
          !state.phoneErrorMsg
      };

    case SET_LAST_NAME:
      return {
        ...state,
        lastname: action.value,
        pseudoErrorMsg: null,
        firstnameErrorMsg: null,
        lastnameErrorMsg: null,
        birthdayErrorMsg: null,
        phoneErrorMsg: null
      };

    case VALIDATE_LAST_NAME:
      return {
        ...state,
        lastnameErrorMsg:
          state.lastname && state.lastname.trim().length > 0
            ? null
            : t("profile:lastnamemandatory"),
        isLastnameClearValidate: false
      };

    case CLEAR_VALIDATE_BIRTHDATE:
      return {
        ...state,
        isBirthdateClearValidate:
          !state.pseudoErrorMsg &&
          !state.firstnameErrorMsg &&
          !state.lastnameErrorMsg &&
          !state.phoneErrorMsg
      };

    case SET_BIRTHDATE:
      if (action.value.length === 10) {
        return {
          ...state,
          birthdate: action.value,
          isBirthdateClearValidate: true,
          pseudoErrorMsg: null,
          firstnameErrorMsg: null,
          lastnameErrorMsg: null,
          birthdayErrorMsg: null,
          phoneErrorMsg: null
        };
      }
      return {
        ...state,
        birthdate: action.value,
        pseudoErrorMsg: null,
        firstnameErrorMsg: null,
        lastnameErrorMsg: null,
        birthdayErrorMsg: null,
        phoneErrorMsg: null
      };

    case VALIDATE_BIRTHDATE:
      return {
        ...state,
        birthdayErrorMsg: validateBirthday(state.birthdate, state.role),
        isBirthdateClearValidate: false
      };

    case CLEAR_VALIDATE_PHONE:
      return {
        ...state,
        isPhoneClearValidate:
          !state.pseudoErrorMsg &&
          !state.firstnameErrorMsg &&
          !state.lastnameErrorMsg &&
          !state.birthdayErrorMsg
      };

    case SET_PHONE:
      if (
        (!state.country || state.country === "FR") &&
        action.value.length === 20
      ) {
        return {
          ...state,
          phone: action.value,
          isPhoneClearValidate: true,
          pseudoErrorMsg: null,
          firstnameErrorMsg: null,
          lastnameErrorMsg: null,
          birthdayErrorMsg: null,
          phoneErrorMsg: null
        };
      }
      return {
        ...state,
        phone: action.value,
        pseudoErrorMsg: null,
        firstnameErrorMsg: null,
        lastnameErrorMsg: null,
        birthdayErrorMsg: null,
        phoneErrorMsg: null
      };

    case VALIDATE_PHONE:
      return {
        ...state,
        phoneErrorMsg:
          state.phone && state.phone.length < 20
            ? t("profile:invalidphone")
            : null,
        isPhoneClearValidate: false
      };

    case SET_ADDRESS:
      return {
        ...state,
        address: action.value
      };

    case SET_GENDER:
      return {
        ...state,
        gender: action.value
      };

    case SET_ZIP_CODE:
      return {
        ...state,
        zipCode: action.value
      };

    case SET_CITY:
      return {
        ...state,
        city: action.value
      };

    case SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.value,
        phone: null
      };

    case SET_DESCRIPTION:
      return {
        ...state,
        description: action.value
      };

    case SET_USER_HOBBIES:
      return {
        ...state,
        hobbiesIds: action.value
      };

    case SET_PHOTO_URI:
      return {
        ...state,
        photoUri: action.value,
        files: action.files
      };

    case SET_BLOCK_USER_RESULT:
    case SET_UNBLOCK_USER_RESULT:
      const { blockedUsersIds } = action.payload;
      if (state.user) {
        const user = { ...state.user, blockedUsersIds };
        return {
          ...state,
          user
        };
      }
      break;

    case API_END:
      if (
        action.payload === FETCH_GET_USER ||
        action.payload === FETCH_UPDATE_PROFILE ||
        action.payload === FETCH_GET_USER_PROFILE
      ) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
