import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import React, { memo } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Avatar, Badge, Button } from "react-native-elements";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getImageUri } from "../assets/Images";
import { ROLE_GUEST, ROLE_WEBMASTER } from "../config/constants";
import { getProfileReducer } from "../selectors";
import { getTranslatedProperty } from "../services/i18n";
import commonStyles, { WHITE_COLOR } from "../styles/commonStyles";

const getSelector = createSelector([getProfileReducer], ({ user }) => ({
  user
}));

const ChatUserItem = ({ item }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const { user } = useSelector(getSelector, shallowEqual);

  const {
    bgWhite,
    row,
    w100p,
    font,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    p5,
    mv10,
    shadowGrey,
    bgLightgrey,
    bgColor2,
    darkgrey,
    color4,
    rounded30,
    rounded22,
    mv5,
    p10,
    pr10,
    h60,
    mb10,
    h44,
    fs10
  } = commonStyles;
  const {
    _id,
    pseudo,
    firstname,
    photoUri,
    notRead,
    online,
    gender,
    bookingsIds
  } = item;

  return (
    <View style={[w100p, bgWhite, mv10, p10, shadowGrey, mv5]}>
      <View style={[bgLightgrey, row, h60, rounded30, alignItemsCenter, pr10]}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("ProfileTeamScreen", {
              userId: _id
            })
          }
          disabled={_id === user?._id}
        >
          <View>
            <Avatar
              rounded
              size={60}
              source={
                photoUri
                  ? { uri: photoUri }
                  : gender === "F"
                  ? getImageUri("avatarF")
                  : getImageUri("avatar")
              }
              title={firstname.toUpperCase().substr(0, 2)}
              activeOpacity={0.7}
            />
            {Boolean(notRead || user?.role === ROLE_WEBMASTER) && (
              <Badge
                status={
                  user?.role === ROLE_WEBMASTER
                    ? online
                      ? "success"
                      : "error"
                    : "error"
                }
                value={notRead ? notRead : " "}
                containerStyle={{ position: "absolute", top: -4, right: -4 }}
              />
            )}
          </View>
        </TouchableOpacity>
        <View style={[justifyContentCenter, flex1, p5]}>
          <TouchableOpacity
            onPress={() => {
              if (user?.role !== ROLE_GUEST) {
                navigation.navigate("ChatTeamScreen", { to: _id });
              } else {
                dispatch({
                  type: SET_DROP_DOWN_ALERT_WARN,
                  warn: "guestforbidden"
                });
              }
            }}
            disabled={_id === user?._id}
          >
            <Text style={[font, darkgrey, { fontSize: 15 }, mb10]}>
              {firstname}
            </Text>
          </TouchableOpacity>
          <Text style={[font, color4, fs10]}>
            {getTranslatedProperty(item, "description")}
          </Text>
        </View>
        <Button
          icon={
            <MaterialCommunityIcons
              name={"chat"}
              size={28}
              color={WHITE_COLOR}
            />
          }
          buttonStyle={[bgColor2, h44, rounded22]}
          onPress={() => {
            if (user?.role !== ROLE_GUEST) {
              navigation.navigate("ChatTeamScreen", { to: _id });
            } else {
              dispatch({
                type: SET_DROP_DOWN_ALERT_WARN,
                warn: "guestforbidden"
              });
            }
          }}
        />
      </View>
    </View>
  );
};

const MemoizedChatUserItem = memo(ChatUserItem);
export default MemoizedChatUserItem;
