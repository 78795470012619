import { Fontisto } from "@expo/vector-icons";
import React, { Component } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-elements";
import { getImageUri } from "../assets/Images";
import { t } from "../services/i18n";
import commonStyles, {
  DARKGREY_COLOR,
  SCREEN_WIDTH
} from "../styles/commonStyles";

const MapRouteImage = props => {
  const { font, mh20, rounded10 } = commonStyles;
  const { uri, callback } = props;
  return (
    <View>
      <Avatar
        containerStyle={[
          {
            height: 300,
            width: SCREEN_WIDTH - 40
          },
          mh20,
          rounded10
        ]}
        renderPlaceholderContent={
          <Image
            style={[
              {
                height: 300,
                width: SCREEN_WIDTH - 40
              },
              rounded10
            ]}
            source={getImageUri("placeholder")}
          />
        }
        source={{
          uri
        }}
        overlayContainerStyle={[rounded10]}
        onPress={() => callback && callback()}
      />
      <TouchableOpacity
        style={{
          width: 200,
          zIndex: 999,
          position: "absolute",
          bottom: 60,
          right: SCREEN_WIDTH / 2 - 100
        }}
        onPress={() => callback && callback()}
      >
        <Fontisto.Button
          name="save"
          size={30}
          color={DARKGREY_COLOR}
          backgroundColor={"rgba(255, 255, 255, 0.4)"}
          borderRadius={25}
          onPress={() => callback && callback()}
        >
          <Text style={[font]}>{t("infos:save")}</Text>
        </Fontisto.Button>
      </TouchableOpacity>
    </View>
  );
};

export default MapRouteImage;
