import {
  API_START,
  API_END,
  FETCH_PUBLISH_EVENT,
  CLEAR_POST_PHOTO_DATA,
  SET_POST_DESCRIPTION_FR,
  SET_POST_DESCRIPTION_EN,
  SET_POST_PHOTO_DATA,
  SET_PUBLISH_EVENT_RESULT,
  SET_POST_ENGLISH,
  CLEAR_POST_VALIDATE,
  VALIDATE_POST_INPUTS,
  RESET_POST,
  SET_POST_TITLE_FR,
  SET_POST_TITLE_EN,
  SET_READY_TO_COPY_URL,
  CLEAR_READY_TO_COPY_URL,
  SET_POST_VIDEO_ID
} from "../actions/types";
import { t } from "../services/i18n";

const initialState = {
  isFetching: false,
  files: [],
  photoUri: null,
  title: null,
  description: null,
  descriptionErrorMsg: null,
  lang: null,
  clearPostValidate: false,
  isValid: false,
  isEnglish: false,
  readyToCopyUrl: false,
  youtubeVideoId: null,
  publishResult: null
};

export default function postReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_PUBLISH_EVENT) {
        return {
          ...state,
          isFetching: true
        };
      }
      break;

    case RESET_POST:
      return {
        ...initialState
      };

    case SET_READY_TO_COPY_URL:
      return {
        ...state,
        readyToCopyUrl: true
      };

    case CLEAR_READY_TO_COPY_URL:
      return {
        ...state,
        readyToCopyUrl: false
      };

    case CLEAR_POST_VALIDATE:
      return {
        ...state,
        clearPostValidate: true,
        descriptionErrorMsg: null,
        isValid: false
      };

    case VALIDATE_POST_INPUTS:
      const descriptionErrorMsg =
        state.description && state.description.trim().length > 0
          ? null
          : t("live:descriptionmandatory");
      return {
        ...state,
        clearPostValidate: false,
        descriptionErrorMsg,
        isEnglish: descriptionErrorMsg ? false : state.isEnglish,
        isValid: !descriptionErrorMsg
      };

    case SET_POST_ENGLISH:
      return {
        ...state,
        isEnglish: action.payload
      };

    case SET_POST_DESCRIPTION_FR:
      return {
        ...state,
        description: action.value,
        descriptionErrorMsg: null
      };

    case SET_POST_DESCRIPTION_EN: {
      let en;
      if (action.value) {
        const description = {
          description: action.value
        };
        en = {
          en: description
        };
      }
      return {
        ...state,
        lang: en
      };
    }

    case SET_POST_TITLE_FR:
      return {
        ...state,
        title: action.value
      };

    case SET_POST_TITLE_EN: {
      let en;
      if (action.value) {
        const title = {
          title: action.value
        };
        en = {
          en: title
        };
      }
      return {
        ...state,
        lang: en
      };
    }

    case CLEAR_POST_PHOTO_DATA:
      return {
        ...state,
        files: [],
        photoUri: null,
        youtubeVideoId: null
      };

    case SET_POST_PHOTO_DATA:
      return {
        ...state,
        files: action.files ? action.files : [],
        photoUri: action.value,
        youtubeVideoId: null
      };

    case SET_POST_VIDEO_ID:
      return {
        ...state,
        files: [],
        photoUri: null,
        youtubeVideoId: action.value
      };

    case SET_PUBLISH_EVENT_RESULT:
      return {
        ...state,
        publishResult: action.payload
      };

    case API_END:
      if (action.payload === FETCH_PUBLISH_EVENT) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }

  return state;
}
