import Constants from "expo-constants";
import { Platform } from "react-native";
import { schema } from "normalizr";
import { apiAction } from "./api";
import { fetchGetAdsCategories } from "./ad";
import { fetchGetLocations } from "./location";
import { fetchGetHobbies, fetchGetUserAndMyChatUsers } from "./profile";
import {
  fetchGetTemplateDates,
  fetchGetTemplates,
  fetchGetTemplateTimes
} from "./template";
import {
  FETCH_GET_GLOBAL_INFORMATIONS,
  FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN,
  FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
  SET_GLOBAL_INFORMATIONS,
  SET_GLOBAL_INFORMATIONS_FAILURE,
  SET_GLOBAL_INFORMATIONS_VERSIONS,
  SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
  FETCH_GET_CITIES,
  SET_CITIES,
  SET_CITIES_FAILURE,
  FETCH_GET_CURRENT_COMMUNITY,
  SET_CURRENT_COMMUNITY,
  FETCH_LOGOUT,
  LOGOUT_RESULT,
  LOGOUT_FAILURE,
  SET_CURRENT_COMMUNITY_FAILURE
} from "./types";
import { APP_NAME, COMMUNITIES } from "../config/constants";

const city = new schema.Entity(
  "cities",
  {},
  {
    idAttribute: "_id"
  }
);

const infospage = new schema.Entity("infospages", {
  docs: [city]
});

export function fetchCheckVersionAuthenticated(communityId) {
  return apiAction({
    url: "/globalinformations/check/version/authenticated",
    method: "POST",
    headers: null,
    data: {
      version: Constants.manifest.version,
      appname: APP_NAME,
      isDev: true
    },
    onSuccess: data => [
      fetchGetCurrentCommunity(communityId),
      setGlobalInformations(data)
    ],
    onFailure: setGlobalInformationsFailure,
    label: communityId
      ? FETCH_GET_GLOBAL_INFORMATIONS
      : FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN
  });
}

function setGlobalInformationsVersions(data) {
  return {
    type: SET_GLOBAL_INFORMATIONS_VERSIONS,
    payload: data
  };
}

function setGlobalInformations(data) {
  return {
    type: SET_GLOBAL_INFORMATIONS,
    payload: data
  };
}

export function fetchCheckVersion() {
  return apiAction({
    url: "/globalinformations/check/version",
    method: "POST",
    data: {
      version: Constants.manifest.version,
      appname: APP_NAME,
      isDev: __DEV__ || Platform.OS === "web"
    },
    onSuccess: setGlobalInformationsVersions,
    onFailure: setGlobalInformationsVersionsFailure,
    label: FETCH_GET_GLOBAL_INFORMATIONS_VERSION
  });
}

function setGlobalInformationsFailure(error) {
  return {
    type: SET_GLOBAL_INFORMATIONS_FAILURE,
    payload: error
  };
}

function setGlobalInformationsVersionsFailure(error) {
  return {
    type: SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
    payload: error
  };
}

export function fetchGetCurrentCommunity(communityId) {
  return apiAction({
    url: `/${COMMUNITIES}/current`,
    method: "GET",
    onSuccess: data =>
      communityId && (!data || communityId !== data._id)
        ? [fetchLogout()]
        : [
            setCurrentCommunity(data),
            fetchGetLocations(),
            fetchGetTemplates(),
            fetchGetTemplateDates(),
            fetchGetTemplateTimes(),
            fetchGetAdsCategories(),
            fetchGetHobbies(),
            fetchGetUserAndMyChatUsers()
          ],
    onFailure: setCurrentCommunityFailure,
    label: FETCH_GET_CURRENT_COMMUNITY
  });
}

function setCurrentCommunity(data) {
  return {
    type: SET_CURRENT_COMMUNITY,
    payload: data
  };
}

function setCurrentCommunityFailure(error) {
  return {
    type: SET_CURRENT_COMMUNITY_FAILURE,
    payload: error
  };
}

export function fetchGetCities({ page, longitude, latitude, search }) {
  return apiAction({
    url: "/cities/search",
    method: "GET",
    data: {
      page,
      longitude,
      latitude,
      name: search
    },
    schema: infospage,
    onSuccess: setCities,
    onFailure: setCitiesFailure,
    label: FETCH_GET_CITIES
  });
}

function setCities(normalized) {
  return {
    type: SET_CITIES,
    entities: normalized.entities
  };
}

function setCitiesFailure(error) {
  return {
    type: SET_CITIES_FAILURE,
    error
  };
}

const TYPE_SHARING = 1;
const TYPE_HAPICOLIBRI_SHARING = 2;
// const TYPE_OCAMPING_HOME = 3;
const TYPE_HAPICOLIBRI_HOME = 4;
const TYPE_AD_CATEGORY = 5;
const TYPE_METEO = 6;
const TYPE_OTHERS = 7;

export function fetchGetCachedImages() {
  return apiAction({
    url: "/globalinformations/cachedImages",
    method: "GET",
    data: {
      types: [
        TYPE_SHARING,
        TYPE_HAPICOLIBRI_SHARING,
        TYPE_HAPICOLIBRI_HOME,
        TYPE_AD_CATEGORY,
        TYPE_METEO,
        TYPE_OTHERS
      ]
    }
  });
}

export function fetchLogout() {
  return apiAction({
    url: "/api/auth",
    method: "DELETE",
    onSuccess: logout,
    onFailure: logoutFailure,
    label: FETCH_LOGOUT
  });
}

function logout(data) {
  return {
    type: LOGOUT_RESULT,
    payload: data
  };
}

function logoutFailure(error) {
  return {
    type: LOGOUT_FAILURE,
    payload: error
  };
}
