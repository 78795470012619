import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/core";
import React, { Fragment, memo, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublishRequest } from "../actions/template";
import {
  CLEAR_PUBLISH,
  SET_DROP_DOWN_ALERT_WARN,
  SET_PUBLISH_CHOICE,
  SET_PUBLISH_DATE,
  SET_PUBLISH_EQUIPMENT,
  SET_PUBLISH_TEMPLATE,
  SET_PUBLISH_TIME
} from "../actions/types";
import ActionButton from "../components/actionButton/ActionButton";
import CommonPicker from "../components/CommonPicker";
import LiveEvent from "../components/LiveEvent";
import { ROLE_GUEST } from "../config/constants";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, { COLOR2, COLOR5 } from "../styles/commonStyles";

const SelectTemplateDate = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const [menuOpen, setMenuOpen] = useState(false);

  let {
    isFetching,
    dateId,
    timeId,
    equipmentId,
    ad,
    choiceId,
    locationId,
    templateDates,
    templateTimes,
    equipments,
    displayTimes,
    template,
    data,
    groupId,
    displayPreview
  } = useSelector(state => state.templateReducer);
  const { templates } = useSelector(state => state.entitiesReducer);
  const { user } = useSelector(state => state.profileReducer);

  useEffect(() => {
    if (!template && route.params?.id) {
      template = templates[route.params.id];
      dispatch({
        type: SET_PUBLISH_TEMPLATE,
        payload: template
      });
    }
    navigation.setOptions({
      title: getTranslatedProperty(template, "name")
    });
    return () =>
      dispatch({
        type: CLEAR_PUBLISH
      });
  }, []);

  useEffect(() => {
    if (data) {
      navigation.navigate("LiveScreen");
    }
  }, [data]);

  const sendRequest = () => {
    if (user) {
      if (user.role === ROLE_GUEST) {
        dispatch({
          type: SET_DROP_DOWN_ALERT_WARN,
          warn: "guestforbidden"
        });
      } else {
        dispatch(
          fetchPublishRequest({
            dateId,
            timeId,
            locationId,
            equipmentId,
            templateId: template._id,
            groupId,
            choiceId,
            goodDealId: ad?._id
          })
        );
      }
    } else {
      dispatch({
        type: SET_DROP_DOWN_ALERT_WARN,
        warn: "nouserforbidden"
      });
    }
  };

  const {
    color5,
    bgColor1,
    flex1,
    font,
    fontBold,
    fs16,
    pv10,
    column
  } = commonStyles;

  if (!template && route.params?.id) {
    template = templates[route.params.id];
  }

  const item = {
    groupId,
    event: {
      dateId,
      timeId,
      locationId,
      equipmentId,
      choiceId
    }
  };
  return (
    <View style={[flex1, bgColor1, column]}>
      {displayPreview && template && (
        <LiveEvent item={item} template={template} />
      )}

      <ScrollView style={flex1} contentContainerStyle={{ paddingBottom: 90 }}>
        {template && template.choices && (
          <Fragment>
            <Text
              style={[
                font,
                color5,
                { fontSize: 18, marginLeft: 10, marginTop: 15 }
              ]}
            >
              {t("template:selectmessage")}
            </Text>
            <View style={{ paddingTop: 10, paddingHorizontal: 20 }}>
              <CommonPicker
                data={template.choices}
                selectedValue={choiceId || ""}
                onChange={itemValue =>
                  dispatch({
                    type: SET_PUBLISH_CHOICE,
                    payload: itemValue
                  })
                }
              />
            </View>
          </Fragment>
        )}
        <Text
          style={[
            font,
            color5,
            { fontSize: 18, marginLeft: 10, marginTop: 15 }
          ]}
        >
          {t("template:proposedate")}
        </Text>
        <View style={{ paddingTop: 10, paddingHorizontal: 20 }}>
          <CommonPicker
            data={templateDates}
            firstPickerLabel={t("template:proposedate")}
            selectedValue={dateId || ""}
            onChange={itemValue =>
              dispatch({
                type: SET_PUBLISH_DATE,
                payload: itemValue
              })
            }
          />
          {displayTimes && (
            <View style={{ paddingTop: 10 }}>
              <CommonPicker
                data={templateTimes}
                selectedValue={timeId || ""}
                onChange={itemValue =>
                  dispatch({
                    type: SET_PUBLISH_TIME,
                    payload: itemValue
                  })
                }
              />
            </View>
          )}
        </View>
        {equipments && (
          <Fragment>
            <Text
              style={[
                font,
                color5,
                { fontSize: 18, marginLeft: 10, marginTop: 15 }
              ]}
            >
              {t("template:proposelocation")}
            </Text>
            <View style={[pv10, { paddingHorizontal: 20 }]}>
              <CommonPicker
                data={equipments}
                firstPickerLabel={t("template:proposelocation")}
                selectedValue={equipmentId || ""}
                onChange={itemValue =>
                  dispatch({
                    type: SET_PUBLISH_EQUIPMENT,
                    payload: itemValue
                  })
                }
              />
            </View>
          </Fragment>
        )}
      </ScrollView>
      <ActionButton
        buttonColor={menuOpen ? COLOR5 : COLOR2}
        degrees={90}
        renderIcon={() => (
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={40}
            color="white"
          />
        )}
        onPressIn={() => setMenuOpen(true)}
        onReset={() => setMenuOpen(false)}
        disabled={isFetching}
      >
        <ActionButton.Item
          buttonColor={"red"}
          title={t("button:cancel")}
          onPress={() => {
            navigation.navigate("LiveScreen");
          }}
          textStyle={[fontBold, fs16]}
        >
          <MaterialCommunityIcons name="cancel" size={36} color="white" />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor={COLOR2}
          title={t("button:post")}
          onPress={sendRequest}
          textStyle={[fontBold, fs16]}
        >
          <FontAwesome name="send" size={32} color="white" />
        </ActionButton.Item>
      </ActionButton>
    </View>
  );
};

const MemoizedSelectTemplateDate = memo(SelectTemplateDate);
export default MemoizedSelectTemplateDate;
