import {
  Entypo,
  FontAwesome,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import { useLinkTo, useNavigation, useRoute } from "@react-navigation/native";
import Constants from "expo-constants";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Easing,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetLiveEvents } from "../actions/live";
import {
  CLEAR_INITIAL_LINK,
  HIDE_SPLASH_SCREEN,
  LIVE_ON_TOP,
  LIVE_REFRESH,
  SET_DROP_DOWN_ALERT_ERROR
} from "../actions/types";
import ActionButton from "../components/actionButton/ActionButton";
import LiveEvent from "../components/LiveEvent";
import LiveFilters from "../components/LiveFilters";
import LiveWeather from "../components/LiveWeather";
import {
  AUTHENTICATED_ROUTES,
  GROUP_ID_WEATHER,
  ROLE_ADMINISTRATOR,
  ROLE_EMPLOYEE,
  ROLE_WEBMASTER
} from "../config/constants";
import { getLiveSelector } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR5,
  isNative,
  isWeb
} from "../styles/commonStyles";
import { getRedirectLink } from "../utils/UrlUtil";

const bottomPosition = new Animated.Value(-60);

const Live = () => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const route = useRoute();

  const listRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum
  ] = useState(false);
  const [chevronUpPosition, setChevronUpPosition] = useState(-60);
  const communityUri = route.params?.communityUri;

  const dispatch = useDispatch();
  const {
    events,
    isFetching,
    liveOnTop,
    reload,
    nextPage,
    hasNextPage,
    filter,
    inLiveScreen,
    initialLink,
    user,
    community,
    deeplink
  } = useSelector(getLiveSelector, shallowEqual);
  const data = Object.values(events);

  const getLiveEvents = (page, filter) => {
    dispatch(fetchGetLiveEvents(page, filter));
  };

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevData = usePrevious(data);

  const regex = /^\/([0-9a-zA-Z-]+)\/?(.*)/;

  useEffect(() => {
    if (isNative) {
      dispatch({
        type: HIDE_SPLASH_SCREEN
      });
    }
    if (initialLink) {
      if (
        initialLink &&
        (initialLink.startsWith(`/${communityUri}/`) ||
          (regex.test(initialLink) &&
            AUTHENTICATED_ROUTES.has(initialLink.match(regex)[1])))
      ) {
        try {
          linkTo(initialLink);
        } catch (error) {
          dispatch({
            type: SET_DROP_DOWN_ALERT_ERROR,
            error: `${t("alert:urlerror")} ${initialLink}`
          });
        }
      }
      dispatch({
        type: CLEAR_INITIAL_LINK
      });
    }
    const interval = setInterval(() => {
      dispatch({ type: LIVE_REFRESH });
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (reload > 1) {
      getLiveEvents(1, filter);
    }
  }, [reload]);

  useEffect(() => {
    if (deeplink) {
      const link = getRedirectLink(deeplink);
      if (link && link !== "/" && !link.startsWith("/?")) {
        try {
          linkTo(link);
        } catch (error) {
          dispatch({
            type: SET_DROP_DOWN_ALERT_ERROR,
            error: t("alert:urlerror")
          });
        }
      }
    }
  }, [deeplink]);

  useEffect(() => {
    if (!prevData || data.length > prevData.length) {
      setOnEndReachedCalledDuringMomentum(false);
    }
  }, [prevData, data.length]);

  const renderLiveEvents = ({ item }) => {
    const { _id, groupId } = item;

    switch (groupId) {
      case GROUP_ID_WEATHER: {
        return <LiveWeather key={_id} item={item} />;
      }

      default: {
        // If template doesn't exist, we don't display the event to avoid "Cannot read property 'lang' of undefined
        // if (templateId && !templates[templateId]) {
        //   return null;
        // }
        return <LiveEvent key={_id} item={item} />;
      }
    }
  };

  const handleScroll = useCallback(
    event => {
      const y = event.nativeEvent.contentOffset.y;
      if (y > 140) {
        if (chevronUpPosition === -60) {
          Animated.timing(bottomPosition, {
            toValue: 25,
            duration: 400,
            easing: Easing.linear,
            useNativeDriver: false
          }).start();
          setChevronUpPosition(25);
          if (liveOnTop) {
            dispatch({
              type: LIVE_ON_TOP,
              value: false
            });
          }
        }
      } else if (chevronUpPosition === 25) {
        Animated.timing(bottomPosition, {
          toValue: -60,
          duration: 400,
          easing: Easing.linear,
          useNativeDriver: false
        }).start();
        setChevronUpPosition(-60);
        if (!liveOnTop) {
          dispatch({
            type: LIVE_ON_TOP,
            value: true
          });
        }
      }
    },
    [liveOnTop, chevronUpPosition]
  );

  const _displayLoading = () => {
    if (isFetching) {
      return (
        <View style={styles.loading_container}>
          <ActivityIndicator size="large" color={COLOR2} />
        </View>
      );
    }
  };

  const {
    bgColor1,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    w100p,
    fontBold,
    fs16,
    mt5,
    positionAbsolute,
    bgColor5,
    ph10
  } = commonStyles;

  return (
    <Animated.View style={[flex1, bgColor1]}>
      <LiveFilters />
      <FlatList
        ref={listRef}
        showsVerticalScrollIndicator={false}
        style={[mt5, flex1, w100p]}
        contentContainerStyle={{
          paddingBottom: 90 + Constants.statusBarHeight
        }}
        data={data}
        renderItem={renderLiveEvents}
        keyExtractor={event => event._id}
        onScroll={handleScroll}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (
            hasNextPage &&
            !onEndReachedCalledDuringMomentum &&
            inLiveScreen
          ) {
            setOnEndReachedCalledDuringMomentum(true);
            getLiveEvents(nextPage, filter);
          }
        }}
      />
      {_displayLoading()}

      <ActionButton
        buttonColor={menuOpen ? COLOR5 : COLOR2}
        renderIcon={() => <FontAwesome name="plus" size={40} color="white" />}
        onPressIn={() => setMenuOpen(true)}
        onReset={() => setMenuOpen(false)}
      >
        {((community && community.acceptPosts) ||
          (user &&
            (user.role === ROLE_ADMINISTRATOR ||
              user.role === ROLE_WEBMASTER ||
              user.role === ROLE_EMPLOYEE))) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:createpost")}
            onPress={() => navigation.navigate("PostScreen")}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons
              name="camera-enhance"
              size={34}
              color="white"
            />
          </ActionButton.Item>
        )}
        <ActionButton.Item
          buttonColor={COLOR2}
          title={t("button:needservice")}
          onPress={() => navigation.navigate("SelectProposalScreen")}
          textStyle={[fontBold, fs16]}
        >
          <MaterialCommunityIcons
            // style={{ marginBottom: 15 }}
            name="hand-heart"
            size={30}
            color={"white"}
          />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor={COLOR2}
          title={t("button:instantsharing")}
          onPress={() => navigation.navigate("SelectActivityScreen")}
          textStyle={[fontBold, fs16]}
        >
          <MaterialCommunityIcons
            name="heart-multiple"
            size={36}
            color="white"
          />
        </ActionButton.Item>
        {user &&
          (user.role === ROLE_ADMINISTRATOR ||
            user.role === ROLE_WEBMASTER ||
            user.role === ROLE_EMPLOYEE) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:posttemplate")}
              onPress={() => navigation.navigate("SelectTemplateScreen")}
              textStyle={[fontBold, fs16]}
            >
              <FontAwesome name="newspaper-o" size={36} color="white" />
            </ActionButton.Item>
          )}
      </ActionButton>
      <Animated.View
        style={[
          positionAbsolute,
          {
            alignSelf: "center",
            bottom: bottomPosition
          }
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            listRef?.current?.scrollToOffset({
              x: 0,
              y: 0,
              animated: true
            });
          }}
          style={[
            bgColor5,
            {
              height: 50,
              width: 50,
              borderRadius: 25
            },
            justifyContentCenter,
            alignItemsCenter,
            isWeb && ph10
          ]}
        >
          <Entypo name="chevron-up" color="white" size={45} />
        </TouchableOpacity>
      </Animated.View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  loading_container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 100,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center"
  }
});

const MemoizedLive = memo(Live);
export default MemoizedLive;
