"use strict";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  View
} from "react-native";
import { Avatar } from "react-native-elements";
import { t } from "../services/i18n";
import commonStyles, { SCALE } from "../styles/commonStyles";
import Modal from "react-native-modalbox";
import SafeAreaView from "react-native-safe-area-view";

export default class ConfirmationModal extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  };

  state = {
    disabled: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { showConfirmationModal } = this.props;

    if (showConfirmationModal && !prevProps.showConfirmationModal) {
      this.setState({ disabled: false });
      this.refs.messageModal.open();
    }
    if (!showConfirmationModal && prevProps.showConfirmationModal) {
      this.refs.messageModal.close();
    }
  }

  render() {
    const {
      color5,
      orange,
      white,
      bgOrange,
      bgLightgrey,
      bgColor1,
      row,
      flex1,
      justifyContentCenter,
      alignItemsCenter,
      mb10,
      mv10,
      mh10,
      p5,
      pr10,
      ph20,
      font,
      fontBold,
      fs16,
      rounded30,
      roundedBottom30,
      h60,
      overflowHidden
    } = commonStyles;
    const {
      description,
      onClosed,
      title,
      onConfirmation,
      buttonConfirm = t("button:remove"),
      buttonColor = "red"
    } = this.props;
    return (
      <Modal
        backdrop={true}
        backdropOpacity={0.7}
        backdropColor="black"
        position="center"
        style={[rounded30, { height: 280, width: 350 }]}
        ref={"messageModal"}
        // swipeToClose={true}
        backdropPressToClose={false}
        onClosed={onClosed}
      >
        <SafeAreaView style={flex1}>
          <View style={[mv10, flex1]}>
            <View style={flex1}>
              <View
                style={[
                  bgLightgrey,
                  row,
                  h60,
                  rounded30,
                  alignItemsCenter,
                  pr10,
                  mb10,
                  mh10
                ]}
              >
                <Avatar
                  rounded
                  size={60}
                  overlayContainerStyle={bgOrange}
                  icon={{ name: "exclamation-triangle", type: "font-awesome" }}
                  activeOpacity={0.7}
                />
                <View contentContainerStyle={[justifyContentCenter, flex1]}>
                  <Text style={[fontBold, { fontSize: 16 }, orange, p5]}>
                    {title}
                  </Text>
                </View>
              </View>
              <ScrollView style={mh10}>
                <Text style={[font]}>{description}</Text>
              </ScrollView>
            </View>
          </View>
          <View style={[row, roundedBottom30, overflowHidden]}>
            <TouchableHighlight
              underlayColor="transparent"
              activeOpacity={0.8}
              onPress={() => this.refs.messageModal.close()}
            >
              <View
                style={[
                  styles.modalButton,
                  ph20,
                  alignItemsCenter,
                  justifyContentCenter,
                  bgColor1
                ]}
              >
                <Text style={[fs16, fontBold, color5]}>
                  {t("button:cancel")}
                </Text>
              </View>
            </TouchableHighlight>
            <TouchableHighlight
              underlayColor="transparent"
              activeOpacity={0.8}
              onPress={() =>
                this.setState({ disabled: true }, () => onConfirmation())
              }
              disabled={this.state.disabled}
            >
              <View
                style={[
                  styles.modalButton,
                  styles.confirmButton,
                  ph20,
                  alignItemsCenter,
                  justifyContentCenter,
                  { backgroundColor: buttonColor }
                ]}
              >
                <Text style={[fontBold, fs16, white]}>{buttonConfirm}</Text>
              </View>
            </TouchableHighlight>
          </View>
        </SafeAreaView>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modalButton: {
    height: 60,
    width: 350 / 2
  },
  confirmButton: {
    borderLeftWidth: 2 / SCALE,
    borderLeftColor: "white"
  }
});
