import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { memo, useEffect } from "react";
import { Image, Linking } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { fetchCheckVersionAuthenticated } from "../actions";
import { fetchGetCommunityDetails } from "../actions/community";
import {
  SET_INITIAL_LINK,
  SET_INITIAL_URL,
  SET_INSTALLATION_ID
} from "../actions/types";
import { getImage } from "../assets/Images";
import { ALL_ROUTES, APP_NAME } from "../config/constants";
import { getLoadingAppSelector } from "../selectors";
import commonStyles from "../styles/commonStyles";
import { getInstallationId } from "../utils/InstallationId";
import { getRedirectLink } from "../utils/UrlUtil";

const LoadingScreen = () => {
  const dispatch = useDispatch();

  const {
    initialUrl,
    clientVersion,
    serverVersion,
    currentCommunity,
    isNotFound
  } = useSelector(getLoadingAppSelector);

  const regex = /^\/([0-9a-zA-Z-]+)\/?(.*)/;

  const getCurrentCommunity = (link, communityUri) => {
    if (regex.test(link) && !ALL_ROUTES.has(link.match(regex)[1])) {
      dispatch(fetchGetCommunityDetails(link.match(regex)[1]));
    } else {
      dispatch(fetchGetCommunityDetails(communityUri));
    }
  };

  useEffect(() => {
    (async () => {
      let installationId = await AsyncStorage.getItem(
        `${APP_NAME}InstallationId`
      );
      if (!installationId) {
        installationId = await getInstallationId();
        await AsyncStorage.setItem(`${APP_NAME}InstallationId`, installationId);
      }
      if (installationId) {
        dispatch({
          type: SET_INSTALLATION_ID,
          value: installationId
        });
      }
      const communityUri = await AsyncStorage.getItem("neighborhoodUri");
      if (!initialUrl) {
        const url = await Linking.getInitialURL();
        dispatch({
          type: SET_INITIAL_URL,
          value: url
        });
        const link = getRedirectLink(url) || "/";
        dispatch({
          type: SET_INITIAL_LINK,
          value: link
        });
        getCurrentCommunity(link, communityUri);
      }
    })();
  }, []);

  useEffect(() => {
    if (isNotFound) {
      dispatch(fetchGetCommunityDetails());
    }
  }, [isNotFound]);

  useEffect(() => {
    if (currentCommunity && !clientVersion) {
      if (serverVersion) {
        // Workaround: wait a while before checking authentication
        // if we logout and call fetchCheckVersionAuthenticated quickly, the user session will be reused
        setTimeout(
          () => dispatch(fetchCheckVersionAuthenticated(currentCommunity._id)),
          1000
        );
      } else {
        dispatch(fetchCheckVersionAuthenticated(currentCommunity._id));
      }
    }
  }, [currentCommunity, clientVersion]);

  const { cover, w100p, h100p } = commonStyles;
  return <Image style={[cover, w100p, h100p]} source={getImage("splash")} />;
};

const MemoizedLoadingScreen = memo(LoadingScreen);
export default MemoizedLoadingScreen;
