import { Entypo } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import { useLinkTo } from "@react-navigation/native";
import Constants from "expo-constants";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Easing,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchGetAds } from "../actions/ad";
import {
  ADS_ON_TOP,
  CLEAR_SCAN,
  HIDE_QR_CODE_SCANNER_MODAL
} from "../actions/types";
import AdItem from "../components/AdItem";
import AdsFilters from "../components/AdsFilters";
import { getAdReducer, getUrlscanReducer } from "../selectors";
import commonStyles, { COLOR2, isWeb } from "../styles/commonStyles";
import QrCodeScannerModal from "./QrCodeScannerModal";

const bottomPosition = new Animated.Value(-60);

const getSelector = createSelector(
  [getAdReducer, getUrlscanReducer],
  (
    {
      isFetching,
      nextPage,
      hasNextPage,
      ad,
      ads,
      reload,
      filter,
      adsOnTop,
      inAdsScreen
    },
    { link }
  ) => ({
    isFetching,
    nextPage,
    hasNextPage,
    ad,
    ads,
    reload,
    filter,
    adsOnTop,
    inAdsScreen,
    link
  })
);

const Ads = () => {
  const dispatch = useDispatch();
  const listRef = useRef();
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum
  ] = useState(false);
  const [chevronUpPosition, setChevronUpPosition] = useState(-60);

  let {
    isFetching,
    nextPage,
    hasNextPage,
    ad,
    ads,
    reload,
    filter,
    adsOnTop,
    inAdsScreen,
    link
  } = useSelector(getSelector, shallowEqual);

  filter = new Set(filter);
  ads = Object.values(ads);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevAds = usePrevious(ads);

  useEffect(() => {
    if (!prevAds || ads.length > prevAds.length) {
      setOnEndReachedCalledDuringMomentum(false);
    }
  }, [prevAds, ads.length]);

  useEffect(() => {
    if (reload) {
      dispatch(fetchGetAds(1, filter.size ? Array.from(filter) : null));
    }
  }, [reload]);

  useEffect(() => {
    if (link) {
      linkTo(link);
      dispatch({
        type: CLEAR_SCAN
      });
    }
  }, [link]);

  useEffect(() => {
    if (ad && inAdsScreen) {
      const { cityUri, customUri: adUri } = ad;
      navigation.navigate("AdScreen", {
        cityUri,
        adUri
      });
    }
  }, [ad]);

  const _displayLoading = () => {
    if (isFetching) {
      return (
        <View style={styles.loading_container}>
          <ActivityIndicator size="large" color={COLOR2} />
        </View>
      );
    }
  };

  const handleScroll = useCallback(
    event => {
      const y = event.nativeEvent.contentOffset.y;
      if (y > 140) {
        if (chevronUpPosition === -60) {
          Animated.timing(bottomPosition, {
            toValue: 25,
            duration: 400,
            easing: Easing.linear,
            useNativeDriver: false
          }).start();
          setChevronUpPosition(25);
          if (adsOnTop) {
            dispatch({
              type: ADS_ON_TOP,
              value: false
            });
          }
        }
      } else if (chevronUpPosition === 25) {
        Animated.timing(bottomPosition, {
          toValue: -60,
          duration: 400,
          easing: Easing.linear,
          useNativeDriver: false
        }).start();
        setChevronUpPosition(-60);
        if (!adsOnTop) {
          dispatch({
            type: ADS_ON_TOP,
            value: true
          });
        }
      }
    },
    [adsOnTop, chevronUpPosition]
  );

  const onQrCodeScannerModalClosed = () => {
    dispatch({
      type: HIDE_QR_CODE_SCANNER_MODAL
    });
  };

  const {
    bgColor1,
    bgColor5,
    alignItemsCenter,
    justifyContentCenter,
    ph10,
    positionAbsolute
  } = commonStyles;

  return (
    <Animated.View style={[StyleSheet.absoluteFillObject, bgColor1]}>
      <QrCodeScannerModal onClosed={onQrCodeScannerModalClosed} />
      <AdsFilters />
      <FlatList
        ref={listRef}
        showsVerticalScrollIndicator={false}
        data={ads}
        contentContainerStyle={{
          paddingBottom: 90 + Constants.statusBarHeight
        }}
        renderItem={({ item }) => <AdItem item={item} />}
        keyExtractor={item => item._id}
        onScroll={handleScroll}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (hasNextPage && !onEndReachedCalledDuringMomentum && inAdsScreen) {
            setOnEndReachedCalledDuringMomentum(true);
            dispatch(
              fetchGetAds(nextPage, filter.size ? Array.from(filter) : null)
            );
          }
        }}
      />
      {_displayLoading()}
      <Animated.View
        style={[
          positionAbsolute,
          {
            alignSelf: "center",
            bottom: bottomPosition
          }
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            listRef.current.scrollToOffset({
              x: 0,
              y: 0,
              animated: true
            });
          }}
          style={[
            bgColor5,
            {
              height: 50,
              width: 50,
              borderRadius: 25
            },
            justifyContentCenter,
            alignItemsCenter,
            isWeb && ph10
          ]}
        >
          <Entypo name="chevron-up" color="white" size={45} />
        </TouchableOpacity>
      </Animated.View>
    </Animated.View>
  );
};

const MemoizedAds = memo(Ads);
export default MemoizedAds;

const styles = StyleSheet.create({
  loading_container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 100,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center"
  }
});
