import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SplashScreen from "expo-splash-screen";
import React, { memo, useEffect, useState } from "react";
import { View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchLogout } from "../actions";
import {
  CLEAR_DROP_DOWN_ALERT_ERROR,
  CLEAR_DROP_DOWN_ALERT_INFO,
  CLEAR_DROP_DOWN_ALERT_MESSAGE,
  CLEAR_DROP_DOWN_ALERT_WARN,
  CLEAR_LOGOUT,
  RESET_DATA
} from "../actions/types";
import { getErrorManagementSelector } from "../selectors";
import { t } from "./i18n";

const ErrorManagement = props => {
  const dispatch = useDispatch();
  const [splashScreenClosed, setSplashScreenClosed] = useState(false);

  let {
    isMaintenance,
    dropDownAlertInfo,
    dropDownAlertError,
    dropDownAlertMessage,
    dropDownAutoClearAlertError,
    dropDownAutoClearAlertWarn,
    dropDownAutoClearAlertInfo,
    splashScreenHide,
    errorlogout,
    logout,
    logoutResult,
    dropDownAlertInfoGI,
    dropDownAlertWarn,
    dropDownAlertErrorGI
  } = useSelector(getErrorManagementSelector, shallowEqual);

  logout = logout || errorlogout;
  dropDownAlertInfo = dropDownAlertInfo || dropDownAlertInfoGI;
  dropDownAlertError = dropDownAlertError || dropDownAlertErrorGI;
  const { navigationRef, dropDownHolder } = props;

  const navigation = navigationRef.current;

  useEffect(() => {
    if (
      (splashScreenHide || dropDownAlertError || dropDownAlertWarn) &&
      !splashScreenClosed
    ) {
      (async () => {
        setSplashScreenClosed(true);
        await SplashScreen.hideAsync();
      })();
    }
  }, [
    splashScreenHide,
    splashScreenClosed,
    dropDownAlertWarn,
    dropDownAlertError
  ]);

  useEffect(() => {
    if (isMaintenance) {
      navigation?.navigate("MaintenanceScreen");
    }
  }, [isMaintenance]);

  useEffect(() => {
    if (logout) {
      dispatch(fetchLogout());
    }
  }, [logout]);

  useEffect(() => {
    if (logoutResult) {
      dispatch({
        type: RESET_DATA
      });

      dispatch({
        type: CLEAR_LOGOUT
      });
    }
  }, [logoutResult]);

  useEffect(() => {
    // Cas des erreurs
    if (dropDownAutoClearAlertError) {
      dropDownHolder?.alertWithType(
        "error",
        t("alert:error"),
        t("login:" + dropDownAutoClearAlertError)
      );

      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_ERROR
      });
    }
  }, [dropDownAutoClearAlertError]);

  useEffect(() => {
    if (dropDownAlertError) {
      AsyncStorage.removeItem("REDIRECT_LINK");
      dropDownHolder?.alertWithType(
        "error",
        t("alert:error"),
        t("login:" + dropDownAlertError)
      );
    }
  }, [dropDownAlertError]);

  useEffect(() => {
    if (dropDownAlertInfo) {
      dropDownHolder?.alertWithType(
        "info",
        t("alert:info"),
        t("login:" + dropDownAlertInfo)
      );
    }
  }, [dropDownAlertInfo]);

  useEffect(() => {
    if (dropDownAlertWarn) {
      dropDownHolder?.alertWithType(
        "warn",
        t("alert:warn"),
        t("login:" + dropDownAlertWarn),
        {
          dropDownAlertWarn
        },
        Number.MAX_VALUE
      );
    }
  }, [dropDownAlertWarn]);

  useEffect(() => {
    if (dropDownAutoClearAlertWarn) {
      dropDownHolder?.alertWithType(
        "warn",
        t("alert:warn"),
        t("login:" + dropDownAutoClearAlertWarn),
        {
          dropDownAutoClearAlertWarn
        }
      );
      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_WARN
      });
    }
  }, [dropDownAutoClearAlertWarn]);

  useEffect(() => {
    if (dropDownAutoClearAlertInfo) {
      dropDownHolder?.alertWithType(
        "info",
        t("alert:info"),
        t("login:" + dropDownAutoClearAlertInfo),
        {
          dropDownAutoClearAlertInfo
        }
      );
      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_INFO
      });
    }
  }, [dropDownAutoClearAlertInfo]);

  useEffect(() => {
    if (dropDownAlertMessage) {
      dropDownHolder?.alertWithType(
        "custom",
        dropDownAlertMessage.title,
        dropDownAlertMessage.message,
        dropDownAlertMessage
      );

      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_MESSAGE
      });
    }
  }, [dropDownAlertMessage]);

  // Empty view
  return <View />;
};

const MemoizedErrorManagement = memo(ErrorManagement);

export default MemoizedErrorManagement;
