import {
  API_START,
  API_END,
  FETCH_GET_ADS,
  SET_ADS_RESULT,
  WS_CONNECTED,
  WS_EVENT,
  ADD_GOODDEAL,
  DELETE_GOODDEAL,
  UPDATE_GOODDEAL,
  ADS_ON_TOP,
  RESET_DATA,
  CATEGORIES_FILTER,
  SET_AD_LOCATION_RESULT,
  FETCH_GET_AD_LOCATION,
  NAVIGATION,
  SET_GOODDEAL_DISPLAY_FILTER_OPTION,
  FETCH_GET_AD_DETAILS,
  SET_AD_DETAILS_RESULT,
  CLEAR_SHARE,
  SHARE,
  CLEAR_AD,
  SET_UPDATE_AD_MAP_PHOTO_URI_RESULT
} from "../actions/types";
import merge from "lodash/merge";
import { adSchema } from "../actions/ad";
import { normalize } from "normalizr";

const initialState = {
  isFetching: false,
  ads: {},
  adsLocations: null,
  page: 1,
  hasNextPage: false,
  nextPage: 2,
  totalPages: 0,
  totalDocs: 0,
  reload: 0,
  inAdsScreen: false,
  adsOnTop: true,
  cptNewAds: 0,
  filter: [],
  displayFilterOptions: false,
  ad: null,
  share: false
};

export default function adReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (
        action.payload === FETCH_GET_ADS ||
        action.payload === FETCH_GET_AD_DETAILS
      ) {
        return {
          ...state,
          isFetching: true,
          ad: null,
          share: false
        };
      }
      if (action.payload === FETCH_GET_AD_LOCATION) {
        return {
          ...state,
          isFetching: true,
          adsLocations: null
        };
      }
      break;

    case RESET_DATA:
      return {
        ...initialState
      };

    case CLEAR_AD:
      return {
        ...state,
        ad: null
      };

    case CLEAR_SHARE:
      return {
        ...state,
        share: false
      };

    case SHARE:
      return {
        ...state,
        share: true
      };

    case SET_UPDATE_AD_MAP_PHOTO_URI_RESULT:
    case SET_AD_DETAILS_RESULT:
      return {
        ...state,
        ad: action.payload
      };

    case SET_GOODDEAL_DISPLAY_FILTER_OPTION:
      return {
        ...state,
        displayFilterOptions: action.payload
      };

    case CATEGORIES_FILTER:
      return {
        ...state,
        filter: action.payload
      };

    case SET_ADS_RESULT: {
      const { pagesinfos, ads } = action.entities;
      const { page, hasNextPage, totalPages, totalDocs } = pagesinfos["1"];

      if (page === 1) {
        return {
          ...state,
          ads: ads ? ads : {},
          page,
          nextPage: 1 + page,
          hasNextPage,
          totalPages,
          totalDocs
        };
      } else {
        return merge({}, state, {
          ads,
          page,
          nextPage: 1 + page,
          hasNextPage,
          totalPages,
          totalDocs
        });
      }
    }

    case SET_AD_LOCATION_RESULT:
      return {
        ...state,
        adsLocations: action.payload
      };

    case ADS_ON_TOP:
      return {
        ...state,
        adsOnTop: action.value,
        cptNewAds: action.value ? 0 : state.cptNewAds
      };

    case NAVIGATION:
      if (action.name === "AdsScreen" || action.name === "AdsScreens") {
        return {
          ...state,
          inAdsScreen: true,
          cptNewAds: state.adsOnTop ? 0 : state.cptNewAds
        };
      } else {
        return {
          ...state,
          inAdsScreen: false
        };
      }

    case WS_EVENT:
      {
        if (action.payload.type === ADD_GOODDEAL) {
          const normalized = normalize(action.payload, adSchema);
          const { ads } = normalized.entities;
          return merge(
            {},
            {
              ads,
              cptNewAds:
                state.inAdsScreen && state.adsOnTop ? 0 : ++state.cptNewAds
            },
            state
          );
        } else if (action.payload.type === UPDATE_GOODDEAL) {
          // remove ad id from state
          let { [action.payload._id]: value, ...withoutSecond } = state.ads;
          const normalized = normalize(action.payload, adSchema);
          const { ads } = normalized.entities;
          return {
            ...state,
            ads: { ...ads, ...withoutSecond },
            cptNewAds:
              state.inAdsScreen && state.adsOnTop ? 0 : ++state.cptNewAds
          };
        } else if (action.payload.type === DELETE_GOODDEAL) {
          const { [action.payload.id]: value, ...withoutSecond } = state.ads;
          // When a record is removed, we stay in the same page
          return {
            ...state,
            ads: { ...withoutSecond }
          };
        }
      }
      break;

    // Issue: if we reload ads when we reconnect, issue when we are in adDetails screen
    // case WS_CONNECTED:
    //   return {
    //     ...state,
    //     reload: ++state.reload
    //   };

    case API_END:
      if (
        action.payload === FETCH_GET_ADS ||
        action.payload === FETCH_GET_AD_LOCATION ||
        action.payload === FETCH_GET_AD_DETAILS
      ) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
