import React, { memo, useCallback, useState, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { Tile } from "react-native-elements";
import { getYoutubeMeta } from "react-native-youtube-iframe";
import commonStyles, { SCREEN_WIDTH, COLOR2 } from "../styles/commonStyles";

const WIDTH = (200 * 16) / 9;
const width = WIDTH > SCREEN_WIDTH - 70 ? SCREEN_WIDTH - 70 : WIDTH;

const Youtube = ({ youtubeVideoId }) => {
  const [videoMeta, setVideoMeta] = useState(null);
  const navigation = useNavigation();

  const {
    h200,
    roundedTop5,
    overflowHidden,
    alignItemsCenter,
    justifyContentCenter
  } = commonStyles;

  useEffect(() => {
    (async () => {
      if (youtubeVideoId) {
        setVideoMeta(await getYoutubeMeta(youtubeVideoId));
      }
    })();
  }, [youtubeVideoId]);

  return (
    <Tile
      activeOpacity={0.5}
      containerStyle={[{ width }, h200, roundedTop5, overflowHidden]}
      imageSrc={{ uri: videoMeta?.thumbnail_url }}
      imageContainerStyle={[roundedTop5, { width }, h200]}
      icon={{
        name: "play-circle",
        type: "font-awesome",
        size: 50,
        color: COLOR2
      }}
      onPress={() => {
        navigation.navigate("YoutubeScreen", {
          videoId: youtubeVideoId
        });
      }}
      featured
    />
  );
};

const YoutubeView = memo(Youtube);

export default YoutubeView;
