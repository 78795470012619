import AsyncStorage from "@react-native-async-storage/async-storage";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import { BlurView } from "expo-blur";
import React, { memo, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import {
  Image,
  ImageBackground,
  StatusBar,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import SafeAreaView from "react-native-safe-area-view";
import Swiper from "react-native-swiper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchCheckVersion } from "../actions";
import { fetchGetCommunityDetails } from "../actions/community";
import {
  CLEAR_INITIAL_LINK,
  CLEAR_SCAN,
  HIDE_QR_CODE_SCANNER_MODAL,
  HIDE_SPLASH_SCREEN,
  SET_DROP_DOWN_ALERT_ERROR,
  SET_DROP_DOWN_ALERT_WARN,
  SHOW_QR_CODE_SCANNER_MODAL
} from "../actions/types";
import { getImage, getImageUri } from "../assets/Images";
import {
  ALL_ROUTES,
  APPLE_STORE_URL,
  GOOGLE_PLAY_URL,
  SLOGAN
} from "../config/constants";
import { getLandingSelector } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  DARKGREY_COLOR,
  isAndroid,
  isElectron,
  isNative,
  isSafari,
  SCREEN_HEIGHT
} from "../styles/commonStyles";
import { getRedirectLink, openURL } from "../utils/UrlUtil";
import QrCodeScannerModal from "./QrCodeScannerModal";

const LandingScreen = () => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  // const route = useRoute();
  const navigation = useNavigation();

  const tooltip0Ref = useRef();
  const tooltip1Ref = useRef();
  const tooltip2Ref = useRef();
  const tooltip3Ref = useRef();

  // const registerkey = route?.params?.register;
  let {
    accessDenied,
    dropDownAlertInfoGI,
    dropDownAlertErrorGI,
    initialLink,
    dropDownAlertError,
    dropDownAlertInfo,
    link,
    currentCommunity,
    deeplink,
    showLoginModal,
    isLandingScreen
  } = useSelector(getLandingSelector, shallowEqual);

  dropDownAlertError = dropDownAlertErrorGI || dropDownAlertError;
  dropDownAlertInfo = dropDownAlertInfoGI || dropDownAlertInfo;

  useEffect(() => {
    (async () => {
      if (isNative) {
        dispatch({
          type: HIDE_SPLASH_SCREEN
        });
      }
      const hideTooltips = await AsyncStorage.getItem("hideTooltips");
      if (!hideTooltips && !(isAndroid && __DEV__)) {
        setTimeout(() => tooltip0Ref?.current?.toggleTooltip(), 500);
      }
    })();
  }, []);

  useEffect(() => {
    if (showLoginModal && currentCommunity) {
      navigateLoginModal();
    }
  }, [showLoginModal, currentCommunity]);

  useEffect(() => {
    if (initialLink && !isElectron) {
      if (initialLink && initialLink !== "/" && !initialLink.startsWith("/?")) {
        try {
          linkTo(initialLink);
        } catch (error) {
          dispatch({
            type: SET_DROP_DOWN_ALERT_ERROR,
            error: `${t("alert:urlerror")} ${initialLink}`
          });
        }
      }

      dispatch({
        type: CLEAR_INITIAL_LINK
      });
    }
  }, [initialLink]);

  useEffect(() => {
    if (deeplink) {
      const link = getRedirectLink(deeplink);
      const regex = /^\/([0-9a-zA-Z-]+)$/;
      if (link) {
        if (regex.test(link) && !ALL_ROUTES.has(link.match(regex)[1])) {
          dispatch(fetchGetCommunityDetails(link.match(regex)[1]));
        } else {
          linkTo(link);
        }
      }
    }
  }, [deeplink]);

  useEffect(() => {
    if (link) {
      const regex = /^\/([0-9a-zA-Z-]+)$/;
      if (regex.test(link) && !ALL_ROUTES.has(link.match(regex)[1])) {
        dispatch(fetchGetCommunityDetails(link.match(regex)[1]));
      } else {
        linkTo(link);
      }

      dispatch({
        type: CLEAR_SCAN
      });
    }
  }, [link]);

  // 1: ACCESS DENIED => Retrieve versions
  useEffect(() => {
    if (accessDenied) {
      dispatch(fetchCheckVersion());
    }
  }, [accessDenied]);

  const navigateLoginModal = () => {
    if (currentCommunity) {
      navigation.navigate("LoginModal");
    } else {
      dispatch({
        type: SET_DROP_DOWN_ALERT_WARN,
        warn: "selectcommunity"
      });
    }
  };

  const {
    bgColor1,
    bgColor2,
    darkgrey,
    white,
    flex1,
    fs18,
    fs25,
    font,
    fontBold,
    underline,
    p20,
    justifyContentCenter,
    alignItemsCenter,
    positionAbsolute,
    w100p,
    cover,
    rounded22,
    textCenter,
    mb20,
    mv20,
    m10,
    overflowHidden,
    opacity08
  } = commonStyles;

  return (
    <SafeAreaView style={[flex1, bgColor1]}>
      <StatusBar barStyle="dark-content" />
      <QrCodeScannerModal
        onClosed={() =>
          dispatch({
            type: HIDE_QR_CODE_SCANNER_MODAL
          })
        }
      />
      <View style={[isSafari && { minHeight: SCREEN_HEIGHT / 2 }, flex1]}>
        <ImageBackground
          source={getImageUri("building")}
          style={[flex1, cover]}
        >
          <View style={flex1}>
            <TouchableOpacity
              style={[
                flex1,
                isNative && alignItemsCenter,
                isNative && justifyContentCenter
              ]}
              onPress={() => navigation.navigate("AppInfosScreen")}
            >
              <Image
                style={[flex1, m10]}
                source={getImage("logo")}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <BlurView intensity={25} tint="light" style={flex1}>
            <TouchableOpacity
              style={[alignItemsCenter, { marginBottom: 50 }]}
              onPress={() => navigation.navigate("CommunitiesModal")}
            >
              <Text style={[fontBold, darkgrey, mv20, fs25, textCenter]}>
                {currentCommunity?.name}
              </Text>
              {isLandingScreen && (
                <Tooltip
                  ref={tooltip0Ref}
                  popover={
                    <Text style={[font, white]}>{t("login:tooltip0")}</Text>
                  }
                  backgroundColor={DARKGREY_COLOR}
                  withOverlay={true}
                  toggleOnPress={false}
                  height={80}
                  width={200}
                  onClose={() => {
                    if (isNative || isMobile) {
                      tooltip1Ref?.current?.toggleTooltip();
                    } else {
                      tooltip2Ref?.current?.toggleTooltip();
                    }
                  }}
                  skipAndroidStatusBar
                >
                  <View>
                    <Text style={[fontBold, fs18, mb20, white, underline]}>
                      {t("login:choosecommunity")}
                    </Text>
                  </View>
                </Tooltip>
              )}
            </TouchableOpacity>
          </BlurView>
        </ImageBackground>
        {(isNative || isMobile) && (
          <TouchableOpacity
            style={[
              positionAbsolute,
              {
                left: 13,
                bottom: 10
              }
            ]}
            onPress={() =>
              dispatch({
                type: SHOW_QR_CODE_SCANNER_MODAL
              })
            }
          >
            {isLandingScreen && (
              <Tooltip
                ref={tooltip1Ref}
                popover={
                  <Text style={[font, white]}>{t("login:tooltip1")}</Text>
                }
                backgroundColor={DARKGREY_COLOR}
                withOverlay={true}
                toggleOnPress={false}
                height={100}
                width={240}
                onClose={() => {
                  tooltip2Ref?.current?.toggleTooltip();
                }}
                skipAndroidStatusBar
              >
                <Icon
                  raised
                  reverse
                  name="qrcode-scan"
                  color={COLOR2}
                  reverseColor="white"
                  type="material-community"
                  size={28}
                />
              </Tooltip>
            )}
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={[
            positionAbsolute,
            {
              right: 13,
              bottom: 10
            }
          ]}
          onPress={() => navigation.navigate("CommunitiesMapModal")}
        >
          {isLandingScreen && (
            <Tooltip
              ref={tooltip2Ref}
              backgroundColor={DARKGREY_COLOR}
              withOverlay={true}
              toggleOnPress={false}
              height={80}
              width={200}
              popover={<Text style={[font, white]}>{t("login:tooltip2")}</Text>}
              onClose={() => {
                tooltip3Ref?.current?.toggleTooltip();
              }}
              skipAndroidStatusBar
            >
              <Icon
                raised
                reverse
                name="map-search-outline"
                color={COLOR2}
                reverseColor="white"
                type="material-community"
                size={28}
              />
            </Tooltip>
          )}
        </TouchableOpacity>
      </View>
      <View style={[flex1, p20]}>
        <Swiper
          loop={true}
          activeDotColor={COLOR2}
          dotColor={"rgba(255, 255, 255, 0.4)"}
          containerStyle={{
            marginBottom: 20,
            flex: 1,
            borderRadius: 22,
            backgroundColor: "white"
          }}
          controlsProps={{
            dotActiveStyle: { backgroundColor: COLOR2 },
            prevTitle: t("button:previous"),
            nextTitle: t("button:next"),
            prevTitleStyle: { color: COLOR2, fontFamily: "fontBold" },
            nextTitleStyle: { color: COLOR2, fontFamily: "fontBold" }
          }}
        >
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("sharelife")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
              imageStyle={{ opacity: 0.9 }}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {SLOGAN}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("help")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
            >
              <Text
                style={[fontBold, white, fs25, textCenter, mb20, opacity08]}
              >
                {t("login:help")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("jogging")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20,
                {
                  backgroundColor: "rgba(0, 0, 0, 0.1)"
                }
              ]}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {t("login:find")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("tools")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {t("login:share")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("vegetables")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {t("login:localeconomy")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("building")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
            >
              <TouchableOpacity
                style={alignItemsCenter}
                onPress={() =>
                  Platform.OS === "ios"
                    ? openURL(dispatch, APPLE_STORE_URL)
                    : openURL(dispatch, GOOGLE_PLAY_URL)
                }
              >
                <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                  {t("login:like")}
                </Text>
                <Text style={[fontBold, fs18, white, underline]}>
                  {t("login:rate")}
                </Text>
              </TouchableOpacity>
            </ImageBackground>
          </View>
        </Swiper>
        {isLandingScreen && (
          <Tooltip
            ref={tooltip3Ref}
            popover={
              <Text style={[font, white]}>{t("login:tooltipextra")}</Text>
            }
            backgroundColor={DARKGREY_COLOR}
            withOverlay={true}
            toggleOnPress={false}
            height={80}
            width={220}
            skipAndroidStatusBar
          >
            <Button
              buttonStyle={[bgColor2]}
              containerStyle={[w100p]}
              activeOpacity={0.8}
              title={t("login:join")}
              onPress={navigateLoginModal}
              titleStyle={[white, font]}
            />
          </Tooltip>
        )}
      </View>
    </SafeAreaView>
  );
};

const MemoizedLandingScreen = memo(LandingScreen);
export default MemoizedLandingScreen;
