import {
  FETCH_GET_CURRENT_METEO,
  FETCH_GET_FORECAST_METEO,
  SET_CURRENT_METEO,
  SET_FORECAST_METEO,
  FETCH_FAILURE,
  FETCH_SHARE_METEO,
  SET_SHARE_METEO_RESULT,
  SET_SHARE_METEO_FAILURE
} from "./types";
import { apiAction } from "./api";

export function fetchGetForecastMeteo() {
  return apiAction({
    url: "/weather/forecast",
    method: "GET",
    onSuccess: setForecastMeteo,
    onFailure: setFetchFailure,
    label: FETCH_GET_FORECAST_METEO
  });
}

function setForecastMeteo(data) {
  return {
    type: SET_FORECAST_METEO,
    payload: data
  };
}

export function fetchGetCurrentMeteo() {
  return apiAction({
    url: "/weather/current",
    method: "GET",
    onSuccess: setCurrentMeteo,
    onFailure: setFetchFailure,
    label: FETCH_GET_CURRENT_METEO
  });
}

function setCurrentMeteo(data) {
  return {
    type: SET_CURRENT_METEO,
    payload: data
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}

export function fetchShareMeteo(files) {
  return apiAction({
    url: "/weather/share",
    method: "POST",
    data: { files },
    onSuccess: setShareMeteoResult,
    onFailure: setShareMeteoFailure,
    label: FETCH_SHARE_METEO
  });
}

function setShareMeteoResult(data) {
  return {
    type: SET_SHARE_METEO_RESULT,
    payload: data
  };
}

function setShareMeteoFailure(error) {
  return {
    type: SET_SHARE_METEO_FAILURE,
    payload: error
  };
}
