import {
  SET_HAS_CAMERA_PERMISSION,
  CLEAR_SCAN,
  SET_SCANNED_DATA,
  HIDE_QR_CODE_SCANNER_MODAL,
  SHOW_QR_CODE_SCANNER_MODAL
} from "../actions/types";

import { PWA_DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME } from "../config/constants";

const initialState = {
  hasCameraPermission: null,
  scanned: false,
  isUnknownDeepLink: false,
  showQrCodeScannerModal: false,
  link: null
};

export default function urlscanReducer(state = initialState, action = {}) {
  switch (action.type) {
    case HIDE_QR_CODE_SCANNER_MODAL:
      return {
        ...state,
        showQrCodeScannerModal: false
      };

    case SHOW_QR_CODE_SCANNER_MODAL:
      return {
        ...state,
        showQrCodeScannerModal: true
      };

    case CLEAR_SCAN:
      return {
        ...state,
        scanned: false,
        isUnknownDeepLink: false,
        link: null
      };

    case SET_HAS_CAMERA_PERMISSION:
      return {
        ...state,
        hasCameraPermission: action.payload
      };

    case SET_SCANNED_DATA:
      const { data: url } = action.payload;
      if (
        !url ||
        (!url.startsWith(PWA_DEEPLINK_APP_URL) &&
          !url.startsWith(DEEPLINK_URL_SCHEME))
      ) {
        return {
          ...state,
          scanned: true,
          isUnknownDeepLink: true
        };
      } else if (url.startsWith(PWA_DEEPLINK_APP_URL)) {
        const link = url.substring(PWA_DEEPLINK_APP_URL.length);
        return {
          ...state,
          scanned: true,
          link: link ? link : "/",
          showQrCodeScannerModal: false
        };
      } else {
        const link = url.substring(DEEPLINK_URL_SCHEME.length);

        return {
          ...state,
          scanned: true,
          link: link ? link : "/",
          showQrCodeScannerModal: false
        };
      }

    default:
      return state;
  }
}
