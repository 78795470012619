import React, { Fragment, memo, useEffect } from "react";
import { LayoutAnimation, Text, View } from "react-native";
import { CheckBox } from "react-native-elements/dist/checkbox/CheckBox";
import { fetchGetLiveEvents } from "../actions/live";
import { useDispatch, useSelector } from "react-redux";
import {
  DISPLAY_ALL,
  DISPLAY_ONLY_COMMUNITY_POSTS,
  DISPLAY_ONLY_MY_POSTS,
  SET_DISPLAY_FILTER_OPTION,
  SET_FILTER
} from "../actions/types";
import { COMMUNITY } from "../config/constants";
import commonStyles, { COLOR2 } from "../styles/commonStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { t } from "../services/i18n";

const LiveFilters = () => {
  const dispatch = useDispatch();
  const { page, filter, displayFilterOptions } = useSelector(
    state => state.liveReducer
  );

  const {
    fontBold,
    color5,
    darkgrey,
    bgWhite,
    mb10,
    ml10,
    pv10,
    w100p,
    shadowGrey
  } = commonStyles;

  const getLiveEvents = (page, filter) => {
    dispatch(fetchGetLiveEvents(page, filter));
  };

  const hideFilter = () => {
    LayoutAnimation.easeInEaseOut();
    dispatch({
      type: SET_DISPLAY_FILTER_OPTION,
      payload: false
    });
  };

  const switchCheckbox = filter => {
    setTimeout(hideFilter, 1000);
    dispatch({
      type: SET_FILTER,
      payload: filter ? filter : DISPLAY_ALL
    });
    dispatch({
      type: SET_DISPLAY_FILTER_OPTION,
      payload: filter && filter !== DISPLAY_ALL
    });
  };

  useEffect(() => {
    (async () => {
      let filter = await AsyncStorage.getItem("filter");
      switchCheckbox(filter);
      getLiveEvents(page, filter);
    })();
  }, []);

  const displayFilteredEvents = filter => {
    AsyncStorage.setItem("filter", filter);
    switchCheckbox(filter);
    getLiveEvents(1, filter);
  };

  return displayFilterOptions ? (
    <View style={[bgWhite, w100p, pv10, shadowGrey]}>
      <Text style={[ml10, mb10, fontBold, darkgrey]}>
        {t("filter:display")}
      </Text>
      <CheckBox
        title={t("filter:all")}
        textStyle={[color5, fontBold]}
        checkedColor={COLOR2}
        checkedIcon="dot-circle-o"
        uncheckedIcon="circle-o"
        checked={!filter || filter === DISPLAY_ALL}
        onPress={() => displayFilteredEvents(DISPLAY_ALL)}
      />
      <CheckBox
        title={t(`filter:${COMMUNITY}`)}
        textStyle={[color5, fontBold]}
        checkedColor={COLOR2}
        checkedIcon="dot-circle-o"
        uncheckedIcon="circle-o"
        checked={filter === DISPLAY_ONLY_COMMUNITY_POSTS}
        onPress={() => displayFilteredEvents(DISPLAY_ONLY_COMMUNITY_POSTS)}
      />
      <CheckBox
        title={t("filter:my")}
        textStyle={[color5, fontBold]}
        checkedColor={COLOR2}
        checkedIcon="dot-circle-o"
        uncheckedIcon="circle-o"
        checked={filter === DISPLAY_ONLY_MY_POSTS}
        onPress={() => displayFilteredEvents(DISPLAY_ONLY_MY_POSTS)}
      />
    </View>
  ) : (
    <Fragment />
  );
};

const MemoizedLiveFilters = memo(LiveFilters);
export default MemoizedLiveFilters;
