import axios from "axios";
import { API, FETCH_FAILURE } from "../actions/types";
import {
  accessDenied,
  apiError,
  apiStart,
  apiStartDefaultFailure,
  apiEnd,
  serverInMaintenance
} from "../actions/api";
import { normalize } from "normalizr";

const setFetchFailure = error => {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
};

export const fetch = async (action, dispatch) => {
  let { url, method, data, onSuccess, onFailure, label, headers, schema } =
    action.payload;
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  try {
    if (label && dispatch) {
      if (onFailure) {
        dispatch(apiStart(label));
      } else {
        onFailure = setFetchFailure;
        dispatch(apiStart(label, true));
      }
    }

    const response = await axios({
      url,
      method,
      headers,
      [dataOrParams]: data,
      withCredentials: true
    });
    if (schema) {
      if (dispatch) {
        dispatch(onSuccess(normalize(response.data, schema)));
      } else {
        return normalize(response.data, schema);
      }
    } else {
      if (dispatch) {
        dispatch(onSuccess(response.data));
      } else {
        return response.data;
      }
    }
  } catch (error) {
    if (dispatch) {
      if (error.response && error.response.status === 503) {
        dispatch(serverInMaintenance(label));
      } else {
        dispatch(apiError(error, label, data));
        dispatch(onFailure(error));
      }
      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(url));
      }
    } else {
      throw error;
    }
  } finally {
    if (label && dispatch) {
      dispatch(apiEnd(label));
    }
  }
};

const apiMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type !== API) return;

    fetch(action, dispatch);
  };

export default apiMiddleware;
