import React, { memo } from "react";
import { ActivityIndicator, FlatList, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchGetChatEmployees } from "../actions/chat";
import ChatUserItem from "../components/ChatUserItem";
import { getChatReducer, getCommunityReducer } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, { COLOR2 } from "../styles/commonStyles";

const getSelector = createSelector(
  [getChatReducer, getCommunityReducer],
  ({ sFetchingDataEmployees, dataEmployees }, { currentCommunity }) => ({
    sFetchingDataEmployees,
    dataEmployees,
    currentCommunity
  })
);

const ChatEmployees = () => {
  const dispatch = useDispatch();

  const {
    isFetchingDataEmployees,
    dataEmployees,
    currentCommunity
  } = useSelector(getSelector, shallowEqual);

  React.useEffect(() => {
    dispatch(fetchGetChatEmployees());
  }, []);

  const {
    bgColor1,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    w100p,
    font,
    bgWhite,
    rounded5,
    darkgrey,
    mh10,
    p10,
    shadowGrey,
    textCenter
  } = commonStyles;

  return (
    <View style={[flex1, bgColor1]}>
      {currentCommunity && !currentCommunity.subscribe ? (
        <View style={[flex1, justifyContentCenter, alignItemsCenter]}>
          <View style={[bgWhite, mh10, rounded5, shadowGrey]}>
            <Text style={[font, darkgrey, textCenter, p10]}>
              {t("chat:nosubscription")}
            </Text>
          </View>
        </View>
      ) : isFetchingDataEmployees ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <FlatList
          style={[flex1, w100p]}
          data={dataEmployees}
          renderItem={({ item }) => <ChatUserItem item={item} />}
          keyExtractor={item => item._id}
        />
      )}
    </View>
  );
};

const MemoizedChatEmployees = memo(ChatEmployees);
export default MemoizedChatEmployees;
