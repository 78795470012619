import { useNavigation } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import React, { memo, useEffect } from "react";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { SET_PUBLISH_TEMPLATE } from "../actions/types";
import { getImageUri } from "../assets/Images";
import {
  ROLE_ADMINISTRATOR,
  ROLE_WEBMASTER,
  TYPE_ACTIVITY,
  TYPE_PROPOSAL,
  TYPE_TEMPLATE
} from "../config/constants";
import {
  getActivities,
  getCategoriesForActivities,
  getCategoriesForProposals,
  getCategoriesForTemplates,
  getCategoriesForUser,
  getProposals,
  getTypeTemplates,
  getUserTemplates
} from "../selectors";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, {
  DARKGREY_COLOR,
  isWeb,
  SCREEN_HEIGHT,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import { openBrowser } from "../utils/UrlUtil";

const itemSize = SCREEN_WIDTH / 3 - 20 > 120 ? 120 : SCREEN_WIDTH / 3 - 20;
const contentSize = itemSize - 40;
const numColumns = Math.floor(SCREEN_WIDTH / itemSize);

const SelectTemplate = ({ type }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const headerHeight = useHeaderHeight();

  const { template } = useSelector(state => state.templateReducer);

  let categories;
  let templates;
  let description;
  switch (type) {
    case TYPE_ACTIVITY: {
      categories = useSelector(getCategoriesForActivities);
      templates = useSelector(getActivities);
      description = t("template:propose");
      break;
    }
    case TYPE_PROPOSAL: {
      categories = useSelector(getCategoriesForProposals);
      templates = useSelector(getProposals);
      description = t("template:need");
      break;
    }
    case TYPE_TEMPLATE: {
      const { user } = useSelector(state => state.profileReducer);
      if (user.role === ROLE_ADMINISTRATOR || user.role === ROLE_WEBMASTER) {
        categories = useSelector(getCategoriesForTemplates);
        templates = useSelector(getTypeTemplates);
      } else {
        categories = useSelector(getCategoriesForUser);
        templates = useSelector(getUserTemplates);
      }
      description = t("template:select");
      break;
    }
    default:
      // unknown type: do nothing
      break;
  }

  useEffect(() => {
    if (template) {
      navigation.navigate("SelectTemplateDateScreen", {
        id: template._id
      });
    }
  }, [template]);

  const {
    p10,
    font,
    wrap,
    textCenter,
    flex1,
    justifyContentCenter,
    alignItemsCenter,
    opacity0,
    darkgrey,
    bgColor1,
    color5,
    color2,
    ml10,
    mh10,
    mb10,
    m10,
    mt20,
    fs12,
    fontBold,
    fs20,
    h100p,
    row,
    flexWrap
  } = commonStyles;

  const renderCategoryTemplate = ({ item: templateId }) => {
    const { buttonActivity } = styles;
    const template = templates[templateId];
    if (template) {
      return (
        <TouchableOpacity
          key={"w" + templateId}
          style={[buttonActivity]}
          onPress={() =>
            dispatch({
              type: SET_PUBLISH_TEMPLATE,
              payload: template
            })
          }
        >
          {template.picto ? (
            <View style={flex1}>
              <Image
                style={[
                  {
                    width: contentSize,
                    height: contentSize,
                    tintColor: DARKGREY_COLOR
                  },
                  justifyContentCenter,
                  alignItemsCenter
                ]}
                source={getImageUri(template.picto)}
              />
              <Image
                style={[
                  {
                    width: contentSize,
                    height: contentSize
                  },
                  opacity0,
                  justifyContentCenter,
                  alignItemsCenter
                ]}
                source={getImageUri(template.picto)}
              />
            </View>
          ) : (
            <View style={flex1}>
              <Image
                style={[
                  { width: contentSize, height: contentSize },
                  justifyContentCenter,
                  alignItemsCenter
                ]}
                source={{ uri: template.photoUri }}
              />
            </View>
          )}

          <Text
            style={[
              font,
              darkgrey,
              wrap,
              { fontSize: 11, paddingHorizontal: 5 },
              textCenter
            ]}
            numberOfLines={2}
          >
            {getTranslatedProperty(template, "name")}
          </Text>
        </TouchableOpacity>
      );
    }
  };

  const renderCategory = ({ item: category }) => {
    return (
      <View style={[mh10]} key={"w" + category._id}>
        <Text style={[fontBold, color5, fs20, m10]}>
          {getTranslatedProperty(category, "name")}
        </Text>
        {isWeb ? (
          // To fix scroll issue, better to use simple View on Web
          <View style={[row, flexWrap]}>
            {category.templateIds.map(item => renderCategoryTemplate({ item }))}
          </View>
        ) : (
          <FlatList
            showsVerticalScrollIndicator={false}
            contentContainerStyle={[p10]}
            numColumns={numColumns}
            data={category.templateIds}
            renderItem={renderCategoryTemplate}
            keyExtractor={templateId => templateId}
          />
        )}
      </View>
    );
  };

  return (
    <View style={[h100p, { height: SCREEN_HEIGHT - headerHeight }, bgColor1]}>
      <Text style={[font, color5, ml10, mb10, { fontSize: 18, marginTop: 15 }]}>
        {description}
      </Text>
      <ScrollView style={[flex1]}>
        {isWeb ? (
          // To fix scroll issue, better to use simple View on Web
          Object.values(categories).map(item => renderCategory({ item }))
        ) : (
          <FlatList
            showsVerticalScrollIndicator={false}
            numColumns={1}
            data={Object.values(categories)}
            renderItem={renderCategory}
            keyExtractor={category => category._id}
          />
        )}
      </ScrollView>
      {type !== TYPE_TEMPLATE && (
        <Text style={[font, m10, color5, fs12, { lineHeight: 20 }]}>
          {t("infos:pictosshort")}
          <Text
            style={[font, mt20, color2, fs12]}
            onPress={() => openBrowser(dispatch, "https://www.freepik.com")}
          >
            Freepik
          </Text>
          {t("infos:from")}
          <Text
            style={[font, mt20, color2, fs12]}
            onPress={() => openBrowser(dispatch, "https://www.flaticon.com")}
          >
            www.flaticon.com
          </Text>
        </Text>
      )}
    </View>
  );
};

const MemoizedSelectTemplate = memo(SelectTemplate);
export default {
  SelectActivity: () => <MemoizedSelectTemplate type={TYPE_ACTIVITY} />,
  SelectProposal: () => <MemoizedSelectTemplate type={TYPE_PROPOSAL} />,
  SelectTemplate: () => <MemoizedSelectTemplate type={TYPE_TEMPLATE} />
};

const styles = StyleSheet.create({
  buttonActivity: {
    height: itemSize,
    width: itemSize,
    borderColor: "black",
    borderRadius: 3,
    backgroundColor: "white",
    borderWidth: 1,
    alignItems: "center",
    marginRight: 10,
    marginBottom: 10,
    paddingVertical: 5
  }
});
