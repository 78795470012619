import {
  SET_PROFILE_DETAILS,
  FETCH_GET_USER,
  SET_USER_FAILURE,
  FETCH_GET_HOBBIES,
  SET_HOBBIES,
  SET_HOBBIES_FAILURE,
  FETCH_GET_USER_PROFILE,
  SET_USER_PROFILE,
  FETCH_UPDATE_PROFILE,
  FETCH_UPDATE_BOOKING,
  UPDATE_PROFILE_FAILURE,
  FETCH_BLOCK_USER,
  SET_BLOCK_USER_RESULT,
  FETCH_UNBLOCK_USER,
  SET_UNBLOCK_USER_RESULT,
  FETCH_FLAG_INAPPROPRIATE_CONTENT,
  SET_FLAG_INAPPROPRIATE_CONTENT_RESULT,
  FETCH_CHECK_PSEUDO,
  CHECK_PSEUDO_RESULT,
  CHECK_PSEUDO_FAILURE,
  FETCH_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";
import { fetchGetMyChatUsers } from "./chat";

const hobbies = new schema.Entity(
  "hobbies",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchGetUser() {
  return apiAction({
    url: "/api/auth",
    method: "GET",
    onSuccess: setProfileDetails,
    onFailure: setUserFailure,
    label: FETCH_GET_USER
  });
}

export function fetchGetUserAndMyChatUsers() {
  return apiAction({
    url: "/api/auth",
    method: "GET",
    onSuccess: data => [fetchGetMyChatUsers(), setProfileDetails(data)],
    onFailure: setUserFailure,
    label: FETCH_GET_USER
  });
}

export function setProfileDetails(data) {
  return {
    type: SET_PROFILE_DETAILS,
    payload: data
  };
}

export function setUserFailure(error) {
  return {
    type: SET_USER_FAILURE,
    payload: error
  };
}

export function fetchGetHobbies() {
  return apiAction({
    url: "/hobbies",
    method: "GET",
    schema: [hobbies],
    onSuccess: setHobbies,
    onFailure: setHobbiesFailure,
    label: FETCH_GET_HOBBIES
  });
}

function setHobbies(normalized) {
  return {
    type: SET_HOBBIES,
    entities: normalized.entities
  };
}

function setHobbiesFailure(error) {
  return {
    type: SET_HOBBIES_FAILURE,
    payload: error
  };
}

export function fetchUpdateProfile(user, booking) {
  return apiAction({
    url: "/api/user",
    data: user,
    method: "PUT",
    onSuccess: () => (booking ? fetchUpdateBooking(booking) : fetchGetUser()),
    onFailure: updateProfileFailure,
    label: FETCH_UPDATE_PROFILE
  });
}

function fetchUpdateBooking(booking) {
  return apiAction({
    url: "/api/booking",
    data: booking,
    method: "POST",
    onSuccess: () => fetchGetUser(),
    onFailure: updateProfileFailure,
    label: FETCH_UPDATE_BOOKING
  });
}

function updateProfileFailure(error) {
  return {
    type: UPDATE_PROFILE_FAILURE,
    payload: error
  };
}

export function fetchGetUserProfile(data) {
  return apiAction({
    url: "/api/user",
    method: "GET",
    data,
    onSuccess: setUserProfile,
    onFailure: setFetchFailure,
    label: FETCH_GET_USER_PROFILE
  });
}

function setUserProfile(data) {
  return {
    type: SET_USER_PROFILE,
    payload: data
  };
}

export function fetchBlockUser(userId) {
  return apiAction({
    url: "/users/block/" + userId,
    method: "PUT",
    onSuccess: data => [setBlockUserResult(data), fetchGetMyChatUsers()],
    onFailure: setFetchFailure,
    label: FETCH_BLOCK_USER
  });
}

function setBlockUserResult(data) {
  return {
    type: SET_BLOCK_USER_RESULT,
    payload: data
  };
}

export function fetchUnblockUser(userId) {
  return apiAction({
    url: "/users/unblock/" + userId,
    method: "PUT",
    onSuccess: data => [setUnblockUserResult(data), fetchGetMyChatUsers()],
    onFailure: setFetchFailure,
    label: FETCH_UNBLOCK_USER
  });
}

function setUnblockUserResult(data) {
  return {
    type: SET_UNBLOCK_USER_RESULT,
    payload: data
  };
}

export function fetchFlagInappropriateContent(userId) {
  return apiAction({
    url: "/api/flag/" + userId,
    method: "PUT",
    onSuccess: setFlagInappropriateContentResult,
    onFailure: setFetchFailure,
    label: FETCH_FLAG_INAPPROPRIATE_CONTENT
  });
}

function setFlagInappropriateContentResult(data) {
  return {
    type: SET_FLAG_INAPPROPRIATE_CONTENT_RESULT,
    payload: data
  };
}

export function fetchCheckPseudo(data) {
  return apiAction({
    url: "/api/checkpseudo",
    data,
    method: "POST",
    onSuccess: checkPseudoResult,
    onFailure: checkPseudoFailure,
    label: FETCH_CHECK_PSEUDO
  });
}

function checkPseudoResult(data) {
  return {
    type: CHECK_PSEUDO_RESULT,
    payload: data
  };
}

function checkPseudoFailure(error) {
  return {
    type: CHECK_PSEUDO_FAILURE,
    payload: error
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}
