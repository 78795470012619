import {
  FETCH_GET_ADS,
  SET_ADS_RESULT,
  SET_ADS_FAILURE,
  FETCH_GET_AD_DETAILS,
  SET_AD_DETAILS_RESULT,
  SET_AD_DETAILS_FAILURE,
  FETCH_GET_ADS_CATEGORIES,
  SET_ADS_CATEGORIES,
  SET_ADS_CATEGORIES_FAILURE,
  FETCH_GET_AD_LOCATION,
  SET_AD_LOCATION_RESULT,
  SET_AD_LOCATION_FAILURE,
  FETCH_UPDATE_AD_MAP_PHOTO_URI,
  SET_UPDATE_AD_MAP_PHOTO_URI_RESULT,
  FETCH_FAILURE
} from "./types";
import { schema } from "normalizr";
import { apiAction } from "./api";

const adCategory = new schema.Entity(
  "adsCategories",
  {},
  {
    idAttribute: "_id"
  }
);

export const adSchema = new schema.Entity(
  "ads",
  {
    categoryId: adCategory
  },
  { idAttribute: "_id" }
);

const pageinfos = new schema.Entity("pagesinfos", {
  docs: [adSchema]
});

export function fetchGetAdsCategories() {
  return apiAction({
    url: "/gooddeals/categories",
    method: "GET",
    schema: [adCategory],
    onSuccess: setAdsCategories,
    onFailure: setAdsCategoriesFailure,
    label: FETCH_GET_ADS_CATEGORIES
  });
}

function setAdsCategories(normalized) {
  return {
    type: SET_ADS_CATEGORIES,
    entities: normalized.entities
  };
}

function setAdsCategoriesFailure(error) {
  return {
    type: SET_ADS_CATEGORIES_FAILURE,
    payload: error
  };
}

export function fetchGetAds(page, filter) {
  return apiAction({
    url: `/gooddeals/community?page=${page}${
      filter ? "&filter=" + filter : ""
    }`,
    schema: pageinfos,
    method: "GET",
    onSuccess: setAds,
    onFailure: setAdsFailure,
    label: FETCH_GET_ADS
  });
}

function setAds(normalized) {
  return {
    type: SET_ADS_RESULT,
    entities: normalized.entities
  };
}

function setAdsFailure(error) {
  return {
    type: SET_ADS_FAILURE,
    payload: error
  };
}

export function fetchGetAdsLocation(page, filter) {
  return apiAction({
    url: `/gooddeals/community?page=${page}${
      filter ? "&filter=" + filter : ""
    }&all=true`,
    method: "GET",
    onSuccess: setAdsLocation,
    onFailure: setAdsLocationFailure,
    label: FETCH_GET_AD_LOCATION
  });
}

function setAdsLocation(data) {
  return {
    type: SET_AD_LOCATION_RESULT,
    payload: data
  };
}

function setAdsLocationFailure(error) {
  return {
    type: SET_AD_LOCATION_FAILURE,
    payload: error
  };
}

export function fetchGetAdDetails(data) {
  return apiAction({
    url: "/gooddeals",
    method: "GET",
    data,
    onSuccess: setAdDetails,
    onFailure: setAdDetailsFailure,
    label: FETCH_GET_AD_DETAILS
  });
}

function setAdDetails(data) {
  return {
    type: SET_AD_DETAILS_RESULT,
    payload: data
  };
}

function setAdDetailsFailure(error) {
  return {
    type: SET_AD_DETAILS_FAILURE,
    payload: error
  };
}

export function fetchUpdateAdMapPhotoUri(data) {
  return apiAction({
    url: "/gooddeals/map",
    method: "PUT",
    data,
    onSuccess: setUpdateAdMapPhotoUriResult,
    onFailure: setFetchFailure,
    label: FETCH_UPDATE_AD_MAP_PHOTO_URI
  });
}

function setUpdateAdMapPhotoUriResult(data) {
  return {
    type: SET_UPDATE_AD_MAP_PHOTO_URI_RESULT,
    payload: data
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}
