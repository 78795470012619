import { useHeaderHeight } from "@react-navigation/elements";
import * as ScreenOrientation from "expo-screen-orientation";
import React, { useCallback, useEffect, useState } from "react";
import { Dimensions, Image, Text, View } from "react-native";
import ImageZoom from "react-native-image-pan-zoom";
import { t } from "../services/i18n";
import commonStyles, {
  isNative,
  isWeb,
  SCREEN_HEIGHT,
  SCREEN_WIDTH
} from "../styles/commonStyles";

let _mounted = false;

const ImgZoom = props => {
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [isPortrait, setIsPortrait] = useState(true);

  const headerHeight = useHeaderHeight();

  const { uri } = props.route.params;

  const screenWidth = isWeb ? SCREEN_WIDTH : Dimensions.get("window").width;
  const cleanup = () => {
    (async () => {
      if (
        !isWeb &&
        (await ScreenOrientation.supportsOrientationLockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT
        ))
      ) {
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT
        );
      }
    })();
    _mounted = false;
  };

  useEffect(() => {
    (async () => {
      if (
        !isWeb &&
        (await ScreenOrientation.supportsOrientationLockAsync(
          ScreenOrientation.OrientationLock.ALL
        ))
      ) {
        await ScreenOrientation.unlockAsync(
          ScreenOrientation.OrientationLock.ALL
        );
      }
      _mounted = true;
    })();
    return cleanup;
  }, []);

  const onLayout = useCallback(
    e => {
      if (e.nativeEvent.layout.height > e.nativeEvent.layout.width) {
        Image.getSize(uri, (width, height) => {
          let proportion = height / width;
          if (_mounted && imageWidth === 0) {
            setImageWidth(screenWidth);
            setImageHeight(Math.ceil(screenWidth * proportion));
            setIsPortrait(true);
          }
        });
      } else {
        Image.getSize(uri, (width, height) => {
          let proportion = height / width;
          if (_mounted && imageWidth === 0) {
            setImageWidth(Math.ceil(SCREEN_HEIGHT / proportion));
            setImageHeight(SCREEN_HEIGHT);
            setIsPortrait(false);
          }
        });
      }
    },
    [uri]
  );

  const { h100p, bgColor1, color5, font } = commonStyles;

  return (
    <View
      style={[h100p, { height: SCREEN_HEIGHT - headerHeight }, bgColor1]}
      onLayout={onLayout}
    >
      {isPortrait && isNative && (
        <Text
          style={[
            font,
            color5,
            { fontSize: 18, marginLeft: 10, marginTop: 15 }
          ]}
        >
          {t("template:zoom")}
        </Text>
      )}
      {Boolean(imageWidth && imageHeight && uri) && (
        <ImageZoom
          cropWidth={screenWidth}
          cropHeight={SCREEN_HEIGHT - headerHeight}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        >
          <Image
            style={{ height: imageHeight, width: imageWidth }}
            source={{ uri }}
          />
        </ImageZoom>
      )}
    </View>
  );
};

export default ImgZoom;
