import React, { memo } from "react";
import { ActivityIndicator, FlatList, Text, View } from "react-native";
import { Button } from "react-native-elements";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchGetMyChatUsers } from "../actions/chat";
import { LOGOUT, RESET_AND_SUBSCRIBE } from "../actions/types";
import ChatUserItem from "../components/ChatUserItem";
import { ROLE_GUEST } from "../config/constants";
import { getChatReducer, getProfileReducer } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, { COLOR2 } from "../styles/commonStyles";

const getSelector = createSelector(
  [getProfileReducer, getChatReducer],
  ({ user }, { isFetching, data, reload }) => ({
    isFetching,
    data,
    reload,
    user
  })
);

const ChatUsers = () => {
  const dispatch = useDispatch();

  const { isFetching, data, reload, user } = useSelector(
    getSelector,
    shallowEqual
  );

  React.useEffect(() => {
    if (user && user.role !== ROLE_GUEST && reload) {
      dispatch(fetchGetMyChatUsers());
    }
  }, [reload]);

  const {
    bgColor1,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    w100p,
    font,
    bgColor2,
    bgWhite,
    rounded5,
    darkgrey,
    mh10,
    p10,
    shadowGrey,
    textCenter,
    h44,
    rounded22,
    white,
    fontBold
  } = commonStyles;

  return (
    <View style={[flex1, bgColor1]}>
      {user && user.role === ROLE_GUEST ? (
        <View style={[flex1, justifyContentCenter, alignItemsCenter]}>
          <View style={[bgWhite, mh10, rounded5, shadowGrey, alignItemsCenter]}>
            <Text style={[font, darkgrey, textCenter, p10]}>
              {t("chat:conversationguest")}
            </Text>
            <Button
              buttonStyle={[bgColor2, h44, rounded22, { width: 120 }]}
              containerStyle={[p10]}
              activeOpacity={0.8}
              title={t("button:signup")}
              onPress={() =>
                dispatch({
                  type: LOGOUT,
                  value: RESET_AND_SUBSCRIBE
                })
              }
              titleStyle={[white, fontBold, { fontSize: 16 }]}
            />
          </View>
        </View>
      ) : isFetching ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <FlatList
          style={[flex1, w100p]}
          data={data}
          renderItem={({ item }) => <ChatUserItem item={item} />}
          keyExtractor={item => item._id}
        />
      )}
    </View>
  );
};

const MemoizedChatUsers = memo(ChatUsers);
export default MemoizedChatUsers;
