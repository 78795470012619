import { useNavigation } from "@react-navigation/core";
import React, { memo } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-elements";
import { shallowEqual, useSelector } from "react-redux";
import { getImageUri } from "../assets/Images";
import { GROUP_ID_WEATHER } from "../config/constants";
import { getLiveHeaderSelector } from "../selectors";
import commonStyles from "../styles/commonStyles";

const LiveHeader = ({ item }) => {
  const navigation = useNavigation();

  const { userId, dateAdded, groupId } = item;

  const { user, users, dateStr } = useSelector(
    getLiveHeaderSelector(dateAdded),
    shallowEqual
  );

  const { pseudo, photoUri, firstname, company, gender } = users[userId] || {};

  // Compute from
  const from = company ? company : firstname;

  const {
    darkgrey,
    bgLightgrey,
    row,
    flex1,
    alignItemsCenter,
    pr10,
    font,
    rounded30,
    h60,
    ml5
  } = commonStyles;

  const isMine = Boolean(user && user._id === userId);

  return (
    <View style={[bgLightgrey, row, h60, rounded30, alignItemsCenter, pr10]}>
      {userId ? (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("ProfileTeamScreen", {
              userId
            })
          }
          disabled={isMine || groupId === GROUP_ID_WEATHER}
        >
          <Avatar
            rounded
            size={60}
            source={
              photoUri
                ? { uri: photoUri }
                : gender === "F"
                ? getImageUri("avatarF")
                : getImageUri("avatar")
            }
            title={from ? from.toUpperCase().substr(0, 2) : "??"}
            activeOpacity={0.7}
          />
        </TouchableOpacity>
      ) : (
        <View>
          <Avatar
            rounded
            size={60}
            source={
              photoUri
                ? { uri: photoUri }
                : gender === "F"
                ? getImageUri("avatarF")
                : getImageUri("avatar")
            }
            title={from ? from.toUpperCase().substr(0, 2) : "??"}
            activeOpacity={0.7}
          />
        </View>
      )}
      <View style={[ml5, flex1]}>
        {userId ? (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("ProfileTeamScreen", {
                userId
              })
            }
          >
            <Text style={[darkgrey, font]}>{from}</Text>
          </TouchableOpacity>
        ) : (
          <Text style={[darkgrey, font]}>{from}</Text>
        )}

        <Text refresh={dateStr} style={[darkgrey, font]}>
          {dateStr}
        </Text>
      </View>
    </View>
  );
};

const MemoizedLiveHeader = memo(LiveHeader);
export default MemoizedLiveHeader;
