import React, { Component, Fragment } from "react";
import { AppState } from "react-native";
import { connect } from "react-redux";
import * as Updates from "expo-updates";
import { ROLE_WEBMASTER } from "../config/constants";
import { SET_DROP_DOWN_ALERT_WARN } from "../actions/types";

class CheckUpdates extends Component {
  // to accept update, client version should be equals to minimumClientVersion or WebMaster
  acceptUpdate = update => {
    const { user, clientVersion, fatalerror } = this.props;
    return (
      clientVersion === update.manifest.version ||
      fatalerror ||
      (user && user.role === ROLE_WEBMASTER)
    );
  };

  _checkUpdates = async () => {
    try {
      const { user, forceupdate, fatalerror } = this.props;
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable && this.acceptUpdate(update)) {
        await Updates.fetchUpdateAsync();
        if (
          (user && user.role === ROLE_WEBMASTER) ||
          forceupdate ||
          fatalerror
        ) {
          this.props.dispatch({
            type: SET_DROP_DOWN_ALERT_WARN,
            warn: "needupdate"
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  _handleAppStateChange = nextAppState => {
    if (nextAppState === "active") {
      this._checkUpdates();
    }
  };

  componentWillUnmount() {
    if (!__DEV__) {
      AppState.removeEventListener("change", this._handleAppStateChange);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { needupdate, user, fatalerror } = this.props;

    if (
      !__DEV__ &&
      ((!prevProps.needupdate && needupdate) ||
        (!prevProps.fatalerror && fatalerror) ||
        (!prevProps.user && user && user.role === ROLE_WEBMASTER))
    ) {
      AppState.addEventListener("change", this._handleAppStateChange);
      this._checkUpdates();
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = state => {
  const {
    needupdate,
    forceupdate,
    dropDownAlertError,
    clientVersion
  } = state.globalInformationsReducer;
  const { user } = state.profileReducer;
  return {
    clientVersion,
    needupdate,
    fatalerror: Boolean(
      dropDownAlertError &&
        (dropDownAlertError === "unexpectederror" ||
          dropDownAlertError === "networkerror")
    ),
    forceupdate,
    user
  };
};

export default connect(mapStateToProps)(CheckUpdates);
