import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import Constants from "expo-constants";
import React, { Fragment, memo, useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { Avatar } from "react-native-elements";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchBlockUser,
  fetchFlagInappropriateContent,
  fetchGetUserProfile,
  fetchUnblockUser
} from "../actions/profile";
import { SET_DROP_DOWN_ALERT_WARN } from "../actions/types";
import { getImageUri } from "../assets/Images";
import ActionButton from "../components/actionButton/ActionButton";
import ConfirmationModal from "../components/ConfirmationModal";
import Hobbies from "../components/Hobbies";
import {
  ROLE_ADMINISTRATOR,
  ROLE_ADVERTISER,
  ROLE_NEIGHBOR,
  ROLE_EMPLOYEE,
  ROLE_GUEST,
  ROLE_WEBMASTER
} from "../config/constants";
import { getProfileSelector } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, {
  BLUE_COLOR,
  COLOR2,
  COLOR5,
  PINK_COLOR,
  SCREEN_HEIGHT
} from "../styles/commonStyles";
import { openURL } from "../utils/UrlUtil";
const IMAGE_SIZE = 200;

const Profile = () => {
  const headerHeight = useHeaderHeight();
  const dispatch = useDispatch();
  const route = useRoute();
  const navigation = useNavigation();

  const [fetched, setFetched] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState({
    description: "",
    buttonConfirm: "",
    showConfirmationModal: false,
    onConfirmation: blockUser,
    buttonColor: "red"
  });

  const {
    isFetching,
    photoUri,
    hobbiesIds,
    pseudo,
    firstname,
    lastname,
    description: userDescription,
    gender,
    floor,
    apartmentNumber,
    job,
    buildingAccess,
    to,
    role,
    myRole,
    blockedUsersIds,
    phone,
    company
  } = useSelector(getProfileSelector, shallowEqual);

  useEffect(() => {
    if (route?.params) {
      const { userId, pseudo } = route.params;
      dispatch(fetchGetUserProfile({ userId, pseudo }));
      setFetched(true);
    }
  }, []);

  const hideConfirmationModal = () => {
    setConfirmationModalProps({
      ...confirmationModalProps,
      showConfirmationModal: false
    });
  };

  const blockUser = () => {
    hideConfirmationModal();
    dispatch(fetchBlockUser(to));
  };

  const unblockUser = () => {
    hideConfirmationModal();
    dispatch(fetchUnblockUser(to));
  };

  const flagContent = () => {
    hideConfirmationModal();
    dispatch(fetchFlagInappropriateContent(to));
  };

  const {
    mv20,
    bgWhite,
    color5,
    color2,
    bgColor1,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    fs16,
    fs20,
    mb10,
    mb20,
    font,
    fontBold,
    textCenter,
    row,
    w100p,
    p10,
    shadowGrey,
    mt10,
    alignItemsFlexEnd
  } = commonStyles;

  return (
    <View style={[w100p, { height: SCREEN_HEIGHT - headerHeight }, bgColor1]}>
      <ConfirmationModal
        onClosed={() => hideConfirmationModal()}
        title={t("menu:confirmation")}
        isFetching={isFetching}
        {...confirmationModalProps}
      />
      {isFetching || !fetched ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <ScrollView
          style={[flex1, bgColor1]}
          contentContainerStyle={{
            paddingBottom: Constants.statusBarHeight + 70
          }}
        >
          <View
            style={[
              w100p,
              p10,
              bgWhite,
              mt10,
              mb20,
              shadowGrey,
              alignItemsCenter
            ]}
          >
            <Text style={[fontBold, color5, fs20, mv20]}>
              {t("profile:profile")}
            </Text>

            <View style={[row, mb20, alignItemsFlexEnd]}>
              <Avatar
                rounded
                size={IMAGE_SIZE}
                title={
                  role === ROLE_ADVERTISER && company && company.length >= 2
                    ? company.toUpperCase().substr(0, 2)
                    : pseudo
                    ? pseudo.toUpperCase().substr(0, 2)
                    : "?"
                }
                source={
                  photoUri
                    ? {
                        uri: photoUri
                      }
                    : company
                    ? undefined
                    : gender === "F"
                    ? getImageUri("avatarF")
                    : getImageUri("avatar")
                }
              />
            </View>

            {role === ROLE_ADVERTISER ? (
              <Text style={[fontBold, color2, fs20, mb10]}>{`${company}`}</Text>
            ) : role !== ROLE_NEIGHBOR ||
              myRole === ROLE_EMPLOYEE ||
              myRole === ROLE_ADMINISTRATOR ||
              myRole === ROLE_WEBMASTER ? (
              <Fragment>
                <Text
                  style={[
                    fontBold,
                    gender === "M"
                      ? { color: BLUE_COLOR }
                      : { color: PINK_COLOR },
                    fs20,
                    mb10
                  ]}
                >
                  {`${firstname} ${lastname}`}
                </Text>
                {pseudo?.indexOf("@") < 0 && (
                  <Text style={[font, mb10]}>[{pseudo}]</Text>
                )}
              </Fragment>
            ) : (
              <View style={row}>
                <Text
                  style={[
                    fontBold,
                    gender === "M"
                      ? { color: BLUE_COLOR }
                      : { color: PINK_COLOR },
                    fs20,
                    mb10
                  ]}
                >{`${firstname} ${lastname}`}</Text>
              </View>
            )}
            {floor && (
              <Text style={[font, mb10, textCenter]}>
                {role === ROLE_NEIGHBOR && t("profile:lives")}
                {floor === "0"
                  ? "RC"
                  : floor === "1"
                  ? `1er ${t("profile:floor").toLowerCase()}`
                  : `${floor}${t("profile:eme")} ${t(
                      "profile:floor"
                    ).toLowerCase()}`}
              </Text>
            )}
            {buildingAccess && (
              <Text style={[font, mb10, textCenter]}>
                {`${floor ? "" : t("profile:lives")}${t(
                  "profile:buildingaccess"
                )} ${buildingAccess}`}
              </Text>
            )}
            {apartmentNumber && (
              <Text style={[font, mb10, textCenter]}>
                {`${floor || buildingAccess ? "" : t("profile:lives")}${t(
                  "profile:apartmentnumber"
                )} ${apartmentNumber}`}
              </Text>
            )}
            {Boolean(job) && (
              <Text style={[font, mb10, textCenter]}>
                {`${t("profile:job")} ${job}`}
              </Text>
            )}
            {Boolean(userDescription) && (
              <Text style={[fontBold, color2, mb20]}>{userDescription}</Text>
            )}
          </View>

          {role === ROLE_NEIGHBOR && (
            <Fragment>
              {hobbiesIds.length > 0 && (
                <View
                  style={[
                    w100p,
                    p10,
                    bgWhite,
                    mb20,
                    shadowGrey,
                    alignItemsCenter
                  ]}
                >
                  <Text style={[fontBold, color5, fs20, mv20]}>
                    {t("profile:hobbies")}
                  </Text>
                  <Hobbies hobbiesIds={new Set(hobbiesIds)} readOnly={true} />
                </View>
              )}
            </Fragment>
          )}
        </ScrollView>
      )}
      <ActionButton
        buttonColor={COLOR5}
        degrees={90}
        renderIcon={() => (
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={40}
            color="white"
          />
        )}
      >
        {Boolean(
          (!role || role === ROLE_NEIGHBOR) && myRole !== ROLE_GUEST
        ) && (
          <ActionButton.Item
            buttonColor={blockedUsersIds.has(to) ? PINK_COLOR : "red"}
            title={
              blockedUsersIds.has(to)
                ? `${t("button:unblock")} ${pseudo}`
                : `${t("button:block")} ${pseudo}`
            }
            onPress={() => {
              blockedUsersIds.has(to)
                ? setConfirmationModalProps({
                    showConfirmationModal: true,
                    description: t("profile:confirmunblockuser"),
                    buttonConfirm: t("button:confirm"),
                    onConfirmation: unblockUser,
                    buttonColor: PINK_COLOR
                  })
                : setConfirmationModalProps({
                    showConfirmationModal: true,
                    description: t("profile:confirmblockuser"),
                    buttonConfirm: t("button:confirm"),
                    onConfirmation: blockUser,
                    buttonColor: "red"
                  });
            }}
            textStyle={[fontBold, fs16]}
          >
            <FontAwesome name="ban" size={34} color="white" />
          </ActionButton.Item>
        )}
        {Boolean(
          (!role || role === ROLE_NEIGHBOR || role === ROLE_ADVERTISER) &&
            myRole !== ROLE_GUEST
        ) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:flagcontent")}
            onPress={() =>
              setConfirmationModalProps({
                showConfirmationModal: true,
                description: t("profile:confirmflag"),
                buttonConfirm: t("button:flag"),
                onConfirmation: flagContent,
                buttonColor: COLOR2
              })
            }
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons
              name="alert-decagram"
              size={34}
              color="white"
            />
          </ActionButton.Item>
        )}
        {!blockedUsersIds.has(to) && role !== ROLE_ADVERTISER && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={`${t("button:contact")} ${
              pseudo.indexOf("@") < 0 ? pseudo : firstname
            }`}
            onPress={() => {
              if (myRole !== ROLE_GUEST) {
                pseudo.indexOf("@") < 0
                  ? navigation.navigate("ChatScreen", { pseudo })
                  : navigation.navigate("ChatTeamScreen", { to });
              } else {
                dispatch({
                  type: SET_DROP_DOWN_ALERT_WARN,
                  warn: "guestforbidden"
                });
              }
            }}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons name={"chat"} size={34} color="white" />
          </ActionButton.Item>
        )}
        {Boolean(
          (role === ROLE_EMPLOYEE ||
            role === ROLE_ADMINISTRATOR ||
            role === ROLE_WEBMASTER ||
            myRole === ROLE_EMPLOYEE ||
            myRole === ROLE_ADMINISTRATOR ||
            myRole === ROLE_WEBMASTER) &&
            role !== ROLE_ADVERTISER &&
            phone
        ) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={`${t("button:call")} ${phone}`}
            onPress={() => openURL(dispatch, "telprompt:" + phone)}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons name="phone" size={40} color="white" />
          </ActionButton.Item>
        )}
      </ActionButton>
    </View>
  );
};

const MemoizedProfile = memo(Profile);
export default MemoizedProfile;
