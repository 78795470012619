import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { memo } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetAdDetails } from "../actions/ad";
import { getImageUri } from "../assets/Images";
import { getTranslatedProperty } from "../services/i18n";
import commonStyles, {
  COLOR4,
  ORANGE_COLOR,
  SCREEN_HEIGHT,
  SCREEN_WIDTH
} from "../styles/commonStyles";

const AdItem = ({ item }) => {
  const dispatch = useDispatch();

  const { adsCategories } = useSelector(state => state.entitiesReducer);
  const { isFetching } = useSelector(state => state.adReducer);

  const {
    bgLightgrey,
    bgWhite,
    bgColor5,
    row,
    spaceBetween,
    justifyContentCenter,
    alignItemsCenter,
    mr5,
    mb10,
    mv5,
    mv10,
    mt10,
    p10,
    font,
    fontBold,
    fs14,
    fs20,
    rounded5,
    rounded22,
    rounded30,
    h44,
    h60,
    w100p,
    shadowGrey
  } = commonStyles;

  return (
    <View style={[w100p, bgWhite, mv10, p10, shadowGrey, mv5]}>
      <View
        style={[
          bgLightgrey,
          row,
          h60,
          rounded30,
          alignItemsCenter,
          p10,
          spaceBetween
        ]}
      >
        <View style={[row, alignItemsCenter]}>
          <View
            style={[
              h44,
              rounded22,
              {
                width: 44,
                backgroundColor: adsCategories[item.categoryId].bgColor
                  ? adsCategories[item.categoryId].bgColor
                  : COLOR4
              },
              mr5,
              justifyContentCenter,
              alignItemsCenter
            ]}
          >
            <Image
              style={{
                width: 30,
                height: 30,
                position: "absolute",
                tintColor: "white"
              }}
              source={getImageUri(adsCategories[item.categoryId].picto)}
            />
            <Image
              style={{
                width: 30,
                height: 30,
                position: "absolute",
                opacity: 0
              }}
              source={getImageUri(adsCategories[item.categoryId].picto)}
            />
          </View>
          <Text style={[fs14, fontBold]} numberOfLines={1}>
            {getTranslatedProperty(adsCategories[item.categoryId], "name")}
          </Text>
        </View>
        <Button
          icon={
            <MaterialCommunityIcons
              name="dots-horizontal"
              size={30}
              color="white"
            />
          }
          buttonStyle={[bgColor5, h44, rounded22]}
          disabled={isFetching}
          onPress={() => dispatch(fetchGetAdDetails({ id: item._id }))}
        />
      </View>
      <TouchableOpacity
        disabled={isFetching}
        onPress={() => {
          dispatch(fetchGetAdDetails({ id: item._id }));
        }}
      >
        <Image
          source={{ uri: item.photoUri }}
          style={[mt10, rounded5, { height: SCREEN_HEIGHT / 3 }, w100p]}
        />
        {Boolean(item.promotion) && (
          <View style={[styles.promotionView]}>
            <Text style={[fs14, font]}>
              {getTranslatedProperty(item, "promotion")}
            </Text>
          </View>
        )}
      </TouchableOpacity>
      <View style={styles.titleView}>
        <View style={[spaceBetween]}>
          <Text style={[fs20, mb10, font]}>
            {getTranslatedProperty(item, "title")}
          </Text>
        </View>
        <View>
          <Text style={[font, mb10]} numberOfLines={3}>
            {getTranslatedProperty(item, "description")}
          </Text>
        </View>
      </View>
    </View>
  );
};
const MemoizedAdItem = memo(AdItem);
export default MemoizedAdItem;

const styles = StyleSheet.create({
  titleView: {
    marginTop: 10,
    width: SCREEN_WIDTH - 60,
    marginRight: "auto",
    marginLeft: "auto"
  },
  promotionView: {
    height: 28,
    position: "absolute",
    bottom: 10,
    right: 10,
    backgroundColor: ORANGE_COLOR,
    borderRadius: 14,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10
  }
});
