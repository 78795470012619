export const BUSINESS_NAME = "HapiColibri";
export const SLOGAN = "Partageons la Vie";
export const APP_NAME = "HapiColibri";
export const APP_BUNDLE_ID = "com.digihapi.hapicolibri";
export const COMMUNITY = "neighborhood";
export const COMMUNITIES = "neighborhoods";
export const UUID_NAMESPACE = "d1ba68d7-991f-4000-b03c-3f7c04c0dc8f";
export const PROTOCOL = "hapicolibri";

export const WEB_URL = "www.hapicolibri.fr";
export const DEEPLINK_APP_URL = "https://app.hapicolibri.fr";
export const DEEPLINK_URL_SCHEME = PROTOCOL + "://";
// export const DEEPLINK_DEV_URL = "exp://127.0.0.1:19000/--/";
// export const PWA_DEEPLINK_APP_URL = `http://localhost:3001`;
export const PWA_DEEPLINK_APP_URL = "https://staging.hapicolibri.digihapi.com";
export const CONTACT_MAIL = "contact@hapicolibri.fr";
export const URL_PREFIX = "";

export const NOTIFICATION_ANDROID_CHANNEL_NAME = APP_NAME;

export const GOOGLE_CLOUD_STORAGE_URL =
  "https://storage.googleapis.com/digihapi/OCamping";

export const SENTRY_DSN =
  "https://b4e73a13bbc748e2b32a5741ffdc4053@sentry.io/5188697";

export const CREATE_COMMUNITY_URL =
  "https://www.hapicolibri.fr/enregistrement-voisinage";
export const APPLE_STORE_URL =
  "http://itunes.apple.com/fr/app/hapicolibri/id1507233341?mt=8";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.digihapi.hapicolibri";

export const COMMUNITY_MARKER_SUBSCRIBE =
  "https://mt.google.com/vt/icon/name=icons/spotlight/home_L_8x.png&scale=2";
export const COMMUNITY_MARKER_UNSUBSCRIBE =
  "https://mt.google.com/vt/icon/name=icons/spotlight/home_L_8x.png&scale=2";

export const TYPE_ACTIVITY = "Activity";
export const TYPE_PROPOSAL = "Proposal";
export const TYPE_TEMPLATE = "Template";

export const ROLE_GUEST = "Guest";
export const ROLE_ADVERTISER = "Advertiser";
export const ROLE_NEIGHBOR = "Neighbor";
export const ROLE_NEIGHBOR_GUEST = "Neighbor guest";
export const ROLE_EMPLOYEE = "Employee";
export const ROLE_EMPLOYEE_GUEST = "Employee guest";
export const ROLE_ADMINISTRATOR = "Administrator";
export const ROLE_WEBMASTER = "Webmaster";
export const ROLE_ADVERTISER_GUEST = "Advertiser guest";

export const GROUP_ID_WEATHER = "weather";
export const GROUP_ID_ACTIVITY = "activity";
export const GROUP_ID_GOODDEAL_ACTIVITY = "gooddealactivity";
export const GROUP_ID_PROPOSAL = "proposal";
export const GROUP_ID_TEMPLATE = "template";
export const GROUP_ID_PUBLISH = "publish";

export const EVENT_PING = "ping";

// Navigation routes
export const ABOUT_ROUTE = "a-propos";
export const CITIES_ROUTE = "villes";
export const COMMUNITIES_MAPS_ROUTE = "carte-communautes";
export const COMMUNITIES_ROUTE = "communautes";
export const AD_ROUTE = "annonce";
export const MAINTENANCE_ROUTE = "maintenance";
export const CGU_ROUTE = "conditions-generales-utilisation";
export const LOCAL_LIFE_ROUTE = "vie-locale";

export const MY_CONVERSATIONS_ROUTE = "mes-conversations";
export const MY_PROFILE_ROUTE = "mon-profil";
export const CAMERA_ROUTE = "appareil-photo";
export const PROFILE_ROUTE = "profil";
export const ZOOM_IMAGE_ROUTE = "zoom-image";
export const YOUTUBE_ROUTE = "youtube";

export const REGISTER_ROUTE = "enregistrement";

export const LANDING_ROUTES = new Set([
  ABOUT_ROUTE,
  CITIES_ROUTE,
  COMMUNITIES_MAPS_ROUTE,
  COMMUNITIES_ROUTE,
  AD_ROUTE,
  MAINTENANCE_ROUTE,
  CGU_ROUTE,
  LOCAL_LIFE_ROUTE
]);

export const AUTHENTICATED_ROUTES = new Set([
  MY_CONVERSATIONS_ROUTE,
  MY_PROFILE_ROUTE,
  PROFILE_ROUTE,
  CAMERA_ROUTE,
  ZOOM_IMAGE_ROUTE,
  YOUTUBE_ROUTE,
  LOCAL_LIFE_ROUTE,
  ABOUT_ROUTE,
  MAINTENANCE_ROUTE
]);

export const REGISTER_ROUTES = new Set([REGISTER_ROUTE]);
export const ALL_ROUTES = new Set([
  ...LANDING_ROUTES,
  ...AUTHENTICATED_ROUTES,
  ...REGISTER_ROUTES
]);
