import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import apiMiddleware from "../middleware/api";
import actionArrayMiddleware from "../middleware/actionArray";
import wsMiddleware from "../middleware/ws";
import createReducers from "../reducers";
import { isElectron } from "../styles/commonStyles";
import isDevelopment from "../utils/electron-is-dev";

let Reactotron = null;

if (__DEV__) {
  Reactotron = require("../../ReactotronConfig").default;
}

export default function configureStore() {
  const appReducer = createReducers();

  let store;
  if (__DEV__) {
    store = createStore(
      appReducer,
      compose(
        composeWithDevTools(
          applyMiddleware(wsMiddleware, actionArrayMiddleware, apiMiddleware)
        ),
        Reactotron.createEnhancer()
      )
    );
  } else {
    store = createStore(
      appReducer,
      applyMiddleware(wsMiddleware, actionArrayMiddleware, apiMiddleware)
    );
  }

  return store;
}
