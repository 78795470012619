import React from "react";
import { BUSINESS_NAME } from "../config/constants";
import env from "../config/env";
import DocReader from "./DocReader";

const URL = env.apiUrl;

function CGUReader() {
  return <DocReader uri={`${URL}/cgu?appname=${BUSINESS_NAME}`} />;
}

export default CGUReader;
